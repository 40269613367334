import { action, observable } from "mobx";
import axios from "axios";

export default class WaybillStore{
    publisingResult = observable({});
    publisingParams = {logisticsState:'publishing'};
  
    searchPublising() {
      axios.get(`/api/logistics`, { params: this.publisingParams }).then(res => {
        this.publisingResult.results = res.data.results;
        this.publisingResult.count = res.data.count
      });
    }


    unSendResult = observable({});
    unSendParams = {logisticsState:'unSend'};
  
    searchUnSend() {
      axios.get(`/api/logistics`, { params: this.unSendParams }).then(res => {
        this.unSendResult.results = res.data.results;
        this.unSendResult.count = res.data.count
      });
    }


    onTheWayResult = observable({});
    onTheWayParams = {logisticsState:'onTheWay'};
  
    searchOnTheWay() {
      axios.get(`/api/logistics`, { params: this.onTheWayParams }).then(res => {
        this.onTheWayResult.results = res.data.results;
        this.onTheWayResult.count = res.data.count
      });
    }


    unSignResult = observable({});
    unSignParams = {logisticsState:'unSign'};
  
    searchUnSign() {
      axios.get(`/api/logistics`, { params: this.unSignParams }).then(res => {
        this.unSignResult.results = res.data.results;
        this.unSignResult.count = res.data.count
      });
    }


    completedResult = observable({});
    completedParams = {logisticsState:'completed'};
  
    searchCompleted() {
      axios.get(`/api/logistics`, { params: this.completedParams }).then(res => {
        this.completedResult.results = res.data.results;
        this.completedResult.count = res.data.count
      });
    }


    closedResult = observable({});
    closedParams = {logisticsState:'closed'};
  
    searchClosed() {
      axios.get(`/api/logistics`, { params: this.closedParams }).then(res => {
        this.closedResult.results = res.data.results;
        this.closedResult.count = res.data.count
      });
    }


    search(logisticsState){
      if(logisticsState == 'publishing'){
        this.searchPublising()
      }
      if(logisticsState == 'unSend'){
        this.searchUnSend()
      }
      if(logisticsState == 'onTheWay'){
        this.searchOnTheWay()
      }
      if(logisticsState == 'unSign'){
        this.searchUnSign()
      }
      if(logisticsState == 'completed'){
        this.searchCompleted()
      }
      if(logisticsState == 'closed'){
        this.searchClosed()
      }
    }

    reset(){
      this.publisingResult = observable({});
      this.publisingParams = {logisticsState:'publishing'};
      this.unSendResult = observable({});
      this.unSendParams = {logisticsState:'unSend'};
      this.onTheWayResult = observable({});
      this.onTheWayParams = {logisticsState:'onTheWay'};
      this.unSignResult = observable({});
      this.unSignParams = {logisticsState:'unSign'};
      this.completedResult = observable({});
      this.completedParams = {logisticsState:'completed'};
      this.closedResult = observable({});
      this.closedParams = {logisticsState:'closed'};
    }
}