import React, { useState, useEffect } from "react";
import {Form,Input,Cascader,Layout,Tabs,Row,Rate,Modal,message,DatePicker,Space,Select,Button,Divider} from "antd"
import axios from "axios"
import useStores from "stores/StoreContext"

export default function(props){

    const {waybillStore} = useStores()
    const [evaluateVisible,setEvaluateVisible] = React.useState(props.visible)
    const [efficiencyScore,setEfficiencyScore] = React.useState(0)
    const [securityScore,setSecurityScore] = React.useState(0)
    const [serveScore,setServeScore] = React.useState(0)
    const [satisfactionScore,setSatisfactionScore] = React.useState(0)
    const [evaluateContent,setEvaluateContent] = React.useState('')
    const [readOnly,setReadOnly] = React.useState(false)

    React.useEffect(() => {
        setEvaluateVisible(props.visible)
        setEfficiencyScore(props.item.efficiencyScore||0)
        setSecurityScore(props.item.securityScore||0)
        setServeScore(props.item.serveScore||0)
        setSatisfactionScore(props.item.satisfactionScore||0)
        setReadOnly(props.readOnly)
        setEvaluateContent(props.item.evaluateList?.length>0&&props.item.evaluateList[0].shipperContent)
    },[props.visible])

    const getScore = (score) => {
        if(score !== null&&score !== undefined){
            return score == 0?0.0:score.toFixed(1)
        }
        return ''
    }

    const onChange = (number,type) => {
        switch(type){
            case 'efficiencyScore':
                setEfficiencyScore(number)
                break;
            case 'securityScore':
                setSecurityScore(number)
                break;
            case 'serveScore':
                setServeScore(number)
                break;
            case 'satisfactionScore':
                setSatisfactionScore(number)
                break;
        }
    }

    return (
        <Modal
            title='运输评价'
            footer={null}
            bodyStyle={{display:'flex',flexDirection:'column',alignItems:'center'}}
            onCancel={() => props.onCancel()}
            visible={evaluateVisible}>
                {
                    evaluateContent
                    &&
                    <div style={{padding:10,width:'100%'}}>
                        <div style={{fontSize:20,fontWeight:'bold'}}>收到评价</div>
                        <div style={{marginTop:10}}>
                            {evaluateContent}
                        </div>
                        <Divider/>
                    </div>
                }
                <Space style={{lineHeight:3,alignItems:'baseline'}} size='large'>
                    <span><span style={{color:'white'}}>占</span>运输效率</span>
                    <Rate allowHalf  disabled={readOnly}
                    value={efficiencyScore} onChange={(number) => onChange(number,'efficiencyScore')}/>
                    <span style={{color:'orange'}}>
                        {getScore(efficiencyScore)}
                    </span>
                </Space>
                <Space style={{lineHeight:3,alignItems:'baseline'}} size='large'>
                    <span><span style={{color:'white'}}>占</span>运输安全</span>
                    <Rate allowHalf  disabled={readOnly}
                    value={securityScore} onChange={(number) => onChange(number,'securityScore')}/>
                    <span style={{color:'orange'}}>
                        {getScore(securityScore)}
                    </span>
                </Space>
                <Space style={{lineHeight:3,alignItems:'baseline'}} size='large'>
                    <span><span style={{color:'white'}}>占</span>服务质量</span>
                    <Rate allowHalf  disabled={readOnly}
                    value={serveScore} onChange={(number) => onChange(number,'serveScore')}/>
                    <span style={{color:'orange'}}>
                        {getScore(serveScore)}
                    </span>
                </Space>
                <Space style={{lineHeight:3,alignItems:'baseline'}} size='large'>
                    <span>客服满意度</span>
                    <Rate allowHalf  disabled={readOnly}
                    value={satisfactionScore} onChange={(number) => onChange(number,'satisfactionScore')}/>
                    <span style={{color:'orange'}}>
                        {getScore(satisfactionScore)}
                    </span>
                </Space>
                {
                    !readOnly?
                    <div style={{width:'100%'}}>
                        <Button style={{float:'right',marginTop:'25px'}} 
                        type='primary' 
                        onClick={() => {
                            axios.put(`/api/logistics/${props.item.transportOrderId}
                            ?efficiencyScore=${efficiencyScore}
                            &securityScore=${securityScore}
                            &serveScore=${serveScore}
                            &satisfactionScore=${satisfactionScore}`)
                            .then(res => {
                                message.success('评价成功')
                                props.onCancel()
                                waybillStore.searchCompleted()
                            })
                        }}>确认提交</Button>
                    </div>:null
                }
            </Modal>
    )
}