import { action, observable } from "mobx";
import axios from "axios";

export default class BillStore{
    searchResult = observable({})
    searchParams = {}

    search() {
        axios.get(`/api/bill`, { params: this.searchParams }).then(res => {
          this.searchResult.results = res.data.results;
          this.searchResult.count = res.data.count
        });
    }
}