import React from 'react'
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import { formatTime,TimeFormats } from 'common/Constant'
import echarts from 'echarts'


export default function(props){

    const { datas } = props
        const dates = _.map(datas, (item) => {
            return formatTime(item.date, TimeFormats.YMD)
        })
        const counts = _.map(datas, (item) => {
            return item.signCount
        })
        const prices = _.map(datas, (item) => {
            return item.signPrice
        })

    const options = (dates, counts, prices) => {
            return {
                title: {
                    text: '统计报表',
                    textStyle: {
                        color: 'rgb(86, 138, 255)'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: 'rgb(63, 166, 255)',
                        }
                    }
                },
                legend: {
                    data: [{
                        name: '交易额',
                    }, '交易数'],
                },
                toolbox: {
                    show: false,
                    feature: {
                        dataView: { readOnly: false },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        data: dates
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '交易额',
                        max: _.max(prices),
                        splitLine: {
                            show: false
                        }
                    },
                    {
                        type: 'value',
                        name: '交易数',
                        max: _.max(counts),
                        splitLine: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        name: '交易数',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                lineStyle: {
                                    color: "rgb(255,130,185)"
                                }
                            }
                        },
                        data: counts
                    },
                    {
                        name: '交易额',
                        type: 'bar',
                        barWidth: 35,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: '#62BFFB' },
                                        { offset: 0.5, color: '#5380F4' },
                                        { offset: 1, color: '#5380F4' }
                                    ]
                                )
                            }
                        },
                        data: prices
                    }
                ]
            }
    }

    return (
        <div style={{
            position: 'relative'
        }}>
            <ReactEcharts
                option={options(dates, counts, prices)}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"}
                style={props.style ? props.style : { height: '530px' }}
            />
        </div>
    )
}