import React, { useState, useEffect } from "react";
import {Form,Input,Button,Layout,Divider,Table,Modal,message} from "antd"
import CommonSearch from "components/Search"
import styled from "styled-components";
import {SearchOutlined} from "@ant-design/icons";
import {useQuery} from "common/hooks"
import {Actions} from "components/common"
import CommonMap from "components/Map"
import axios from "axios"
import {GAODE_KEY,regular} from "common/Constant"
import useStores from "stores/StoreContext"
import MobxTable,{mergeParams} from "components/List/MobxTable"
import AddressForm from "components/Logistics/AddressForm"

const { Search } = Input;
const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
let id
export default function (){
    const [visible,setVisible] = React.useState(false)
    const {addressStore} = useStores()
    const [form] = Form.useForm();
    const [addressForm] = Form.useForm()


    return (
        <Layout className='full'>
            <CommonSearch title='地址列表'/>
            <Divider style={{marginTop:'10px'}}/>
            <SearchDiv>
                <Button type='primary' onClick={() => {
                    id=null
                    form.resetFields()
                    addressForm.resetFields()
                    setVisible(true)
                }}>新建地址</Button>
                <Input suffix={<SearchOutlined />} style={{width:'300px'}}
                onChange={(e) => {
                    addressStore.searchParams = mergeParams(addressStore.searchParams,{searchField:e.target.value},['searchField'])
                    addressStore.search()
                }}></Input>
            </SearchDiv>
            <MobxTable
            rowKey="id"
            store={addressStore}
            columns={[
                {
                  title: "联系人",
                  dataIndex: "contractName",
                  ellipsis:true
                },
                {
                  title: "联系电话",
                  dataIndex: "contractPhone",
                },
                {
                  title: "详细地址",
                  dataIndex: "address",
                  ellipsis:true
                },
                {
                  title: "操作",
                  width: 200,
                  render: (test, record) => (
                      <Actions items={[
                          {
                              text:'删除',
                              color:'red',
                              onClick:() => {
                                  Modal.confirm({
                                      title:"是否删除地址",
                                      onOk:() => {
                                          axios.delete(`/api/address/${record.id}`)
                                          .then(res => {
                                            message.success('删除成功')
                                            addressStore.search()
                                          })
                                      }
                                  })
                              }
                          },
                          {
                            text:'编辑',
                            onClick:() => {
                                id = record.id
                                setVisible(true)
                                form.setFieldsValue({...record})
                                debugger
                                addressForm.setFieldsValue({...record})
                            }
                        }
                      ]}/>
                  ),
                },
              ]}
            />
            <Modal 
            visible={visible} 
            width='700px'
            title={`${id?'编辑地址':'新建地址'}`}
            destroyOnClose={true}
            onCancel={()=>{
                setVisible(false)
                
            }}
            onOk={() => {
                form.validateFields()
                addressForm.validateFields()
                .then(item => {
                    form.validateFields()
                    .then(values => {
                        values.address = item.address
                        values.lat = item.lat
                        values.lng = item.lng
                        values.areacode = item.areacode
                        if(!item.areacode||!item.lat||!item.lng){
                            message.warning('地址无效，请重新选择地址')
                            return
                        }
                        if(!id){
                            axios.post(`/api/address`,values)
                            .then(res => {
                                setVisible(false)
                                message.success('添加地址成功')
                                addressStore.search()
                            })
                        }else{
                            axios.post(`/api/address/${id}`,values)
                            .then(res => {
                                setVisible(false)
                                message.success('修改地址成功')
                                addressStore.search()
                            })
                        }
                    })
                })
                
            }}>
                <Form 
                {...layout}
                form={form}>
                    <Form.Item
                    label='联系人'
                    name="contractName"
                    rules={[{ required: true, message: "请输入联系人" }]}>
                        <Input placeholder='填写联系人姓名' maxLength={50}/>
                    </Form.Item>
                    <Form.Item
                    label='联系电话'
                    name="contractPhone"
                    rules={[{ required: true, message: "请输入联系电话" },{pattern:regular.phone,message:'请输入正确的手机号码'}]}>
                        <Input placeholder='填写联系人电话'/>
                    </Form.Item>
                </Form>
                <AddressForm form={addressForm} />
            </Modal>
        </Layout>
    )
}


const SearchDiv = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0px 15px 15px 15px
`