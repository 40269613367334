import React from 'react'
import { Form, Input, Button, Layout, Divider, InputNumber, Modal, message, Space, Select, DatePicker, Upload, Tabs, Image,Radio } from "antd"
import CommonSearch from "components/Search"
import { SearchOutlined, PlusOutlined, RedoOutlined } from "@ant-design/icons";
import { Actions } from "components/common"
import useStores from "stores/StoreContext"
import MobxTable, { mergeParams } from "components/List/MobxTable"
import axios from "axios"
import { TabCard } from "components/common"
import { formatTime, TimeFormats,noData } from "common/Constant";
import { exportFile } from "common/fileExport"
import { price } from 'utils';
import { surcharge } from 'common/Constant';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { getValueFromEvent } from 'components/CImageNew';
import './new.css'
import { phoneXin,idCardXin,carXin } from 'utils';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs

const col = [
  {
    title: '运单号',
    dataIndex: 'waybillId',
    ellipsis: true,
    fixed: 'left',
    width: 200,
  },
  {
    title: '创建日期',
    dataIndex: 'createTime',
    width: 200,
    render: (text) => formatTime(text)
  },
  {
    title: '表单名称',
    dataIndex: 'fileName',
    width: 200,
    render: (text) => {return text||'--'}
  },
  {
    title: '表单内运单号',
    dataIndex: 'trackingNo',
    width: 200,
    render: (text) => (text||'--')
  },
  {
    title: '创建日期',
    dataIndex: 'createTime',
    width: 200,
    render: (text) => formatTime(text)
  },
  
  {
    title: '车牌号',
    dataIndex: 'plateNumber',
    width: 200,
  },
  {
    title: '驾驶员姓名',
    dataIndex: 'driverName',
    width: 200,
  },
  {
    title: '驾驶员电话',
    dataIndex: 'driverPhone',
    width: 200,
    render: (text) => noData(text) != '--' ? phoneXin(text+''):noData(text)
  },
  // {
  //   title: '收款人',
  //   dataIndex: 'receiverBankCardHolder',
  //   width: 200,
  // },
  // {
  //   title: '收款人账号',
  //   dataIndex: 'receiverBankCardNo',
  //   width: 250,
  // },
  // {
  //   title: '费用类型',
  //   dataIndex: 'billType',
  //   width: 200,
  //   render: (text) => surcharge[text],
  // },

  {
    title: '车长',
    dataIndex: 'vehicleLength',
    width: 200,
  },
  {
    title: '车辆类型',
    dataIndex: 'vehicleModel',
    width: 200,
  },
  // {
  //   title: '付款人',
  //   dataIndex: 'sendName',
  //   width: 250,
  //   // render: (text) => formatTime(text)
  // },
  // {
  //   title: '付款账号',
  //   dataIndex: 'sendBankCardNo',
  //   width: 200,
  // },
]
let id

export default observer(function Risk(peops) {
  const { riskStore } = useStores()
  const [vis, setVis] = React.useState(false)
  const [title, setTitle] = React.useState('')
  const [type, setType] = React.useState('')
  const [reasonList, setReasonList] = React.useState([])
  const [form] = Form.useForm()
  const [img, setImg] = React.useState('')
  const [visible, setVisible] = React.useState(false)
  const [startRotate, setRotate] = React.useState(0)

  const [isShow,setIsShow] = React.useState(false)
  const [waybillId,setWaybillId] = React.useState(0)
  React.useEffect(() => {
    riskStore.searchComplaint()
    riskStore.auditComplaint()
    riskStore.notComplaint()
  }, [])

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImg(file.url || file.preview)
    setVisible(true)
  }
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  async function open(title, obj) {
    id = obj.id
    if (title == '上传单据') {
      let pickFileList = obj.pickupReceipt && obj.pickupReceipt.split(',').map((item, index) => ({ uid: index, status: 'done', url: `/file/${item}`, response: item }))
      let deliverFileList = obj.deliveryReceipt && obj.deliveryReceipt.split(',').map((item, index) => ({ uid: index, status: 'done', url: `/file/${item}`, response: item }))

      form.setFieldsValue({ deliveryReceipt: deliverFileList ? deliverFileList : undefined, pickupReceipt: pickFileList ? pickFileList : undefined })
      // setPickFileList(pickFileList)
      // setDeliverFileList(deliverFileList)
      console.log(form.getFieldsValue(true));
    }
    if (title == '风险原因') {
      let res = await axios(`/api/logistics/risk/${obj.id}`)
      if (res.data.code == 100000) {
        setReasonList(res.data.data)
      } else {
        message.error('风险原因查询失败')
      }

    }
    setVis(true)
    setTitle(title)
    setType(obj.type)

  }

  function openAudit(title,id){
    if (title == '审核') {
      // let res = await axios(`/api/logistics/risk/${obj.id}`)

      console.log("点击显示审核")
      setIsShow(true)
      setWaybillId(id)
    }
  }
  function subAudit(){

    form.validateFields().then(val =>{
      // console.log("选择的值===>",val)

      axios.get(`/api/audit/setRisk?waybillId=${waybillId}&risk=${val.audit}`).then(res =>{
        // console.log("res===>",res)
        if(res.status == 200 ){
          message.success('审核成功')
          riskStore.auditComplaint()
          setIsShow(false)
        }else{
          message.error('审核失败')
          riskStore.auditComplaint()
          setIsShow(false)
        }
      })
    })
  }
  function rotate() {
    setRotate(v => v + 90)
  }
  function subOk() {
    if (type == 'upload') {
      let dataList = form.getFieldsValue('true')
      let pickupReceipt = dataList.pickupReceipt?.map(item => item.response).join(',')
      let deliveryReceipt = dataList.deliveryReceipt?.map(item => item.response).join(',')
      axios.post('/api/logistics/receipt', { pickupReceipt, deliveryReceipt, waybillId: id }).then(
        res => {
          if (res.data.code == 100000) {
            message.success('上传单据成功')
            riskStore.searchComplaint()
            setVis(false)
          } else {
            message.error('上传单据失败' + res.data.msg)
          }
        }, err => message.error('上传单据失败' + err.msg)
      )
    } else if (type == 'comp') {
      let data = form.getFieldsValue('true')
      axios.get('/api/logistics/appeal/' + id + "/" + data.reason).then(
        res => {
          if (res.data.code == 100000) {
            message.success('申述成功')
            riskStore.searchComplaint()
            setVis(false)
            form.resetFields()
          } else {
            message.success('申述成功失败' + res.data.msg)
          }
        }, err => message.success('申述成功失败' + err.msg)
      )
    }
  }

  function modal(type, id) {
    if (type == 'upload') {
      return <Form form={form} key={1}>
        <div className="textAlign">请上传装货单据</div>
        <Form.Item name='pickupReceipt' getValueFromEvent={getValueFromEvent} valuePropName="fileList">
          <Upload
            listType="picture-card"
            action="/api/file/real"
            onPreview={handlePreview}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
        <div className="textAlign">请上传卸货单据</div>
        <Form.Item name='deliveryReceipt' getValueFromEvent={getValueFromEvent} valuePropName='fileList'>
          <Upload
            listType="picture-card"
            action="/api/file/real"
            onPreview={handlePreview}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    } else if (type == 'reason') {
      return reasonList.length > 0 ? reasonList.map((item, index) => <div>
        <div>扫描时间：{item.createTime}&nbsp;
          <span style={{ color:riskLevel(item.level).color,fontWeight:'bold'}}>{riskLevel(item.level).text}</span>
        </div>
        <div>风险原因：{item.riskReason}</div>
      </div>) : "无风险原因"
    } else if (type == 'comp') {
      return <Form form={form} key={2}>
        <Form.Item name='reason' >
          <Input.TextArea placeholder='请输入原因' rows={10} autosize={{ minRows: 5, maxRows: 10 }} ></Input.TextArea>
        </Form.Item>
      </Form>
    }


  }
  function riskLevel(level) {
    switch (level) {
      case 0:
        return {color:'#FF3333',text:'关键'}
      case 1:
        return {color:'#FF9933',text:'中等'}
      case 2:
        return {color:'#0099FF',text:'一般'}

    }
  }
  return (
    <Layout className='full'>
      <Tabs defaultActiveKey={'0'} style={{ marginLeft: 15, marginTop: 10 }}>
        <TabPane tab={<TabCard text={`待申诉 (` + riskStore.complaintResult.count + ')'} />} key="0">
          <SearchForm method='searchComplaint' params={riskStore.complaintParmas} store={riskStore} />
          <MobxTable
            rowKey="waybillId"
            store={riskStore}
            params='complaintParmas'
            result='complaintResult'
            method='searchComplaint'
            scroll={{ y: 550,x:2000 }}
            columns={[
              ...col,
              {
                title: '操作',
                fixed: 'right',
                align: 'center',
                width:300,
                render: (text, record) =>
                  <div style={{ display: 'flex' }}>
                    <Button type='link' onClick={() => open('上传单据', { type: 'upload', id: record.waybillId, deliveryReceipt: record.deliveryReceipt, pickupReceipt: record.pickupReceipt })}>上传单据</Button>
                    <Button type='link' onClick={() => open('申诉', { type: 'comp', id: record.waybillId })}>申诉</Button>
                    <Button type='link' onClick={() => open('风险原因', { type: 'reason', id: record.waybillId })}> 风险原因</Button>
                  </div>
              }
            ]}
          />
          <Modal
            key={1}
            visible={vis}
            className='image'
            width='800px'
            {...(type == 'reason' ? { footer: null } : null)}
            onOk={subOk}
            onCancel={() => {
              setVis(false)
              form.resetFields()
            }}
            title={title}>
            {modal(type)}
          </Modal>
          <Modal
            key={2}
            visible={visible}
            className='image'
            width='800px'
            footer={[
              <Button icon={<RedoOutlined />} style={{ width: 50 }} onClick={rotate}></Button>,
            ]}
            onCancel={() => {
              setVisible(false)
              setRotate(0)
            }}
            title="单据图片">
            <img
              style={{ width: '80%', transform: `rotate(${startRotate}deg)` }}
              src={img}

            />
          </Modal>
        </TabPane>
        <TabPane tab={<TabCard text={'待审核 (' + riskStore.auditResult.count + ') '} />} key="1">

          <MobxTable
            rowKey="transportOrderId"
            store={riskStore}
            params='auditParmas'
            result='auditResult'
            method='auditComplaint'
            columns={[
              ...col,
              {
                title: '操作',
                fixed: 'right',
                align: 'center',
                width:300,
                render: (text, record) =>
                  <div >
                    <Button type='link' onClick={() => openAudit('审核',record.waybillId) }> 审核</Button>
                  </div>
              }
            ]} />
        </TabPane>
        <TabPane tab={<TabCard text={'不可开票 (' + riskStore.notResult.count + ")"} />} key="2">

          <MobxTable
            rowKey="id"
            store={riskStore}

            params='notParmas'
            result='notResult'
            method='notComplaint'
            columns={[
              ...col
            ]} />
        </TabPane>
      </Tabs>

      <Modal
                visible={isShow}
                width='400px'
                onOk={subAudit}
                onCancel={() => {
                    setIsShow(false)
                    form.resetFields()
                }}
                title="审核">
                   <Form colon={false}  form={form}>

                        <Form.Item
                         label='审核结果：'
                         name="audit"
                         rules={[{
                          required:true,message:'请选择审核结果!'
                      }]}
                      >
                          <Radio.Group>
                            <Radio value={1}>通过</Radio>
                            <Radio value={4}>不可开票</Radio>
                          </Radio.Group>
                        </Form.Item>
                                                {/* <Form.Item
                         label='支付密码：'
                        name="password"
                        rules={[{
                            required:true,message:'请输入支付密码!'
                        }]}>
                            <Input type="password" style={{width:'200px'}} placeholder="请输入支付密码"></Input>
                        </Form.Item> */}
                </Form>      
            </Modal>

    </Layout>
  )
})

function SearchForm(props) {
  const [from] = Form.useForm()
  const { method } = props
  function search() {
    let a = from.getFieldsValue(true)
    a.startTime = a.date ? (formatTime(a.date[0]._d, TimeFormats.YMD) + ' 00:00:00') : null
    a.endTime = a.date ? (formatTime(a.date[1]._d, TimeFormats.YMD) + ' 23:59:59') : null
    let b = _.pick(a, 'driverName', 'driverPhone', 'plateNumber', 'startTime', 'endTime')
    Object.assign(props.params, b)
    props.store[method]()
  }
  return <>
    <br />
    <Form form={from}>
      <Space>

        <Form.Item
          name='driverName'
        >
          <Input placeholder='请输入司机姓名' allowClear />
        </Form.Item>
        <Form.Item
          name='driverPhone'
        >
          <Input placeholder='请输入司机手机号' allowClear />
        </Form.Item>
        <Form.Item
          name='plateNumber'
        >
          <Input placeholder='请输入司机车牌号' allowClear />
        </Form.Item>
        <Form.Item
          name='date'
        >
          <RangePicker allowClear />
        </Form.Item>
        <Form.Item
        >
          <Button type='primary' onClick={search}>查询</Button>
        </Form.Item>
      </Space>

    </Form>
  </>
}

