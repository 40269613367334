import { action, observable } from "mobx";
import axios from "axios";

export default class WaterStore{
    searchResult = observable({})
    searchParams = {searchField:null}

    search() {
        axios.post(`/api/driver/list`,  this.searchParams ).then(res => {
          this.searchResult.results = res.data.data.results;
          this.searchResult.count = res.data.data.count
        });
    }
}