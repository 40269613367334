import axios from "axios"

export async function exportFile() {
    return conspostJson('/Template.xlsx', 'blob').then(res => {
        let url = URL.createObjectURL(new Blob([res.data]));
        let filename = '补录单模板.xlsx';
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    })
}

const conspostJson = async (url, responseType) => {
    const response = await axios.get(url, {
        header: {
            'Content-Type': 'application/json',
        },
        responseType: responseType,
    });
    return Promise.resolve(response);
}