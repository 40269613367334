import React, { Component } from 'react';
import { Input, Row, Col, Icon, message,Modal,Button,Tabs } from 'antd';
import jdLogoImg from "assert/images/jd-logo.png"
import { useParams, Switch, useHistory, useLocation } from "react-router-dom";
import {privateBanks,creditBanks,publicBanks} from './BankData'
import workingImg from "assert/images/working.gif"
import qs from "qs"
import axios from "axios"
import "./index.less"

let accountType = 'P'
const { TabPane } = Tabs;
export default function({match}){
    let location  = useLocation()
    const history = useHistory()
    const {amount,billAuditId} = qs.parse(location.search.slice(1))
    const [bankPayment,setBankPayment] = React.useState({})
    const [pmodeId,setPmodeId] = React.useState('')
    const [visible,setVisible] = React.useState(false)
    const formRef = React.useRef()

    const onChange = (key) => {
        if(key == 'public'){
            accountType = 'C'
        }else {
            accountType = 'P'
        }
    }

    const pay = () =>{
        if(!pmodeId){
            message.warning('请选择一个银行')
            return
        }
        setVisible(true)
        const url = `http://${window.location.host}/jdpay/rewrite`
        axios.post(`/api/pay/bank/${accountType}?url=${url}`,[billAuditId])
        .then(res => {
            setBankPayment(res.data)
            formRef.current.submit()
        })
    }

    return (
        <div  className='full' style={{padding:'15px'}}>
            <Row>
                <Col span={4}>
                    <img src={jdLogoImg} alt="京东支付"/>
                </Col>
                <Col span={16}>
                    支付金额：<span style={{color:'red',fontSize:'30px'}}>￥{new Number(amount).toFixed(1)}</span>
                </Col>
                
            </Row>
            
            <Tabs onChange={onChange} style={{height:300}}>
                <TabPane tab='对私账户' key='private'>
                    <div style={{padding:10,marginTop:20}}>
                        <ul class="pay-wrap">
                            {
                                privateBanks.map((item) => {
                                    return <li className={`pay-item ${item.pmodeId==pmodeId?'pay-item-select':''}`} onClick={() => setPmodeId(item.pmodeId)}>
                                                <span className={`bank-logo ${item.type}`} span={6}>
                                                    {item.name}
                                                </span>
                                            </li>
                                })
                            }
                        </ul>
                    </div>
                </TabPane>
                <TabPane tab='对公账户' key='public'>
                    <div style={{padding:10,marginTop:20}}>
                        <ul class="pay-wrap">
                            {
                                publicBanks.map((item) => {
                                    return <li className={`pay-item ${item.pmodeId==pmodeId?'pay-item-select':''}`} onClick={() => this.netWebPay(item.pmodeId)}>
                                                <span className={`bank-logo ${item.type}`} span={6}>
                                                    {item.name}
                                                </span>
                                            </li>
                                })
                            }
                        </ul>
                    </div>
                </TabPane>
                <TabPane tab='信用卡账户' key='credit'>
                    <div style={{padding:10,marginTop:20}}>
                        <ul class="pay-wrap">
                            {
                                creditBanks.map((item) => {
                                    return <li className={`pay-item ${item.pmodeId==pmodeId?'pay-item-select':''}`} onClick={() => this.netWebPay(item.pmodeId)}>
                                                <span className={`bank-logo ${item.type}`} span={6}>
                                                    {item.name}
                                                </span>
                                            </li>
                                })
                            }
                        </ul>
                    </div>
                </TabPane>
            </Tabs>
            <Button type="primary" style={{float:'right',marginRight:100}} onClick={pay} >
                    确认支付
            </Button>
            <Modal footer={
                <div style={{display:'flex',justifyContent:'center'}}>
                    <Button onClick={() => {
                        setVisible(false)
                        window.location.reload()
                    }}>支付遇到问题 </Button>
                    <Button type='primary' onClick={() => {
                        setVisible(false)
                        history.push(`/billAudit`)
                    }} style={{marginLeft:10}}>支付完成 </Button>
                </div>
            } title='网上支付提示'
            onCancel={() => setVisible(false)}
            visible={visible}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:20}}>
                    <img src={workingImg}/>
                    <div style={{marginLeft:10}}>
                        <div>支付完成前，请不要关闭此支付验证窗口。</div>
                        <div>支付完成后，请根据您支付的情况点击下面按钮。</div>
                    </div>
                </div>
            </Modal>
            <form action="https://pay3.chinabank.com.cn/PayGate" method="POST" name="E_FORM" 
                ref={formRef} target="_blank">
                <input type="hidden" name="v_md5info" value={bankPayment.v_md5info} size="100"/>
                <input type="hidden" name="v_mid" value={bankPayment.v_mid}/>
                <input type="hidden" name="v_oid" value={bankPayment.v_oid}/>
                <input type="hidden" name="v_amount" value={bankPayment.v_amount}/>
                <input type="hidden" name="v_moneytype" value={bankPayment.v_moneytype}/>
                <input type="hidden" name="v_url" value={bankPayment.v_url}/>
                <input type="hidden"  name="remark1" value=""/>
                <input type="hidden"  name="remark2" value={bankPayment.remark2}/>
                <input type="hidden"  name="pmode_id" value={pmodeId}/>
                <input type="hidden"  name="bizTp" value={bankPayment.bizTp}/>
                <input type="hidden"  name="goodsInfos" value={bankPayment.goodsInfos}/>
                <input type="hidden"  name="v_rcvname" value=""/>
                <input type="hidden"  name="v_rcvaddr" value=""/>
                <input type="hidden"  name="v_rcvtel"  value=""/>
                <input type="hidden"  name="v_rcvpost"  value=">"/>
                <input type="hidden"  name="v_rcvemail"  value=""/>
                <input type="hidden"  name="v_rcvmobile"  value=""/>
                <input type="hidden"  name="v_ordername"  value=""/>
                <input type="hidden"  name="v_orderaddr"  value=""/>
                <input type="hidden"  name="v_ordertel" value=""/>
                <input type="hidden"  name="v_orderpost"  value=""/>
                <input type="hidden"  name="v_orderemail"  value=""/>
                <input type="hidden"  name="v_ordermobile" value=""/>
                
            </form>
        </div>
    )
}