import React from 'react'
import MobxTable, { mergeParams } from "components/List/MobxTable"
import useStores from 'stores/StoreContext'
import { Radio, Button, Space, Form, Modal, Input, Select, Divider, Table, message } from 'antd';
import CBreadcrumb from 'components/CBreadcrumb';
import CommonSearch from "components/Search"
import {RedoOutlined} from "@ant-design/icons";
import { formatTime, noData, surcharge, TimeFormats, billState, vehicleModels } from "common/Constant";
import { observer } from "mobx-react"
import { Title } from "components/common"
import { Actions } from "components/common"
import styled from "styled-components";
import axios from 'axios';
import _ from 'lodash'
import './new.css'
const TdWrap = styled.div`

`

const cols = [
  { title: '', dataIndex: 'v1', width: '15%', className: 'cell', align: 'right' },
  { title: '', dataIndex: 'v2', width: '35%' },
  { title: '', dataIndex: 'v3', width: '15%', className: 'cell', align: 'right' },
  { title: '', dataIndex: 'v4', },
]
const billCols = [
  {
    title: "账单号",
    dataIndex: "id",
    ellipsis: true
  },
  {
    title: "出账日期",
    dataIndex: "createTime",
    ellipsis: true,
    render: (text) => formatTime(text)
  },
  {
    title: "运单号",
    dataIndex: "waybillId",
    ellipsis: true,
  },
  {
    title: "付款人",
    dataIndex: "sendName",
    ellipsis: true,
  },
  {
    title: "付款账号",
    dataIndex: "sendBankCardNo",
    ellipsis: true,
    render: (text) => noData(text)
  },
  {
    title: "费用类型",
    dataIndex: "billType",
    ellipsis: true,
    render: (text) => surcharge[text]
  },
  {
    title: "金额",
    dataIndex: "price",
    ellipsis: true,
    render: (text) => <span style={{ color: 'red' }}>￥ {(text * 1).toFixed(2)}</span>
  },
]
export default observer(function InvoDetail(props) {
  debugger
  const { invoiceStore } = useStores()
  let [state, setState] = React.useState('0')
  let [visible, setVisible] = React.useState(false)
  const [detail, setDetail] = React.useState({})
  const [content, setContent] = React.useState({})
  const [previewVisible, setPreviewVisible] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const [startRotate, setRotate] = React.useState(0)
  const [startScale, setScale] = React.useState(1)
  let { match: { params: { id } } } = props
  invoiceStore.detailParams.billAuditId = id
  invoiceStore.detailParams.submit = ''

  function change(url) {
    setUrl(url)
    setPreviewVisible(true)
  }
  function handleCancel() {
    setPreviewVisible(false)
    setRotate(0)
    setScale(1)
  }
  // 图片旋转
  function rotate() {
    setRotate(startRotate => startRotate + 90)
  }
  function wheel(e) {
    if (e.deltaY < 0) {
      if (startScale > 1) {
        setScale(startScale => startScale - 1) // 缩小
      }
    } else {
      setScale(startScale => startScale + 1) // 放大
    }
  }
  //   React.useEffect(() => {
  //     refresh()
  //   }, [])
  //   const refresh = () => {
  //     axios.get(`/api/bill/${id}`)
  //         .then(res => {
  //             debugger
  //             setDetail(res.data)
  //         })
  //     axios.get(`/api/bill/att/${1634608895174}`).then(res => {
  //         debugger
  //         setContent(res.data)
  //     })
  // }
  return (
    <>
      <CBreadcrumb items={[{ href: `/invoice`, text: '未开票' }, { href: `/inDetail/${id}`, text: '账单明细' }]} />

      <CommonSearch title='账单列表'>
        {/* <SearchForm type='pending'
          onValuesChange={(changedValues, allValues) => {
            billAuditStore.pendingParams.billAuditState = 'pending'
            billAuditStore.pendingParams = mergeParams(billAuditStore.pendingParams, allValues, searchProps, ['billAuditState'])
            billAuditStore.searchPending()
          }} /> */}
      </CommonSearch>
      <MobxTable
        params='detailParams'
        result='detailResult'
        method='searchDetail'
        bordered
        rowKey="id"
        store={invoiceStore}
        columns={[
          ...billCols,
          {
            title: '操作',
            render: (text, record) => <Button type="link" style={{ color: '#2262fd' }} onClick={async () => {
              try {
                let resDet = await axios.get(`/api/bill/${record.id}`)
                let resImg = await axios.get(`/api/bill/att/${record.id}`)
                setDetail(resDet.data)
                setContent(resImg.data)
                setVisible(true)
              } catch (error) {
                message.error('获取账单明细失败' + error)
              }
            }}>账单明细</Button>
          }
        ]} />

      <Modal
        title='账单详情'
        visible={visible}
        width='900px'
        onCancel={() => setVisible(false)}
        footer={null}>
        <Title text='账单信息' />
        <Table
          style={{ marginBottom: '15px' }}
          pagination={false}
          showHeader={false}
          columns={cols}
          dataSource={[
            {
              v1: <TdWrap>账单编号</TdWrap>,
              v2: detail.bill?.id,
              v3: <TdWrap>出账时间</TdWrap>,
              v4: formatTime(detail.bill?.createTime, TimeFormats.YMDHMS)
            },
            {
              v1: <TdWrap>付款人</TdWrap>,
              v2: detail.shipper?.companyName,
              v3: <TdWrap>联系电话</TdWrap>,
              v4: detail.shipper?.contractPhone,
            },
            {
              v1: <TdWrap>付款账户</TdWrap>,
              v2: detail.shipper?.bankCardNo,
              v3: <TdWrap>开户银行</TdWrap>,
              v4: detail.shipper?.bankName,
            },
            {
              v1: <TdWrap>费用类型</TdWrap>,
              v2: surcharge[detail.bill?.type],
              v3: <TdWrap>运费金额</TdWrap>,
              v4: detail.bill?.transferType === 'receive' ? detail.waybill?.price.toFixed(2) : detail.order?.price.toFixed(2)
            },
            {
              v1: <TdWrap>货损扣款</TdWrap>,
              v2: detail.order?.cargoDamagePrice,
              v3: <TdWrap>扣款原因</TdWrap>,
              v4: noData(detail.order?.cargoDamageReason)
            },
            {
              v1: <TdWrap>费用总计</TdWrap>,
              v2: <span style={{ color: 'red' }}>{_.subtract(detail.bill?.transferType === 'receive' ? detail.waybill?.price.toFixed(2) : detail.order?.price.toFixed(2), detail.order?.cargoDamagePrice).toFixed(2)}</span>,
              v3: <TdWrap>账单状态</TdWrap>,
              v4: <div style={{
                border: '1px solid #1692E4',
                width: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <span style={{ color: '#1692E4' }}>{billState[detail.billState]}</span>
              </div>
            },
          ]}
        />
        <Title text='运单信息' />
        <Table
          style={{ marginBottom: '15px' }}
          pagination={false}
          showHeader={false}
          columns={cols}
          dataSource={[
            {
              v1: <TdWrap>运单号</TdWrap>,
              v2: detail.waybill?.id,
              v3: <TdWrap>发货人</TdWrap>,
              v4: detail.shipper?.companyName
            },
            {
              v1: <TdWrap>装货地址</TdWrap>,
              v2: detail.waybill?.fromAddress,
              v3: <TdWrap>卸货地址</TdWrap>,
              v4: detail.waybill?.toAddress
            },
            {
              v1: <TdWrap>承运方</TdWrap>,
              v2: detail.bill?.receiverType == 'driver' ? detail.driver?.realName : detail.carrier?.companyName,
              v3: <TdWrap>电话号码</TdWrap>,
              v4: detail.bill?.receiverType == 'driver' ? detail.driver?.accountName : detail.carrier?.contractPhone,
            },
            {
              v1: <TdWrap>车长（米）</TdWrap>,
              v2: detail.vehicle?.vehicleLength,
              v3: <TdWrap>车型</TdWrap>,
              v4: vehicleModels[detail.vehicle?.vehicleModel],
            },
            {
              v1: <TdWrap>货物信息</TdWrap>,
              v2: detail.shipments?.map((item, index) => {
                return <div>{`${item.cargoName};${item.weight}kg;${item.packingManner}`}</div>
              }),
              v3: <TdWrap>付款凭证</TdWrap>,
              v4: <img src={`/file/${content.toDriveCert}`} style={{ width: '200px', height: '200px' }} onClick={() => change(`/file/${content.toDriveCert}`)} alt='暂无图片' title="点击放大" />
              // props.state == 'send' ?
              //   [<img src={`/file/${content.payCert}`} style={{ width: '200px', height: '200px' }} onClick={() => change(`/file/${content.payCert}`)} alt='暂无图片' title="点击放大" />,
              //   <img src={`/file/${content.collCert}`} style={{ width: '200px', height: '200px' }} onClick={() => change(`/file/${content.collCert}`)} alt='暂无图片' title="点击放大" />]
              //   : [<img src={`/file/1634900355935/微信图片_20211021145616.jpg`} style={{ width: '200px', height: '200px' }} onClick={() => change(`/file/1634900355935/微信图片_20211021145616.jpg`)} alt='暂无图片' title="点击放大" />]
            }
          ]}
        />

      </Modal>
      <Modal
        key='1'
        visible={previewVisible}
        footer={[
          <Button icon={<RedoOutlined />} style={{width:'35px'}} onClick={rotate}></Button>,
        ]}
        className="image"
        title="图片详情"
        width='800px'
        className="image"
        onCancel={handleCancel}
      >
        <div style={{ overflow: 'auto', transform: `rotate(${startRotate}deg) scale(${startScale})` }} onWheel={wheel}>
          <img alt="example" style={{ width: '100%' }} src={url} />
        </div>
      </Modal>
    </>
  )
})
