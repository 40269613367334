import { action, observable } from "mobx";
import axios from "axios";

export default class InvoiceStore {
  detailResult = observable({})
  detailParams = {}
  searchDetail() {
    axios.get(`/api/bill/service`, { params: this.detailParams }).then(res => {
      this.detailResult.results = res.data.results;
      this.detailResult.count = res.data.count
    });
  }

  //未开票
  unInvoiceResult = observable({})
  unInvoiceParams = { applyInvoice: false, billAuditState: 'approved' }
  searchUninvoice() {
    axios.get(`/api/invoice/billAudit`, { params: this.unInvoiceParams }).then(res => {
      this.unInvoiceResult.results = res.data.results;
      this.unInvoiceResult.count = res.data.count
    });
  }

  pendingResult = observable({})
  pendingParams = { state: 'pending' }
  searchPending() {
    axios.get(`/api/invoice`, { params: this.pendingParams }).then(res => {
      this.pendingResult.results = res.data.results;
      this.pendingResult.count = res.data.count
    });
  }


  confirmedResult = observable({})
  confirmedParams = { state: 'confirmed' }
  searchConfirmed() {
    axios.get(`/api/invoice`, { params: this.confirmedParams }).then(res => {
      this.confirmedResult.results = res.data.results;
      this.confirmedResult.count = res.data.count
    });
  }
}