import React, { useState, useEffect } from "react";
import {Form,Input,Button,Space} from "antd"
import {login} from "common/session"
import { useHistory } from "react-router-dom";
import "../index.less"
import LogoImg from "assert/images/logo1.png"
import PhoneImg from "assert/images/phone.png"
import SecretImg from "assert/images/secret.png"

export default function Login(){

    let history = useHistory()

    const onFinish = values => {
      login(values,history)
    };
      
    return (
      <div className='auth-bg'>
        <div className='login-container'>
          <Space>
            <img src={LogoImg} style={{width: '110px'}}/>
            <span style={{
              fontSize:'34px',
              color:'#1C56FB',
              fontWeight:'530',
            }}>双龙火云智慧物流货运平台客户端</span>
          </Space>
          <Form
            style={{marginTop:'70px'}}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: '请输入账号' }]}>
              <Input prefix={<img src={PhoneImg}/>} style={{width:'100%'}} placeholder='请输入账号'/>
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码' }]}>
              <Input.Password prefix={<img src={SecretImg}/>} placeholder='请输入密码'/>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit"
              style={{
                height:'55px',
                width:'100%',
                marginTop:'20px'
              }}
              type='primary'>
                <span style={{fontSize:'24px'}}>登录</span>
              </Button>
            </Form.Item>
          </Form>
          <div style={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'
          }}>
            <span style={{color:'#8D8D8D',cursor:'pointer'}}
            onClick={() => {
              history.push('/forgetPassword')
            }}>忘记密码？</span>
            <span style={{color:'#8D8D8D'}}>
                还没账号？
                <a onClick={() => history.push('/register')}>去注册</a>
            </span>
          </div>
        </div>
    </div>
    )
}