import React, { useState, useEffect } from "react";
import {Form} from "antd"

export default function CommonSearch(props:any){
    return (
        <div style={{
            display:'flex',
            justifyContent:'space-between',
            paddingLeft:'10px',
            paddingRight:'15px',
            paddingTop:'13px'
        }}>
            <div style={{
                fontSize:'18px',
                color:'black',
            }}>
                {props.title}
            </div>
            {props.children}
        </div>
    )
}