import React from 'react'
import { Form, Input, Button, Layout, Divider, InputNumber, Modal, message, Space, Select, DatePicker, Upload, Tabs } from "antd"
import CommonSearch from "components/Search"
import { SearchOutlined } from "@ant-design/icons";
import { Actions } from "components/common"
import useStores from "stores/StoreContext"
import MobxTable, { mergeParams } from "components/List/MobxTable"
import axios from "axios"
import { TabCard } from "components/common"
import { formatTime, TimeFormats } from "common/Constant";
import { exportFile } from "common/fileExport";
import { phoneXin,idCardXin,carXin } from 'utils';

const { RangePicker } = DatePicker;
const {TabPane} = Tabs

export default function Car() {
  const { carStore } = useStores()
  const [visible, setVisible] = React.useState(false)
  const [news, setNews] = React.useState({})
  const [state, setState] = React.useState('0')
  const [fileList, setFileList] = React.useState([])
  const [form] = Form.useForm();
  
  return (
    <Layout className='full'>
      <Tabs defaultActiveKey={state} style={{ marginLeft: 15, marginTop: 10 }}>
        <TabPane tab={<TabCard text='合作司机' />} key="0">
            <SearchForm 
            store={carStore}
            // type='pending'
            //   exportCata={exportCata}
            //   onValuesChange={(changedValues, allValues) => {
            //     const searchProps = ['startDate', 'endDate']
            //     // catalogueStore.searchParams = mergeParams(catalogueStore.searchParams, allValues, searchProps)
            //   }} 
              />
          <MobxTable
            rowKey="id"
            store={carStore}
            scroll={{y:550}}
            columns={[
              {
                title: '车辆所有人',
                dataIndex: 'ownerName',
              },
              {
                title: '车辆道路运输证号',
                dataIndex: 'vehicleRoadTransportation',
                render:(text) => carXin(text+'')
              },
              {
                title: '公司名称',
                dataIndex: 'companyName',
              },
              {
                title: '载重',
                dataIndex: 'loads',
                render: (text) => `${text}kg`
              },
              {
                title: '准驾车型',
                dataIndex: 'vehicleClass',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '司机姓名',
                dataIndex: 'driverName',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '司机电话',
                dataIndex: 'driverPhone',
                render: (text) => phoneXin(text)
              },
              {
                title: '车牌号',
                dataIndex: 'plateNumber',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '司机身份证',
                dataIndex: 'idNo',
                render: (text) => idCardXin(text)
              },
              {
                title: '车辆总质量',
                dataIndex: 'totalQuality',
                render: (text) => `${text}kg`
              },

            //   {
            //     title: '操作',
            //     render: (text, record) => <Button type='primary' onClick={del(record.repairId)}>删除运单</Button>
            //   }
            ]} />
        </TabPane>
       
      </Tabs>

    </Layout>
  )
}

function SearchForm(props) {
  const [form] = Form.useForm();
  return (
    <Form colon={false} hideRequiredMark={true}
      form={form}
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginTop:10 }}
      onValuesChange={props.onValuesChange}
    >
      <Space>
        <Form.Item
          name="searchField"
        
        >
         <Input allowClear placeholder='支持车牌号，手机号，司机姓名' style={{width:400}}/>
        </Form.Item>
        <Form.Item>
          <Button type='primary'
            onClick={() => {
            
              props.store.searchParams.searchField = form.getFieldsValue(true).searchField || null
              props.store.searchParams.current = 1
              props.store.search()
            }}
          >
            查询</Button>
        </Form.Item>
      </Space>
    </Form>
  )
}