import moment from "moment"
export const GAODE_KEY = '07947b41a651aa43e869427f8e4141c9'
export const vehicleLengths = [
    '不限',
    1.8,
    2.7,
    3.8,
    4.2,
    5,
    6.2,
    6.8,
    7.7,
    8.2,
    8.7,
    9.6,
    11.7,
    12.5,
    13,
    15,
    16,
    17.5,
    //   '其他'
]

export const insuranceList = [
    {
        code: '1',
        value: '无'
    },
    {
        code: 'ABIC',
        value: '安邦财产保险股份有限公司'
    },
    {
        code: 'AICS',
        value: '永诚财产保险股份有限公司'
    },
    {
        code: 'BOCI',
        value: '中银保险有限公司'
    },
    {
        code: 'BPIC',
        value: '渤海财产保险股份有限公司'
    },
    {
        code: 'CAIC',
        value: '长安责任保险股份有限公司'
    },

    {
        code: 'CCIC',
        value: '中国大地财产保险股份有限公司'
    },
    {
        code: 'CICP',
        value: '中华联合财产保险公司'
    },

    {
        code: 'CPIC',
        value: '中国太平洋财产保险股份有限公司'
    },
    {
        code: 'DBIC',
        value: '都邦财产保险股份有限公司'
    },

    {
        code: 'GPIC',
        value: '中国人寿财产保险公司'
    },
    {
        code: 'HAIC',
        value: '华安财产保险股份有限公司'
    },

    {
        code: 'HTIC',
        value: '华泰财产保险股份有限公司'
    },
    {
        code: 'MACN',
        value: '民安保险(中国)有限公司'
    },

    {
        code: 'PAIC',
        value: '中国平安财产保险股份有限公司'
    },
    {
        code: 'PICC',
        value: '中国人民财产保险股份有限公司'
    },


    {
        code: 'TAIC',
        value: '天安保险股份有限公司'
    },
    {
        code: 'TPIC',
        value: '太平保险有限公司'
    },
    {
        code: 'YDCX',
        value: '英大泰和人寿保险股份有限公司'
    },
    {
        code: 'YGBX',
        value: '阳光财产保险股份有限公司'
    },
    {
        code: 'ZKIC',
        value: '紫金财产保险公司'
    },
    {
        code: 'MACN',
        value: '民安保险（中国）有限公司'
    },
    {
        code: 'YAIC',
        value: '永安财产保险股份有限公司'
    },
    {
        code: 'TPBX',
        value: '天平保险公司'
    },
    {
        code: 'ACIC',
        value: '安诚财产保险股份有限公司'
    },
    {
        code: 'DHIC',
        value: '鼎和财产保险股份有限公司'
    },

    {
        code: 'ALIC',
        value: '安联保险公司'
    },
    {
        code: 'QITA',
        value: '其他保险公司'
    },
]

export const weightUnit = ['吨']
export const vechleType = [
    '不限',
    '平板',
    '高栏',
    '厢式',
    '高地板',
    '保温',
    '冷藏',
    '危险品',
    '自卸',
    '面包车',
    '棉被车',
    '爬梯车',
    '集装箱',
]
export const vehicleModels = {
    'high_low': '高低板',
    'high_sided': '高栏',
    'flatbed': '平板',
    'van': '厢式',
    '不限': '不限'
}


export const vehicleLengthModels = [
    { length: '4.2', model: ['high_sided', 'van', 'flatbed'] },
    { length: '6.8', model: ['high_sided', 'van', 'flatbed'] },
    { length: '9.6', model: ['high_sided', 'van', 'flatbed'] },
    { length: '7.6', model: ['high_sided', 'van', 'flatbed'] },
    { length: '13', model: ['high_sided', 'high_low', 'flatbed', 'van'] },
    { length: '13.5', model: ['high_sided', 'van'] },
    { length: '17.5', model: ['high_sided', 'van'] },
    { length: '5.2', model: ['van'] },
    { length: '6.5', model: ['high_sided'] },
    { length: '10', model: ['van'] },
    { length: '12.5', model: ['van', 'flatbed'] },
    { length: '14.5', model: ['van'] },
    { length: '16.5', model: ['high_sided', 'van', 'flatbed', 'high_low'] },
]


export const TimeFormats = {
    YMD: "YYYY-MM-DD",
    YMDHM: "YYYY-MM-DD HH:mm",
    YMDHMS: "YYYY-MM-DD HH:mm:ss",
    HMS: 'HH:mm:ss'
}

export const formatTime = (value, format = TimeFormats.YMDHM) => {
    return moment(value).format(format)
}

export const billState = {
    "unsubmit": "未提交",
    "rejected": "已驳回",
    "unpaid": "未结清",
    "paid": "已结清"
}

export const surcharge = {
    "earlier": "提货附加费",
    "later": "卸货附加费",
    "transport": "运费"
}

export const regular = {
    realName: /^[\u4e00-\u9fa5_a-zA-Z]{1,10}$/,
    idNo: /(^\d{15}$)|(^\d{17}([0-9]|X)$)/,
    phone: /^1(3|4|5|6|7|8|9)\d{9}$/,
    companyName: /^[\u4e00-\u9fa5_a-zA-Z（）]{1,50}$/,
    unifiedSocialCreditCode: /^[0-9A-Z]{18}$/,
    password: /^[0-9A-Za-z\\W]{6,12}$/,
    telOrPhone: /(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}/,
    email: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
    positiveInteger: /^[1-9]\d*$/,
    bankCardNo: /^\d+$/,
    price: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1})?$/,
    faxNo: /^(\d{3,4})-\d{7,8}$/,
    telephone: /0\d{2,3}-\d{7,8}/
};

export const halfScreenHeight = (window.innerHeight - 66) / 2

export const noData = (value) => {
    return value || '--'
}