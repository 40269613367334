import React, { Component } from 'react';
import {Button,message} from "antd"
import { observer, inject } from "mobx-react";
import axios from "axios"

export default function(props){

    const [count,setCount] = React.useState(0)

    const getCaptcha = () => {
        const { option, phone,accountName,captcha,captchaKey } = props;
        if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone) || !phone) {
          message.warning("请输入正确的手机号");
          return false;
        }
        if(!accountName){
          message.warning("请输入账号");
          return false;
        }
        if(!captcha){
          message.warning("请输入图形验证码");
          return false;
        }
        
        axios.get(`/api/auth/code`,{params:{phone,accountName, option,captcha,captchaKey}})
        .then(res => {
            message.success("短信已经下发到联系人手机号码上，请注意查收");
            setCount(60)
        })
      };

      React.useEffect(() => {
        if(count == 0){
          return 
        }
        const timer = setInterval(() => {
            setCount(count - 1)
            if (count <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        return () => clearInterval(timer);
      },[count])

    return (
        <Button
            disabled={count > 0}
            onClick={getCaptcha}
            type="primary"
            ghost
            {...props}
        >
        {count ? `请稍等${count}秒` : "获取验证码"}
        </Button>
    )
}