import { observable } from "mobx";
import axios from "axios";

export class RiskStore {

    // 申述
    complaintParmas = {risk:2,pageSize:500,current:1}
    complaintResult = observable({})

    searchComplaint () {
        axios('/api/logistics/risk',{params:this.complaintParmas}).then(
            res => {
                this.complaintResult.results = res.data.data.results;
                this.complaintResult.count = res.data.data.count
            }
        )
    }
    // 审核
    auditParmas = {risk:3,pageSize:500,current:1}
    auditResult = observable({})

    auditComplaint () {
        axios('api/logistics/risk',{params:this.auditParmas}).then(
            res => {
                this.auditResult.results = res.data.data.results;
                this.auditResult.count = res.data.data.count
            }
        )
    }

    // 不可开票
    notParmas = {risk:4,pageSize:500,current:1}
    notResult = observable({})

    notComplaint () {
        axios('api/logistics/risk',{params:this.notParmas}).then(
            res => {
                this.notResult.results = res.data.data.results;
                this.notResult.count = res.data.data.count
            }
        )
    }
}