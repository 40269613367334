import React, { useState, useEffect } from "react";
import { Layout, Avatar,Row,Col,Modal,Space } from "antd";
import styled from "styled-components";
import Logo from "components/Logo"
import {PoweroffOutlined} from "@ant-design/icons";
import moment from "moment"
import {logout,session} from "common/session"
import { useHistory } from "react-router-dom";
import useStores from "stores/StoreContext"

export default function(props){
    return (
        <Layout.Header 
        style={{
            backgroundColor:'#1D6CFD',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'}}>
            <Logo text='双龙火云智慧物流货运平台客户端'/>
            <HeaderRight {...props}/>
        </Layout.Header>
    )
}


function HeaderRight(){
    const [now,setNow] = React.useState(moment())
    const {profileStore} = useStores()
    let history = useHistory()

    React.useEffect(() => {
        profileStore.get()
        const timer = setInterval(() => {
            setNow(moment())
        },1000)
        return () => clearInterval(timer)
    },[])

    const toLogout = () => {
        Modal.confirm({
            title:'是否退出登录',
            okText:'是',
            cancelText:'否',
            onOk:() => logout(history)
        })
    }

    return (
        <div style={{
            color:'white'
        }}>
            <Space size={60}>
                <Space size='large'>
                    <span>{now.format('HH:mm:ss')}</span>
                    <span>{now.format('YYYY年MM月DD日')}</span>
                </Space>
                <Space>
                    <Avatar size={40}>USER</Avatar>
                    <div 
                    title={profileStore.profile?.companyName}
                    style={{width:'200px'}}
                    className='ant-table-cell-ellipsis'>
                        欢迎你！{profileStore.profile?.companyName}
                    </div>
                    <PoweroffOutlined onClick = {toLogout}/>
                </Space>
            </Space>
        </div>
    )
}

