import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LogoImg from "assert/images/logo.png"

export default function(props:any){
    return (
        <div style={{
            height:'100%',
            display:'flex',
            alignItems:'center'
        }}>
            <img src={LogoImg} style={{height:'50%'}}/>
            <span style={{
                color:'white',
                fontSize:'20px',
                fontWeight:'bold',
                letterSpacing:'2px',
                marginLeft:'10px'
            }}>{props.text}</span>
        </div>
    )
}
