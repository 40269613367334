import React, { useState, useEffect } from "react";
import { Upload, Modal, message, Spin } from "antd"
import { PlusOutlined,LoadingOutlined  } from '@ant-design/icons';
import axios from "axios";


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default function CImageNew(props) {
  console.log('我重新渲染了');
  const [visible, setVisible] = React.useState(false)
  const [img, setImg] = React.useState('')
  const [fileList, setFileList] = React.useState()
  const [loading,setLoading] = React.useState(false)
  const uploadButton = (
    <div>
      {loading?<LoadingOutlined />:<PlusOutlined />}
      {loading?<div className="ant-upload-text">上传中</div>:<div className="ant-upload-text">上传</div>}
    </div>
  );
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImg(file.url || file.preview)
    setVisible(true)
  }
  function getInfo(type, res) {
    switch (type) {
      case 'business_license':
        props.form.setFieldsValue({ companyName: res.data.data.name })
        props.form.setFieldsValue({ unifiedSocialCreditCode: res.data.data.registrationNumber })
        props.form.setFieldsValue({ companyAddress: res.data.data.address })
        break;
      case 'identification_front':
        props.form.setFieldsValue({ idNo: res.data.data.number })
        props.form.setFieldsValue({ companyLegalPerson: res.data.data.name })
        break;
    }
  }
  const beforeUpload = async (file) => {
    setLoading(true)
    try {
      let fd = new FormData()
      let url = ''
      switch (props.id) {
        case 'business_license':
          fd.append('file', file)
          url = '/api/ocr/businessLicense'
          break;
        case 'identification_front':
          fd.append('file', file)
          fd.append('side', 'front')
          url = '/api/ocr/idCard'
          break;
        case 'identification_reverse':
          fd.append('file', file)
          fd.append('side', 'back')
          url = '/api/ocr/idCard'
          break;
      }
      let res = await axios.post(url, fd)
      if (res.data.code == 100000) {
        getInfo(props.id, res)
        setLoading(false)
      } else {
        message.error('上传失败' + res.data.msg)
        setLoading(false)
        return false
      }
      const isLt3M = file.size / 1024 / 1024 < 15;
      if (!isLt3M) {
        message.error('图片超过15M，无法上传');
      }
      return isLt3M;
    } catch (error) {
      message.error(error.message)
      return false
    }

  }
  React.useEffect(() => {
    console.log(fileList);
    if (props.fileList?.length > 0 && props.fileList[props.fileList.length - 1].status) {
      setFileList(props.fileList)
    } else {
      props.fileList && props.fileList.pop()
      setFileList(props.fileList)
    }

  }, [props.fileList])

  const show = () => {
    if (fileList?.length >= (props.count || 1) && fileList[0].status) {
      return null
    }
    return uploadButton
  }

  return (
    <div>
        <Upload
          listType="picture-card"
          action="/api/file"
          onPreview={handlePreview}
          beforeUpload={beforeUpload}
         
          {...props}
        // fileList={fileList}
        >
          {show()}
        </Upload>
      <Modal
        visible={visible}
        title={props.title}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <img style={{ width: '100%' }} src={img} />
      </Modal>
    </div>
  )
}

export const getValueFromEvent = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  const { fileList } = e
  return fileList
  // if(fileList[fileList.length - 1]?.status){
  //   return fileList
  // }
}