import React from 'react'
import { Form, Input, Button, Layout, Divider, InputNumber, Modal, message, Space, Select, DatePicker, Upload, Tabs } from "antd"
import CommonSearch from "components/Search"
import { SearchOutlined } from "@ant-design/icons";
import { Actions } from "components/common"
import useStores from "stores/StoreContext"
import MobxTable, { mergeParams } from "components/List/MobxTable"
import axios from "axios"
import { TabCard } from "components/common"
import { formatTime, TimeFormats } from "common/Constant";
import { exportFile } from "common/fileExport";
import { phoneXin,idCardXin,carXin } from 'utils';

const { RangePicker } = DatePicker;
const {TabPane} = Tabs

export default function WaterList() {
  const { WaterStore } = useStores()
  const [visible, setVisible] = React.useState(false)
  const [state, setState] = React.useState('0')
  const [fileList, setFileList] = React.useState([])
  const [form] = Form.useForm();
  
  return (
    <Layout className='full'>
      <Tabs defaultActiveKey={state} style={{ marginLeft: 15, marginTop: 10 }}>
        <TabPane tab={<TabCard text='流水列表' />} key="0">
            <SearchForm 
            store={WaterStore}
            // type='pending'
            //   exportCata={exportCata}
              onValuesChange={(changedValues, allValues) => {
                const searchProps = ['startDate','endDate','searchField','oparteType','countType']
                WaterStore.searchParams = mergeParams(WaterStore.searchParams, allValues, searchProps)
              }} 
              />
          <MobxTable
            rowKey="id"
            store={WaterStore}
            scroll={{y:550}}
            columns={[
              {
                title: '流水编号',
                dataIndex: 'ownerName',
              },
              {
                title: '时间',
                dataIndex: 'vehicleRoadTransportation',
                render:(text) => carXin(text+'')
              },
              {
                title: '操作人',
                dataIndex: 'companyName',
              },
              {
                title: '金额',
                dataIndex: 'loads',
                render: (text) => `${text}kg`
              },
              {
                title: '操作后收入金额',
                dataIndex: 'vehicleClass',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '操作后对公金额',
                dataIndex: 'driverName',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '操作后对私金额',
                dataIndex: 'driverPhone',
                render: (text) => phoneXin(text)
              },
              {
                title: '备注',
                dataIndex: 'plateNumber',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },

            //   {
            //     title: '操作',
            //     render: (text, record) => <Button type='primary' onClick={del(record.repairId)}>删除运单</Button>
            //   }
            ]} />
        </TabPane>
       
      </Tabs>

    </Layout>
  )
}

function SearchForm(props) {
  const [form] = Form.useForm();
  return (
    <Form colon={false} hideRequiredMark={true}
      form={form}
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginTop:10 }}
      onValuesChange={props.onValuesChange}
    >
      <Space>
        <Form.Item
          name="searchField" 
          label="流水编号"
        >
         <Input allowClear placeholder='请输入流水编号' style={{width:200}}/>
        </Form.Item>
        <Form.Item
          name="countType" 
          label="余额类型"
          style={{width:'200px'}}
        >
         <Select allowClear >
            <Select.Option value={1}>
                增加
            </Select.Option>
            <Select.Option value={0}>
                减少
            </Select.Option>
         </Select>
        </Form.Item>
        <Form.Item
          name="oparteType" 
          label="操作类型"
          style={{width:'200px'}}
        >
         <Select allowClear >
            <Select.Option value={1}>
                增加
            </Select.Option>
            <Select.Option value={0}>
                减少
            </Select.Option>
         </Select>
        </Form.Item>
        <Form.Item
            label='选择日期'
            name="publishDate"
            getValueFromEvent={(e)=>{
                if(e){
                    form.setFieldsValue({startDate:e[0].valueOf()})
                    form.setFieldsValue({endDate:e[1].valueOf()})
                }else{
                    form.setFieldsValue({startDate:null})
                    form.setFieldsValue({endDate:null})
                }
            }}>
                <RangePicker allowClear/>
            </Form.Item>
        <Form.Item>
          <Button type='primary'
            onClick={() => {
            
              props.store.searchParams.searchField = form.getFieldsValue(true).searchField || null
              props.store.searchParams.current = 1
              props.store.search()
            }}
          >
            查询</Button>
        </Form.Item>
      </Space>
    </Form>
  )
}