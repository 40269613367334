export const privateBanks = [
    {
        pmodeId:1025,
        name:'中国工商银行',
        type:'bank-icbc'
    },
    {
        pmodeId:1051,
        name:'中国建设银行',
        type:'bank-cbc'
    },
    {
        pmodeId:104,
        name:'中国银行',
        type:'bank-zgb'
    },
    {
        pmodeId:103,
        name:'中国农业银行',
        type:'bank-zgcb'
    },
    {
        pmodeId:3407,
        name:'交通银行',
        type:'bank-jtb'
    },
    {
        pmodeId:3230,
        name:'中国邮政储蓄银行',
        type:'bank-zgyzcxb'
    },
    {
        pmodeId:3080,
        name:'招商银行',
        type:'bank-zsb'
    },
    {
        pmodeId:313,
        name:'中信银行',
        type:'bank-zxb'
    },
    {
        pmodeId:314,
        name:'上海浦东银行',
        type:'bank-shpdb'
    },
    {
        pmodeId:309,
        name:'兴业银行',
        type:'bank-xyb'
    },
    {
        pmodeId:305,
        name:'中国民生银行',
        type:'bank-zgmsb'
    },
    {
        pmodeId:312,
        name:'中国光大银行',
        type:'bank-zggdb'
    },
    {
        pmodeId:307,
        name:'平安银行',
        type:'bank-pab'
    },
    {
        pmodeId:311,
        name:'华夏银行',
        type:'bank-hxb'
    },
    {
        pmodeId:310,
        name:'北京银行',
        type:'bank-bjb'
    },
    {
        pmodeId:3061,
        name:'广发银行',
        type:'bank-gfb'
    },
    {
        pmodeId:326,
        name:'上海银行',
        type:'bank-shb'
    },
    {
        pmodeId:335,
        name:'北京农商银行',
        type:'bank-bjnsb'
    },
    {
        pmodeId:342,
        name:'重庆农村商业银行',
        type:'bank-cqncbb'
    },
    {
        pmodeId:343,
        name:'上海农村商业银行',
        type:'bank-shncbb'
    },
    {
        pmodeId:316,
        name:'南京银行',
        type:'bank-njb'
    },
    {
        pmodeId:302,
        name:'宁波银行',
        type:'bank-nbb'
    },
    {
        pmodeId:324,
        name:'杭州银行',
        type:'bank-hzb'
    },
    {
        pmodeId:336,
        name:'成都银行',
        type:'bank-cdb'
    },
    {
        pmodeId:3341,
        name:'青岛银行',
        type:'bank-qdb'
    },
    {
        pmodeId:344,
        name:'恒丰银行',
        type:'bank-hfb'
    },
]

export const publicBanks = [
    {
        pmodeId:9102,
        name:'中国工商银行',
        type:'bank-icbc'
    },
    {
        pmodeId:9103,
        name:'中国农业银行',
        type:'bank-zgcb'
    },
    {
        pmodeId:9104,
        name:'交通银行',
        type:'bank-jtb'
    },
    {
        pmodeId:9105,
        name:'中国建设银行',
        type:'bank-cbc'
    },
    {
        pmodeId:9107,
        name:'招商银行',
        type:'bank-zsb'
    },
    {
        pmodeId:9108,
        name:'中国光大银行',
        type:'bank-zggdb'
    },
    {
        pmodeId:9109,
        name:'中国银行',
        type:'bank-zgb'
    },
] 

export const creditBanks = [
    {
        pmodeId:1027,
        name:'中国工商银行',
        type:'bank-icbc'
    },
    {
        pmodeId:1054,
        name:'中国建设银行',
        type:'bank-cbc'
    },
    {
        pmodeId:106,
        name:'中国银行',
        type:'bank-zgb'
    },
    {
        pmodeId:1031,
        name:'中国农业银行',
        type:'bank-zgcb'
    },
    {
        pmodeId:3011,
        name:'交通银行',
        type:'bank-jtb'
    },
    {
        pmodeId:3231,
        name:'中国邮政储蓄银行',
        type:'bank-zgyzcxb'
    },
    {
        pmodeId:308,
        name:'招商银行',
        type:'bank-zsb'
    },
    {
        pmodeId:3131,
        name:'中信银行',
        type:'bank-zxb'
    },
    {
        pmodeId:3141,
        name:'上海浦东银行',
        type:'bank-shpdb'
    },
    {
        pmodeId:3091,
        name:'兴业银行',
        type:'bank-xyb'
    },
    {
        pmodeId:3051,
        name:'中国民生银行',
        type:'bank-zgmsb'
    },
    {
        pmodeId:3121,
        name:'中国光大银行',
        type:'bank-zggdb'
    },
    {
        pmodeId:3071,
        name:'平安银行',
        type:'bank-pab'
    },
    {
        pmodeId:3112,
        name:'华夏银行',
        type:'bank-hxb'
    },
    {
        pmodeId:306,
        name:'广发银行',
        type:'bank-gfb'
    },
    {
        pmodeId:3261,
        name:'上海银行',
        type:'bank-shb'
    },
    {
        pmodeId:303,
        name:'宁波银行',
        type:'bank-nbb'
    },
    {
        pmodeId:3241,
        name:'杭州银行',
        type:'bank-hzb'
    },
    {
        pmodeId:334,
        name:'青岛银行',
        type:'bank-qdb'
    },
]