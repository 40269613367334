import React, { PureComponent } from "react";
import { observable, autorun } from "mobx";
import axios from "axios";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  forceRefresh: true
});

export const session = observable({token:null});

export const SessionCtx = React.createContext({});

const TOKEN_KEY = "access_token";

const token = localStorage.getItem(TOKEN_KEY);
if (token) {
  session.token = token;
}

autorun(async () => {
    if (session.token) {
        localStorage.setItem(TOKEN_KEY, session.token);
    }

    if (session.token === null) {
      localStorage.removeItem(TOKEN_KEY);
    }
});

axios.interceptors.response.use(null, (error) => {
  switch (error.response.status) {
    case 401:
      localStorage.removeItem(TOKEN_KEY);
      session.token = null;
      history.push('/login')
      break;
  }
  return Promise.reject(error);
});

export function logout() {
  session.token = null;
  history.push('/login')

}

export async function login(form) {
  const resp = await axios.post(`/api/auth`,form)
  session.token = resp.data.taken
  if(resp.data.user.approved){
    history.push('/home')
  }else{
    history.push("/setting")
  }
  
}

export function Authenticated({children}) {
  return (
    <SessionCtx.Provider>
      {children}
    </SessionCtx.Provider>
  );
}
