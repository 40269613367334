import React, { useEffect, useState,useRef } from 'react'
import { Form, Input, Button, Layout, Divider, InputNumber, Modal, message, Space, Select, DatePicker, Upload, Tabs, Row, Col, Card, Image } from "antd"
import './index.less'
import CommonSearch from "components/Search"
import { SearchOutlined } from "@ant-design/icons";
import { Actions } from "components/common"
import useStores from "stores/StoreContext"
import { createBrowserHistory } from 'history';
import MobxTable, { mergeParams } from "components/List/MobxTable"
import axios from "axios"
import { JSEncrypt } from 'jsencrypt'
import { TabCard } from "components/common"
import { formatTime, TimeFormats } from "common/Constant";
import { exportFile } from "common/fileExport";
import { phoneXin, idCardXin, carXin } from 'utils';
import { insuranceList, vechleType, weightUnit, vehicleLengths, noData, vehicleModels, vehicleLengthModels, halfScreenHeight, regular, } from "common/Constant"
import {
  SettingFilled,
  TransactionOutlined,
  FolderFilled,
  FireFilled
} from "@ant-design/icons";
import { values } from 'lodash';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs
const history = createBrowserHistory({
  forceRefresh: true
});
export default function UserIfno() {
  const img = "https://gcsz-check-point.oss-cn-chengdu.aliyuncs.com/upload/test/8750619_1696747467813.jpg"
  const { carStore } = useStores()
  const {profileStore} = useStores()
  const [visible, setVisible] = React.useState(false)
  const [news, setNews] = React.useState({})
  const [state, setState] = React.useState('0')
  const [fileList, setFileList] = React.useState([])
  const [isShow,setIsShow] = React.useState(false)
  const [showPayPwd,setShowPayPwd] = React.useState(false)
  const [personalBalance,setPersonalBalance] = useState(0.00)
  const [personalInfo,setPersonalInfo] = useState({})
  const [companyInfo,setCompanyInfo] = useState({})
  const [key,setKey] = useState('')
  const [payInfo,setPayInfo] = useState({})
  const [showAmountTxt,setShowAmountTxt] = useState(false)
  const [showBankTxt,setShowBankTxt] = useState(false)
  const amountInput = useRef()
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [editPwdForm] = Form.useForm();
  
  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 18 },
  };
  const addressLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 10 },
  }
  const bankList = [
    {
      id: 1,
      name: '交通银行',
      value: 'BCOM'
    },
    {
      id: 2,
      name: '建设银行',
      value: 'CCB'
    },
    {
      id: 3,
      name: '招商银行',
      value: 'CMB'
    }
  ]
  const [count, setCount] = useState(0)
  const list = [
    {
      title: '支付密码',
      icon: SettingFilled,
      key: 'SettingFilled'
    },
    {
      title: '登录密码',
      icon: TransactionOutlined,
      key: 'TransactionOutlined'
    },
    {
      title: '充值',
      icon: FolderFilled,
      key: 'FolderFilled'
    },
    {
      title: '资金管理',
      icon: FireFilled,
      key: 'FireFilled'
    }
  ]

  useEffect(() => {
    getPersonalBalance()
  },[])
  const getPersonalBalance = () => {
    let userId = profileStore.profile.id
    axios.get(`/api/shipper/selectBalance?id=${userId}`).then(res => {
      let result =  res.data
      setPersonalBalance(result)
    })
    axios.get(`/api/shipper/getCompanyInfo?id=${userId}`).then(res => {
      let companyInfo =  res.data.helibaoCompanyInfo
      let personalInfo = res.data.helibaoInfo
      setPersonalInfo(personalInfo)
      setCompanyInfo(companyInfo)
    })
  }

  const handleAcount = (index) => {
    if(index === 0)  setShowPayPwd(true)
    if(index === 1)  history.push("/setting")
    if(index === 2)  setIsShow(true)
    if(index === 3)  testPay()
  }
  
  //测试转账
  const testPay = () => {
    return
    axios.get(`/api/shipper/getPublicKey`).then(res => {
      let encryptor = new JSEncrypt()
      encryptor.setPublicKey(res.data)
      let password = encryptor.encrypt('1234567')
      let data = {
        helibaoNum: 'E1807958804',
        inHelibaoNum: 'E1807977245',
        money:1,
        password: password
      }
      axios.post(`/api/shipper/transferAccount`,data).then(res => {
        console.log('res',res)
      })
    })
    
  }
  const getSign = (data) => {
    let userId = profileStore.profile.id
    axios.get(`/api/shipper/toRecharge?shipperId=${userId}&money=${data.P4_orderAmount}&bankNum=${data.P5_bankId}`).then(res => {
      let data = res.data
      form2.setFieldsValue({...data})
      setPayInfo(data)
      document.getElementById('frm').submit()
    })
   
  }
  const subOk = () => {
    form2.validateFields().then(values => {
      console.log('value',values)
      getSign(values)
    })
 }
 const getPublicKey = () => {
  
 }
 const handleSumbit = () => {
   getPublicKey()
  let userId = profileStore.profile.id
  editPwdForm.validateFields().then(values => {
    axios.get(`/api/shipper/getPublicKey`).then(res => {
      let encryptor = new JSEncrypt()
      encryptor.setPublicKey(res.data)
      values.shipperId = userId
      values.oldPassword = encryptor.encrypt(values.oldPassword)
      values.password = encryptor.encrypt(values.password)
      values.cofirmPayPassword = encryptor.encrypt(values.cofirmPayPassword)
      axios.post(`/api/shipper/changePassword`,values).then(res => {
        console.log('res',res)
        message.success(res.data)
        editPwdForm.resetFields()
        setShowPayPwd(false)
      })
    })
  })
 }

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card style={{ width: '100%', borderRadius: '20px', height: '90vh' }}>
            <div>
              <h1>我的账户</h1>
              <div className='account_content'>
                <div className='title_price'>
                  <span className='count'>{personalBalance.toFixed(2)}</span> 元
                </div>
                <div className='pay_area'>
                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {list.map((item,index) => {
                      return <div className='pay_list' onClick={()=>handleAcount(index)}>
                        <item.icon style={{ color: 'rgb(66 113 199)' }} />
                        <div>{item.title}</div>
                      </div>
                    })}
                  </div>
                </div>
                <div className='pay_footer'>
                  <p className='pay_p'>
                    您可以通过网银转账方式使用您的对公账户(仅支持对公账户)付款到 以下任一账户,确认您的付款银行告知支付成功后，再查看余额，如果2小时后余额 仍未更新请联系我们的客服处理!
                  </p>
                  <div className='pay_list'>
                    <div className='pay_item'>
                        <div>公司名称：{companyInfo.companyName}</div>
                        <div>开户银行：{companyInfo.openingBank}</div>
                        <div>银行卡号：{companyInfo.openingBank}</div>
                        <div>联行号：</div>
                    </div>
                    {/* {payList.map(item => {
                      return <div className='pay_item'>
                        <div>公司名称：{item.name}</div>
                        <div>开户银行：{item.bank}</div>
                        <div>银行卡号：{item.bankNum}</div>
                        <div>联行号：{item.num}</div>
                      </div>
                    })} */}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: '100%', borderRadius: '20px', height: '90vh' }}>
            <div className='person_title'>个人认证(实名通过)</div>
            <Form
              className='form_content'
              form={form}
              layout='horizontal'
              {...layout}>
              <Row >
                <Col span={24}>
                  <Form.Item
                    label='身份证正面:'
                  >
                    {/* <div>已上传未审核</div> */}
                    <Image
                    width={200}
                    src={personalInfo.idcardFace}
                  />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='身份证反面:'
                  >
                    {/* <div>已上传未审核</div> */}
                    <Image
                    width={200}
                    src={personalInfo.idcardBack}
                  />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='真实姓名'
                    name="realName "
                  >
                    <span>{personalInfo.realName}</span>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='身份证号'
                    name="idcardNum"
                  >
                    <span>{personalInfo.idcardNum}</span>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='邮箱'
                    name="email"
                  >
                    <span>{personalInfo.email}</span>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: '100%', borderRadius: '20px', height: '90vh' }}>
            <div className='person_title'>公司认证(审核通过)</div>
            <Form
              className='form_content'
              form={form}
              layout='horizontal'
              {...layout}>
              <Row >
                <Col span={24}>
                  <Form.Item
                    label='营业执照'
                  >
                    <Image
                    width={200}
                    src={img}
                  />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='公司名称'
                    name="companyName"
                  >
                    <span>{companyInfo.companyName}</span>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='公司法人'
                    name="legalPerson"
                  >
                    <span>{companyInfo.legalPerson}</span>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='信用代码'
                    name="businessLicense"
                  >
                    <span>{companyInfo.businessLicense}</span>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='公司地址'
                    name="address"
                  >
                     <span>{companyInfo.address}</span>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='开户行'
                    name="openingBank"
                  >
                    <span>{companyInfo.openingBank}</span>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='开户行卡号'
                    name="openingBankNo"
                  >
                    <span>{companyInfo.openingBankNo}</span>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='公司电话'
                    name="companyPhone"
                  >
                    <span>{companyInfo.openingBankNo}</span>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal
          visible={isShow}
          width='500px'
          onOk={subOk}
          onCancel={() => {
              setIsShow(false)
              form.resetFields()
          }}
          title="在线充值">
             <Form  form={form2}  id="frm" action='http://pay.trx.helipay.com/trx/online/interface.action' method='post'  target='_blank'>
               <Form.Item
                    label='充值金额'
                    name="P4_orderAmount"
                  >
                     {/* <Input.Group compact>
                       <Input name="P4_orderAmount" type='Number' min={1} size="25"  style={{ width: 300 }}/>
                       <Button type="default">元</Button>
                    </Input.Group> */}
                    <Input ref={amountInput} name="P4_orderAmount" type='Number' size="25" value="" />
                  
                </Form.Item>
                  {
                      showAmountTxt ? <p style={{color:'red',fontSize:'12px'}}>请填写充值金额!</p>:''
                  }
              
                <Form.Item
                    label=''
                    name="P3_customerNumber"
                    style={{display:'none'}}
                  >
                     <Input type='hidden' name="P3_customerNumber"/>	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P2_orderId"
                    style={{display:'none'}}
                  >
                    <Input type='hidden' name="P2_orderId"/>
                </Form.Item>
              
                <Form.Item
                    label='银行类型'
                    name="P5_bankId"
                  >
                   <Select name="P5_bankId">
                    {
                        bankList.map((item,index) => {
                          return  <Option  value={item.value}>{item.name}</Option >
                        })
                      }
                  </Select>
                  
                </Form.Item>
                <Form.Item
                    label='银行类型'
                    name="P5_bankId"
                    style={{display:'none'}}
                  >
                  <Input  name="P5_bankId"/>
                  
                </Form.Item>
               
                {/* {
                    showBankTxt ?  <p style={{color:'red',fontSize:'12px'}}>请选择银行类型!</p>:''
                  }
                */}
                <Form.Item
                    label=''
                    name="P8_goodsName"
                    style={{display:'none'}}
                  >
                    <Input  name="P8_goodsName" size="25"  value="" />	
                </Form.Item>
                {/* <Input  name="P8_goodsName" size="25" />	 */}
                <Form.Item
                    label=''
                    name="P10_periodUnit"
                    style={{display:'none'}}
                  >
                    <Input type='hidden' name="P10_periodUnit" size="25"  value="" />	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P11_callbackUrl"
                    style={{display:'none'}}
                  >
                    <Input type='hidden' name="P11_callbackUrl" size="25"  value="" />	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P12_serverCallbackUrl"
                    style={{display:'none'}}
                  >
                    <Input type='hidden' name="P12_serverCallbackUrl" size="25"  value="" />	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P15_desc"
                    style={{display:'none'}}
                  >
                    <Input type='hidden' name="P15_desc" size="25"  value="" />	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P1_bizType"
                    style={{display:'none'}}
                  >
                     <Input type='hidden' name="P1_bizType" size="25"  value="" />
                </Form.Item>
                <Form.Item
                    label=''
                    name="P6_business"
                    style={{display:'none'}}
                  > 
                      <Input type='hidden' name="P6_business" value=''/>	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P7_timestamp"
                    style={{display:'none'}}
                  >
                     <Input type='hidden' name="P7_timestamp" value=''/>	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P17_signatureType"
                    style={{display:'none'}}
                  >
                     <Input type='hidden' name="P17_signatureType" value=''/>	
                </Form.Item>
                <Form.Item
                    label=''
                    name="sign"
                    style={{display:'none'}}
                  >
                    <Input type='hidden' name="sign" value=''/>	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P13_orderIp"
                    style={{display:'none'}}
                  >
                    <Input type='hidden' name="P13_orderIp" value=''/>	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P14_onlineCardType"
                    style={{display:'none'}}
                  >
                     <Input type='hidden' name="P14_onlineCardType" value=''/>	
                </Form.Item>
                <Form.Item
                    label=''
                    name="P9_period"
                    style={{display:'none'}}
                  >
                   <Input type='hidden' name="P9_period" value=''/>	
                </Form.Item>
             </Form>
      </Modal>

      <Modal
          visible={showPayPwd}
          width='500px'
          onOk={handleSumbit}
          onCancel={() => {
              setShowPayPwd(false)
              editPwdForm.resetFields()
          }}
          title="修改支付密码">
          <Form form={editPwdForm} labelCol={{span: 7}}>
            <Form.Item
                label='原支付密码'
                name="oldPassword"
                validateFirst={true}
                rules={[
                  { required: true, message: "请输入密码" },
                  {pattern:regular.password,message:'请输入6-12位密码'}]}
                >
                <Input.Password name="oldPassword" placeholder="请输入原支付密码" style={{ width: 300 }}/> 
            </Form.Item>
            <Form.Item
                label='新支付密码'
                name="password"
                validateFirst={true}
                rules={[
                  { required: true, message: "输入新密码" },
                  {pattern:regular.password,message:'请输入6-12位密码'}]}
                >
                <Input.Password name="password" placeholder="请输入新支付密码" style={{ width: 300 }}/> 
            </Form.Item>
            <Form.Item
                label='确认新支付密码'
                name="cofirmPayPassword"
                validateFirst={true}
                rules={[
                    { required: true, message: "重新输入新密码" },
                    {pattern:regular.password,message:'请输入6-12位密码'},
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('密码不一致');
                        },
                    })
                ]}
                >
                <Input.Password name="cofirmPayPassword"  placeholder="请输入确认支付密码" style={{ width: 300 }}/> 
            </Form.Item>
          </Form>  
      </Modal>
    </div>
  )
}
