import React from 'react'
import { Form, Input, Button, Layout, Divider, InputNumber, Modal, message, Space, Select, DatePicker, Upload, Tabs } from "antd"
import CommonSearch from "components/Search"
import { SearchOutlined } from "@ant-design/icons";
import { Actions } from "components/common"
import useStores from "stores/StoreContext"
import MobxTable, { mergeParams } from "components/List/MobxTable"
import axios from "axios"
import { TabCard } from "components/common"
import { formatTime, TimeFormats } from "common/Constant";
import { exportFile } from "common/fileExport";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs

export default function Catalogue() {
  console.log(111);
  const { catalogueStore } = useStores()
  const [visible, setVisible] = React.useState(false)
  const [news, setNews] = React.useState({})
  const [state, setState] = React.useState(1)
  const [fileList, setFileList] = React.useState([])
  const [form] = Form.useForm();
  const fileRemove = (file) => {
    const list = fileList.filter(item => item.uid != file.uid)
    setFileList(list)
  }
  const fileChange = ({ file }, fileList) => {
    file.status = 'done'
    console.log({ file });
    let fd = new FormData()
    fd.append('file', file)
    Modal.confirm({
      title: '是否确定上传',
      okText: '是',
      cancelText: '否',
      onOk: () => {

        axios.post('/api/billRepair/upload', fd).then(
          res => {
            if (res.data.code === 100000) {
              setFileList(fileList => [...fileList, file])
              catalogueStore.search()
              setVisible(false)
              message.success('上传成功')
            }
            else {
              setFileList(fileList => [...fileList])
              message.error('上传失败,' + res.data.msg)
            }
          },
          err => {
            console.log(err);
            setFileList(fileList => [...fileList])
            message.error('上传失败' + err.message)
          })

      }
    })

  }

  const props = {
    action: '#',
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      showInfo: true,
      strokeWidth: 3,
    },
  }
  function callback(key) {


  }

  function exportCata() {
    setVisible(true)
  }
  function downTemplate() {
    exportFile()
  }
  function del(id) {
    return function () {
      Modal.confirm({
        title: '是否确定删除',
        okText: '是',
        cancelText: '否',
        onOk: () => {

          axios.get('/api/billRepair/del/' + id).then((res) => {
            if (res.data.code === 100000) {
              message.success('删除成功')
              catalogueStore.search()
            } else {
              message.error('删除失败:' + res.data.msg)
            }
          },
            err => message.error('删除失败:' + err.message))
        }
      })
    }
  }
  return (
    <Layout className='full'>
      <Tabs defaultActiveKey={state} onChange={callback} style={{ marginLeft: 15, marginTop: 10 }}>
        <TabPane tab={<TabCard text='录单管理' />} key="0">
          <SearchForm type='pending'
            exportCata={exportCata}
            onValuesChange={(changedValues, allValues) => {
              const searchProps = ['startDate', 'endDate']
              catalogueStore.searchParams = mergeParams(catalogueStore.searchParams, allValues, searchProps)
            }} />
          <MobxTable
            rowKey="id"
            store={catalogueStore}
            scroll={{ y: 550 }}
            columns={[
              {
                title: '序号',
                render: (text, record, index) => index + 1
              },
              {
                title: '导入时间',
                dataIndex: 'createTime',
                render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '运单数',
                dataIndex: 'repairSum',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '起始单号',
                dataIndex: 'repairId',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '表单名称',
                dataIndex: 'fileName',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '操作',
                render: (text, record) => <Button type='primary' onClick={del(record.repairId)}>删除运单</Button>
              }
            ]} />
          <Modal
            visible={visible}
            footer={null}
            width='800px'
            onCancel={() => {
              setVisible(false)
            }}
            title={
              <div style={{
                fontSize: '20px',
                lineHeight: 1.1,
                // backgroundColor:'#0a90f4'
              }}>导入</div>
            }>
            <Form form={form}>
              <Form.Item>
                <Button type="link" size="small" onClick={downTemplate}>下载模板</Button>
              </Form.Item>
              <Form.Item>
                <Upload {...props} customRequest={fileChange} fileList={fileList} onRemove={fileRemove}>
                  <Button type="primary">Upload</Button>
                </Upload>
              </Form.Item>
              {/* <Form.Item name="date" label="数据年份"
            getValueFromEvent={e => {
              console.log(e);
              form.setFieldsValue({ start: e[0].valueOf() })
              form.setFieldsValue({ end: e[1].valueOf() })
            }}
          >
            <RangePicker format="YYYY-MM-DD" />
          </Form.Item> */}
            </Form>
          </Modal>
        </TabPane>
        {/* <TabPane tab={<TabCard text='驳回运单' />} key="1">
            <SearchForm type='pending'
              exportCata={exportCata}
              onValuesChange={(changedValues, allValues) => {
                const searchProps = ['startDate', 'endDate']
                catalogueStore.searchParams = mergeParams(catalogueStore.searchParams, allValues, searchProps)
              }} />
          <MobxTable
            rowKey="id"
            store={catalogueStore}
            columns={[
              {
                title: '序号',
                render: (text, record, index) => index + 1
              },
              {
                title: '导入时间',
                dataIndex: 'createTime',
                render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '运单数',
                dataIndex: 'repairSum',
              },
              {
                title: '起始单号',
                dataIndex: 'repairId',
              },
              {
                title: '表单名称',
                dataIndex: 'fileName',
              },
              {
                title: '驳回理由',
                dataIndex: 'fileName',
                // render: (text) => formatTime(text, TimeFormats.YMDHM)
              },
              {
                title: '操作',
                render: (text, record) => <Button type='primary' onClick={del(record.repairId)}>运单下载</Button>
              }
            ]} />
        </TabPane> */}
      </Tabs>

    </Layout>
  )
}

function SearchForm(props) {
  const { catalogueStore } = useStores()
  const [form] = Form.useForm();
  return (
    <Form colon={false} hideRequiredMark={true}
      form={form}
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}
      onValuesChange={props.onValuesChange}
    >
      <Space>
        <Form.Item
          label='导入时间'
          name="date"
          getValueFromEvent={(e) => {
            debugger
            if (e) {
              form.setFieldsValue({ createStartDate: formatTime(e[0].valueOf(), TimeFormats.YMD) + ' ' + '00:00:00' })
              form.setFieldsValue({ createEndDate: formatTime(e[1].valueOf(), TimeFormats.YMD) + ' ' + '23:59:59' })
            } else {
              form.setFieldsValue({ createStartDate: '' })
              form.setFieldsValue({ createEndDate: '' })
            }
          }}
        >
          <RangePicker allowClear format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item>
          <Button type='primary'
            onClick={() => {
              // id = null
              // form.resetFields()
              // setVisible(true) 
              catalogueStore.searchParams.createStartDate = form.getFieldValue('createStartDate')
              catalogueStore.searchParams.createEndDate = form.getFieldValue('createEndDate')
              catalogueStore.search()
            }}
          >
            查询</Button>
        </Form.Item>
        <Form.Item>
          {/* <Upload {...prop} > */}
          <Button type='primary' onClick={props.exportCata}>运单导入</Button>
          {/* </Upload>, */}
        </Form.Item>
      </Space>
    </Form>
  )
}