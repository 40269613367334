import React, { useState, useEffect } from "react";
import { Form, Input, Cascader, Layout, Tabs, Table, Modal, message, Divider, DatePicker, Space, Select, Button, InputNumber } from "antd"
import CommonSearch from "components/Search"
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Actions } from "components/common"
import "../index.less"
import { TabCard } from "components/common"
import { Title } from "components/common"
import { observer } from 'mobx-react'
import useStores from "stores/StoreContext"
import { vehicleModels, noData, formatTime, TimeFormats, billState } from "common/Constant"
import { surcharge } from "common/Constant";
import MobxTable, { mergeParams } from "components/List/MobxTable"
import _ from "lodash"
import qs from "qs"
import axios from "axios"
import styled from "styled-components"
import ManyChange from "./BillComponents";
import moment from "moment"
import { price } from "utils";
import { useParams, useHistory, useLocation } from "react-router-dom"
// import vehicleCode from 'common/vehicle_code.json'
import { JSEncrypt } from 'jsencrypt'
const TdWrap = styled.div`

`
const cols = [
  { title: '', dataIndex: 'v1', width: '15%', className: 'cell', align: 'right' },
  { title: '', dataIndex: 'v2', width: '35%' },
  { title: '', dataIndex: 'v3', width: '15%', className: 'cell', align: 'right' },
  { title: '', dataIndex: 'v4', width: '35%' },
]
const billCols = [
  {
    title: "账单号",
    dataIndex: "id",
    ellipsis: true
  },
  {
    title: "出账日期",
    dataIndex: "createTime",
    ellipsis: true,
    render: (text) => formatTime(text)
  },
  {
    title: "运单号",
    dataIndex: "waybillId",
    ellipsis: true,
  },
  {
    title: "付款人",
    dataIndex: "sendName",
    ellipsis: true,
  },
  {
    title: "付款账号",
    dataIndex: "sendBankCardNo",
    ellipsis: true,
    render: (text) => noData(text)
  },
  {
    title: "费用类型",
    dataIndex: "billType",
    ellipsis: true,
    render: (text) => surcharge[text]
  },
  {
    title: "金额",
    dataIndex: "price",
    ellipsis: true,
    render: (text) => price(text)
  },
]

const { RangePicker } = DatePicker;
const { TabPane } = Tabs
const commonCol = [
  {
    title: "水单号",
    dataIndex: "id",
    width: 150
  },

  {
    title: "笔数",
    dataIndex: "billCount",
    width: 100
  },
  {
    title: "总金额",
    dataIndex: "billAmount",
    width: 100,
    render: (text) => price(text)
  },
  {
    title: "运费",
    dataIndex: "billPrice",
    width: 100,
    render: (text) => price(text)
  },
  {
    title: "平台服务费",
    dataIndex: "actServiceFee",
    width: 100,
    render: (text) => price(text)
  },
  {
    title: '收款人',
    width: 100,
    dataIndex: 'receiverName',
  },
  {
    title: '收款人账号',
    width: 200,
    dataIndex: 'receiverBankCardNo',
  },
  {
    title: "事项",
    dataIndex: "remark",
    render: (text) => noData(text),
    width: 100
  },
]

const searchProps = [
  'submitStartDate',
  'submitEndDate',
  'confirmStartDate',
  'confirmEndDate',
  'paidStartDate',
  'paidEndDate',
  'billAuditState',
  'invoice',
  'searchField'
]
let data = { amount: '0.00', serviceFee: '0.00', wayBillAmount: '0.00' }
export default observer(function () {
  const { billAuditStore } = useStores()
  const [tabPaneKey, setTabPaneKey] = React.useState('pending')
  const [isShow,setIsShow] = React.useState(false)
  let location = useLocation()
  let history = useHistory();
 
  const [items, setItems] = React.useState([])
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([])
  const [selectedRow, setSelectedRows] = React.useState([])
  const [priceObj, setAmount] = React.useState(data)
  const [visible, setVisible] = React.useState(false)
  const [manyVisible, setMany] = React.useState(false)
  const [remark, setRemark] = React.useState('')
  const [detail, setDetail] = React.useState({})
  const [previsible, setPreVisible] = React.useState(false)
  const [dedForm] = Form.useForm()
  const [deductionsVisible,setDeductionsVisible] = React.useState(false)

  const [form] = Form.useForm()
  const {profileStore} = useStores()
  const [personalInfo,setPersonalInfo] = useState({})
  const [orderInfo,setOrderInfo] = React.useState({})
  const [smount,setMmount] = React.useState(0)
/****
 * 
 */
const getPersonInfo = () => {
  let userId = profileStore.profile.id
  axios.get(`/api/shipper/getCompanyInfo?id=${userId}`).then(res => {
    console.log('res',res)
    let personalInfo = res.data.helibaoInfo
    setPersonalInfo(personalInfo)
  }) 
}

const subOk = () => {
  form.validateFields().then(values=>{
    axios.get(`/api/shipper/getPublicKey`).then(res => {
        let encryptor = new JSEncrypt()
        encryptor.setPublicKey(res.data)
        values.password = encryptor.encrypt(values.password)
        values.helibaoNum = personalInfo.helibaoNum
        values.money = orderInfo.billAmount
        values.inHelibaoNum = 'E1807977245'
        values.billAuditId = orderInfo.id

         axios.post(`/api/shipper/transferAccount`,values).then(res => {
            console.log('res',res)
            message.success('成功支付')
            setIsShow(false)
            form.resetFields()
        }).catch(e => {
            message.success(e)
            setIsShow(true)
        })
       
    })
                                    
  })
 
}


/*****
 * 
 */

  const reset = () => {
    setSelectedRowKeys([])
    setSelectedRows([])
  }
  function search(form, type, store) {
    const { confirmStartDate, confirmEndDate, paidEndDate, paidStartDate, searchField } = form.getFieldsValue(true)
    if (type == 'unpaid') {
      store.unpaidParams.confirmStartDate = confirmStartDate
      store.unpaidParams.confirmEndDate = confirmEndDate
      store.unpaidParams.searchField = searchField
      store.searchUnpaid()
    } else if (type == 'paid') {
      store.paidParams.paidStartDate = paidStartDate
      store.paidParams.paidEndDate = paidEndDate
      store.paidParams.searchField = searchField
      store.searchPaid()
    }
  }
  function manyChange() {
    if (selectedRow.length == 0) {
      message.warning('请选择最少一笔账单')
      return
    } else {
      setMany(true)
    }

  }
  return (
    <Layout className='full bill' style={{ paddingTop: '0px' }}>
      <Tabs activeKey={qs.parse(location.search.slice(1)).tabPaneKey || tabPaneKey}
        onChange={(key) => {
          history.push(`/billAudit?tabPaneKey=${key}`)
          setTabPaneKey(key)
        }}>
        {/* <TabPane key='pendings' tab={<TabCard text='待审核' />}>
          <CommonSearch title=''>
            <SearchForm type='pending'
              onValuesChange={(changedValues, allValues) => {
                billAuditStore.pendingParams.billAuditState = 'pending'
                billAuditStore.pendingParams = mergeParams(billAuditStore.pendingParams, allValues, searchProps, ['billAuditState'])
                billAuditStore.searchPending()
              }} />
          </CommonSearch>
          <MobxTable
            rowKey="id"
            store={billAuditStore}
            params='pendingParamss'
            result='pendingResults'
            method='searchPendings'
            columns={[
              {
                title: '提交时间',
                dataIndex: 'createTime',
                render: (text) => formatTime(text)
              },
              ...commonCol,
              {
                title: '操作',
                render: (text, record) => <Actions items={[
                  {
                    text: '账单明细',
                    onClick: () => history.push(`/billAudit/${record.id}/detail?state=pending`)
                  }
                ]} />
              }
            ]} />

        </TabPane> */}
        <TabPane key='pending' tab={<TabCard text='待处理' />}>
          {/* <CommonSearch title=''>
            <SearchForm type='pending'
              onValuesChange={(changedValues, allValues) => {
                billAuditStore.pendingParams.billAuditState = 'pending'
                billAuditStore.pendingParams = mergeParams(billAuditStore.pendingParams, allValues, searchProps, ['billAuditState'])
                billAuditStore.searchPending()
              }} />
          </CommonSearch> */}
          {/* <MobxTable
            rowKey="id"
            store={billAuditStore}
            params='pendingParamss'
            result='pendingResults'
            method='searchPendings'
            columns={[
              {
                title: '提交时间',
                dataIndex: 'createTime',
                render: (text) => formatTime(text)
              },
              ...commonCol,
              {
                title: '操作',
                render: (text, record) => <Actions items={[
                  {
                    text: '账单明细',
                    onClick: () => history.push(`/billAudit/${record.id}/detail?state=pending`)
                  }
                ]} />
              }
            ]} /> */}
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '10px',
            paddingRight: '15px',
            paddingTop: '13px'
          }}>
            <div style={{
              fontSize: '18px',
              color: 'black',
            }}>账单列表</div>
            <Space>
              <RangePicker allowClear onChange={(v) => {
                if (v) {
                  billAuditStore.pendingParams.billStartDate = moment(v[0]._d).valueOf()
                  billAuditStore.pendingParams.billEndDate = moment(v[1]._d).valueOf()
                } else {
                  billAuditStore.pendingParams.billStartDate = null
                  billAuditStore.pendingParams.billEndDate = null
                }
              }} />
              <Input allowClear placeholder="请输入司机姓名" onChange={(e) => {
    
                billAuditStore.pendingParams.driverName = e.target.value ? e.target.value:null
              }} />
              <Input allowClear placeholder="请输入司机电话" onChange={(e) => {
                billAuditStore.pendingParams.driverPhone = e.target.value ? e.target.value:null
              }} />
              <Input allowClear placeholder="请输入司机车牌号" onChange={(e) => {
                billAuditStore.pendingParams.plateNumber = e.target.value ? e.target.value:null
              }} />
              <Button onClick={() => {
                billAuditStore.searchPending()
              }} type="primary">
                查询
              </Button>
            </Space>
          </div>
          <Space size='small' style={{ marginTop: 10, marginBottom: 10 }}>
            <div style={{ color: '#8D8D8E',marginLeft: 10 }}>已选：{selectedRow.length}笔</div>
            <div style={{ color: '#101010' }}>总金额合计：
              <span style={{ color: '#FF4A28' }}>
                {(priceObj.amount * 1).toFixed(2)} ( 总运费：{(priceObj.wayBillAmount*1).toFixed(2)}, 总服务费：{((priceObj.serviceFee*1).toFixed(2))} )
              </span>
            </div>
            <Button type="primary"
              onClick={() => {
                if (selectedRow.length == 0) {
                  message.warning('请选择最少一笔账单')
                  return
                }
                setVisible(true)
              }}
            >
              提交账单
            </Button>
            <Button onClick={manyChange} type="primary">
              批量修改
            </Button>
          </Space>
          <MobxTable
            store={billAuditStore}
            params='pendingParams'
            result='pendingResult'
            method='searchPending'
            scroll={{ x: 3000, y: 500 }}
            rowSelection={{
              preserveSelectedRowKeys: true,
              selectedRowKeys,
              onChange: (keys, selectedRows) => {
                setSelectedRowKeys(keys)
                setSelectedRows(selectedRows)
                if (keys.length > 0) {
                  axios.post('api/bill/calFee', keys).then(
                    res => {
                      if (res.data.code == 100000) {
                        setAmount(res.data.data)
                      } else {
                        message.error("计算总运费失败" + res.data.msg)
                      }
                    }
                  )
                } else setAmount(data)
              }
            }}
            columns={[
              {
                title: '账单号',
                dataIndex: 'id',
                ellipsis: true,
                fixed: 'left',
                width: 200,
                // render: (text) => formatTime(text)
              },
              {
                title: '出账日期',
                dataIndex: 'createTime',
                width: 200,
                render: (text) => formatTime(text)
              },

              {
                title: '车牌号',
                dataIndex: 'plateNumber',
                width: 200,
              },
              {
                title: '驾驶员姓名',
                dataIndex: 'driverName',
                width: 200,
              },
              {
                title: '驾驶员电话',
                dataIndex: 'driverPhone',
                width: 200,
              },

              // {
              //   title: '总金额',
              //   dataIndex: 'amount',
              //   width: 200,
              //   render: (text, record) => <span style={{ color: 'red' }}>￥ {text}</span>
              // },
              {
                title: '运费',
                dataIndex: 'price',
                width: 200,
                render: (text) => price(text)
              },
              // {
              //   title: '平台服务费',
              //   dataIndex: 'serviceFee',
              //   width: 200,
              //   render: (text) => <span style={{ color: 'red' }}>￥ {text}</span>
              // },
              {
                title: '运单号',
                dataIndex: 'waybillId',
                width: 200,
                // render: (text) => formatTime(text)
              },
              {
                title: '收款人',
                dataIndex: 'receiverBankCardHolder',
                width: 200,
                // render: (text) => formatTime(text)
              },
              {
                title: '收款人账号',
                dataIndex: 'receiverBankCardNo',
                width: 250,
                // render: (text) => formatTime(text)
              },
              {
                title: '费用类型',
                dataIndex: 'billType',
                width: 200,
                render: (text) => surcharge[text],
              },

              {
                title: '车长',
                dataIndex: 'vehicleLength',
                width: 200,
              },
              {
                title: '车辆类型',
                dataIndex: 'vehicleModel',
                width: 200,
              },
              {
                title: '付款人',
                dataIndex: 'sendName',
                width: 250,
                // render: (text) => formatTime(text)
              },
              {
                title: '付款账号',
                dataIndex: 'sendBankCardNo',
                width: 200,
                // render: (text) => formatTime(text)
              },
              {
                title: '操作',
                fixed: 'right',
                align: 'center',
                width: 100,
                render: (text, record) =>
                <>
                <Button type="link" style={{ color: '#2262fd' }} onClick={async () => {
                  try {
                    let resDet = await axios.get(`/api/bill/${record.id}`)
                    let resImg = await axios.get(`/api/bill/att/${record.id}`)
                    setDetail(resDet.data)
                    setPreVisible(true)
                  } catch (error) {
                    message.error('获取账单明细失败' + error)
                  }
                }}>账单明细</Button>
                <Button type="link" style={{ color: '#2262fd' }} onClick={ () => {setDeductionsVisible(true);dedForm.setFieldsValue({orderId:record.transportOrderId*1})}}>货损扣款</Button>
                </> 
              }
            ]} />
          <Modal
            title='提交对账'
            visible={visible}
            width='700px'
            onCancel={() => setVisible(false)}
            footer={null}>
            <Table
              pagination={false}
              showHeader={false}
              columns={cols}
              dataSource={[
                {
                  v1: '付款人',
                  v2: selectedRow[0]?.sendName,
                  v3: '联系电话',
                  v4: selectedRow[0]?.sendPhone
                },
                {
                  v1: '付款账户',
                  v2: selectedRow[0]?.sendBankCardNo,
                  v3: '开户银行',
                  v4: selectedRow[0]?.sendBankName,
                },
                {
                  v1: '笔数',
                  v2: selectedRow.length,
                  v3: '账单总额',
                  v4: <span style={{ color: 'red' }}>{priceObj.amount}</span>,
                },
              ]} />
            { }
            <Divider />
            {
              selectedRow.length <= 10 && selectedRow.map(item => {
                return (
                  <Table
                    style={{ marginBottom: '15px' }}
                    pagination={false}
                    showHeader={false}
                    columns={cols}
                    dataSource={[
                      {
                        v1: '账单编号',
                        v2: item?.id,
                        v3: '出账时间',
                        v4: formatTime(item?.createTime)
                      },
                      {
                        v1: '费用类型',
                        v2: surcharge[item?.billType],
                        v3: '费用金额',
                        v4: ((item?.price) * 1).toFixed(2),
                      },

                    ]} />
                )
              })
            }
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <Space>
                <span>事项</span>
                <Input
                  onChange={(e) => setRemark(e.target.value)}
                  value={remark} />
              </Space>
              <Space>
                <Button
                  style={{ width: '100px' }}
                  onClick={() => setVisible(false)}>取消</Button>
                <Button
                  style={{ width: '100px' }}
                  type='primary'
                  onClick={() => {
                    axios.post(`/api/bill/service/submit?remark=${remark}`, selectedRow.map(item => item.id))
                      .then(res => {
                        reset()
                        setRemark('')
                        setVisible(false)
                        billAuditStore.searchPending()
                        message.success('提交对账成功')

                      })
                  }} >确认提交</Button>
              </Space>
            </div>
          </Modal>
          <Modal
            title='账单详情'
            visible={previsible}
            width='900px'
            onCancel={() => setPreVisible(false)}
            footer={null}>
            <Title text='账单信息' />
            <Table
              style={{ marginBottom: '15px' }}
              pagination={false}
              showHeader={false}
              columns={cols}
              dataSource={[
                {
                  v1: <TdWrap>账单编号</TdWrap>,
                  v2: detail.bill?.id,
                  v3: <TdWrap>出账时间</TdWrap>,
                  v4: formatTime(detail.bill?.createTime, TimeFormats.YMDHMS)
                },
                {
                  v1: <TdWrap>付款人</TdWrap>,
                  v2: detail.shipper?.companyName,
                  v3: <TdWrap>联系电话</TdWrap>,
                  v4: detail.shipper?.contractPhone,
                },
                {
                  v1: <TdWrap>付款账户</TdWrap>,
                  v2: detail.shipper?.bankCardNo,
                  v3: <TdWrap>开户银行</TdWrap>,
                  v4: detail.shipper?.bankName,
                },
                {
                  v1: <TdWrap>费用类型</TdWrap>,
                  v2: surcharge[detail.bill?.type],
                  v3: <TdWrap>运费金额</TdWrap>,
                  v4: detail.bill?.transferType === 'receive' ? detail.waybill?.price.toFixed(2) : detail.order?.price.toFixed(2)
                },
                {
                  v1: <TdWrap>平台服务费</TdWrap>,
                  v2: detail.waybill?.serviceFee.toFixed(2),
                  v3: <TdWrap>货损扣款</TdWrap>,
                  v4: detail.order?.cargoDamagePrice,
                },
                {
                  v1: <TdWrap>扣款原因</TdWrap>,
                  v2: noData(detail.order?.cargoDamageReason),
                  v3: <TdWrap>费用总计</TdWrap>,
                  v4: <span style={{ color: 'red' }}>{_.subtract(detail.bill?.transferType === 'receive' ? (detail.waybill?.price + detail.waybill?.serviceFee).toFixed(2) : detail.order?.price.toFixed(2), detail.order?.cargoDamagePrice).toFixed(2)}</span>,
                },
                {
                  v1: <TdWrap>账单状态</TdWrap>,
                  v2: <div style={{
                    border: '1px solid #1692E4',
                    width: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <span style={{ color: '#1692E4' }}>{billState[detail.billState]}</span>
                  </div>
                },
              ]}
            />
            <Title text='运单信息' />
            <Table
              style={{ marginBottom: '15px' }}
              pagination={false}
              showHeader={false}
              columns={cols}
              dataSource={[
                {
                  v1: <TdWrap>运单号</TdWrap>,
                  v2: detail.waybill?.id,
                  v3: <TdWrap>发货人</TdWrap>,
                  v4: detail.shipper?.companyName
                },
                {
                  v1: <TdWrap>装货地址</TdWrap>,
                  v2: detail.waybill?.fromAddress,
                  v3: <TdWrap>卸货地址</TdWrap>,
                  v4: detail.waybill?.toAddress
                },
                {
                  v1: <TdWrap>承运方</TdWrap>,
                  v2: detail.bill?.receiverType == 'driver' ? detail.driver?.realName : detail.carrier?.companyName,
                  v3: <TdWrap>电话号码</TdWrap>,
                  v4: detail.bill?.receiverType == 'driver' ? detail.driver?.accountName : detail.carrier?.contractPhone,
                },
                {
                  v1: <TdWrap>车长（米）</TdWrap>,
                  v2: detail.vehicle?.vehicleLength,
                  v3: <TdWrap>车型</TdWrap>,
             
                  // v4: vehicleCode.find(item => item.code == detail.vehicle?.vehicleModel)?.name,
                },
                {
                  v1: <TdWrap>货物信息</TdWrap>,
                  v2: detail.shipments?.map((item, index) => {
                    return <div>{`${item.cargoName};${item.weight}kg;${item.packingManner}`}</div>
                  }),
                  v3:<TdWrap>装货时间</TdWrap>,
                  v4:formatTime(detail.order?.pickupTime, TimeFormats.YMDHMS)
                },
                {
                  v1:<TdWrap>卸货时间</TdWrap>,
                  v2:formatTime(detail.order?.deliveryTime, TimeFormats.YMDHMS)
                }
              ]}
            />

          </Modal>
          <Modal   
            title='添加货损扣款'
            visible={deductionsVisible}
            width='900px'
            onCancel={() => setDeductionsVisible(false)}
            onOk={() => {
              dedForm.validateFields().then(
                res => {
                  let reg = /^\d*\.\d*$/
                  res.orderId = dedForm.getFieldValue('orderId')
                  if(!reg.test(res.price)) res.price = res.price.toFixed(2) 
                  else res.price = res.price + ''
                  axios.post('/api/logistics/damage',res).then(
                    res => {
                      if (res.data.code == 100000) {
                        message.success('修改货损扣款成功')
                        billAuditStore.searchPending()
                        dedForm.resetFields()
                        setDeductionsVisible(false)
                      } else {
                        message.error('修改货损扣款失败：'+res.data.msg)
                      }
                    },
                  )
                }
              )
            }}>
            <Form form={dedForm} >
              <Form.Item
                label="货损扣款"
                name="price"
                rules={[{ required: true, message: "请输入货损扣款" }]}
                >
                <InputNumber style={{width:'100%'}} step={0.01} onChange={(value) => {
                  // let reg = /^\d*\.\d*$/
                  // if(!reg.test(value)) value = value.toFixed(2)
                  // dedForm.setFieldsValue({price:value})
                }}/>
              </Form.Item>
              <Form.Item
                label="货损原因"
                name="reason"
                rules={[{ required: true, message: "请输入货损原因" }]}
                >
                <Input style={{width:'100%'}}/>
              </Form.Item>
            </Form>
          </Modal>
          <ManyChange manyVisible={manyVisible} change={setMany} billIds={selectedRowKeys} store={billAuditStore} />
        </TabPane>

        <TabPane key='unpaid' tab={<TabCard text='待付款' />}>
          <CommonSearch title='账单列表'>
            <SearchForm type='unpaid' search={search} store={billAuditStore} />
          </CommonSearch>
          <Space>
            <div style={{ color: '#8D8D8E', marginLeft: 10 }}>总账单数：{billAuditStore.unpaidResult.count}笔</div>
            <span>总金额合计:</span>
            <span style={{ color: '#FF4A28' }}>
              {(_.sumBy(billAuditStore.unpaidResult.results, 'billAmount')*1).toFixed(2)} ( 总运费合计：{(_.sumBy(billAuditStore.unpaidResult.results, 'billPrice')*1).toFixed(2)}, 总平台服务费合计：{(_.sumBy(billAuditStore.unpaidResult.results, 'actServiceFee')*1).toFixed(2)} )
            </span>
          </Space>
          <MobxTable
            rowKey="id"
            store={billAuditStore}
            params='unpaidParams'
            result='unpaidResult'
            method='searchUnpaid'
            scroll={{y: 500 }}
            columns={[
              {
                title: '对账时间',
                dataIndex: 'approvedTime',
                fixed: 'left',
                width: 100,
                render: (text) => formatTime(text)
              },
              ...commonCol,
              {
                title:'操作',
                render:(text,record) => <Actions items = {[
                    {
                        text:'线上支付',
                        onClick:()=> {
                          setIsShow(true)
                          setOrderInfo(record)
                          setMmount(record.billAmount)
                          getPersonInfo()
                          console.log('record',record)
                        }
                    },
                    {
                        text:'线下支付',
                        onClick: () => history.push(`/billAudit/${record.id}/detail?state=unpaid`)
                    },
                    // {
                    //     text:'账单明细',
                    //     onClick:()=>history.push(`/billAudit/${record.id}/detail?state=unpaid`)
                    // }
                ]}/>
            }
            ]} />
        </TabPane>
        <TabPane key='paid' tab={<TabCard text='已付款' />}>
          <CommonSearch title='账单列表'>
            <SearchForm type='paid' search={search} store={billAuditStore}
            />
          </CommonSearch>
          <Space>
            <div style={{ color: '#8D8D8E', marginLeft: 10 }}>总账单数：{billAuditStore.paidResult.count}笔</div>
            <span>总金额合计:</span>
            <span style={{ color: '#FF4A28' }}>
              {(_.sumBy(billAuditStore.paidResult.results, 'billAmount')*1).toFixed(2)} ( 总运费合计：{(_.sumBy(billAuditStore.paidResult.results, 'billPrice')*1).toFixed(2)}, 总平台服务费合计：{(_.sumBy(billAuditStore.paidResult.results, 'actServiceFee')*1).toFixed(2)} )
            </span>
          </Space>
          <MobxTable
            rowKey="id"
            store={billAuditStore}
            params='paidParams'
            result='paidResult'
            method='searchPaid'
            scroll={{y: 500 }}
            columns={[
              {
                title: '付款时间',
                dataIndex: 'payTime',
                width: 100,
                render: (text) => formatTime(text)
              },
              ...commonCol,
              {
                title: '操作',
                width: 80,
                render: (text, record) => <Actions items={[
                  {
                    text: '账单明细',
                    onClick: () => history.push(`/billAudit/${record.id}/detail?state=paid`)
                  }
                ]} />
              }
            ]} />
        </TabPane>
      </Tabs>
      <Modal
                visible={isShow}
                width='400px'
                onOk={subOk}
                onCancel={() => {
                    setIsShow(false)
                    form.resetFields()
                }}
                title="在线支付">
                   <Form colon={false}  form={form}>
                        <Form.Item
                            label='账单编号：'
                            name="amount"
                       >
                        <span  style={{marginLeft:'10px',fontWeight:'600'}}>{orderInfo.id}</span>
                        </Form.Item>
                        <Form.Item
                            label='支付金额：'
                            name="amount"
                         >
                          <span style={{color:'red',marginLeft:'10px',fontWeight:'600'}}>￥{orderInfo.billAmount}</span>
                        </Form.Item>
                        <Form.Item
                         label='支付密码：'
                        name="password"
                        rules={[{
                            required:true,message:'请输入支付密码!'
                        }]}>
                            <Input type="password" style={{width:'200px'}} placeholder="请输入支付密码"></Input>
                        </Form.Item>
                        {/* <Form.Item
                         label='支付类型：'
                        name="payType"
                        >
                        <Radio.Group
                            defaultValue="a"
                            buttonStyle="dash"
                            size="mini"
                            >
                            <Radio.Button value="a">
                                余额
                            </Radio.Button>
                        </Radio.Group>
                        </Form.Item> */}
                </Form>      
            </Modal>
    </Layout>
  )
})


function SearchForm(props) {
  const [form] = Form.useForm();

  const getFormItems = () => {
    let items = []
    if (props.type == 'pending') {
      items.push(
        <Form.Item
          label='提交时间'
          name="submitDate"
          getValueFromEvent={(e) => {
            if (e) {
              form.setFieldsValue({ submitStartDate: e[0].valueOf() })
              form.setFieldsValue({ submitEndDate: e[1].valueOf() })
            } else {
              form.setFieldsValue({ submitStartDate: null })
              form.setFieldsValue({ submitEndDate: null })
            }
          }}>
          <RangePicker allowClear />
        </Form.Item>
      )
    }
    if (props.type == 'unpaid') {
      items.push(
        <Form.Item
          label='对账时间'
          name="confirmDate"
          getValueFromEvent={(e) => {
            if (e) {
              form.setFieldsValue({ confirmStartDate: e[0].valueOf() })
              form.setFieldsValue({ confirmEndDate: e[1].valueOf() })
            } else {
              form.setFieldsValue({ confirmStartDate: null })
              form.setFieldsValue({ confirmEndDate: null })
            }
          }}>
          <RangePicker allowClear />
        </Form.Item>
      )
      // items.push(
      //   <Form.Item
      //     label='发票'
      //     name="invoice"
      //     style={{ width: '200px' }}>
      //     <Select allowClear>
      //       <Select.Option value={false}>
      //         未开发票
      //       </Select.Option>
      //       <Select.Option value={true}>
      //         已开发票
      //       </Select.Option>
      //     </Select>
      //   </Form.Item>
      // )
    }
    if (props.type == 'paid') {
      items.push(
        <Form.Item
          label='支付时间'
          name="paidDate"
          getValueFromEvent={(e) => {
            if (e) {
              form.setFieldsValue({ paidStartDate: e[0].valueOf() })
              form.setFieldsValue({ paidEndDate: e[1].valueOf() })
            } else {
              form.setFieldsValue({ paidStartDate: null })
              form.setFieldsValue({ paidEndDate: null })
            }
          }}>
          <RangePicker allowClear />
        </Form.Item>
      )
    }
    items.push(
      <Form.Item
        name="searchField">
        <Input suffix={<SearchOutlined />} style={{ width: '200px' }} allowClear></Input>
      </Form.Item>
    )
    items.push(
      <Form.Item>
        <Button type="primary" onClick={() => props.search(form, props.type, props.store)}>查询</Button>
      </Form.Item>
    )
    return items
  }

  return (
    <Form colon={false} hideRequiredMark={true}
      form={form}
      style={{ display: 'flex', flexDirection: 'row' }}
      {...props}>
      <Space>
        {getFormItems()}
      </Space>
    </Form>
  )
}