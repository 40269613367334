import React, { useState, useEffect } from "react";
import {Row,Col,Timeline} from "antd"
import CommonMap from "components/Map"
import axios from "axios"
import {formatTime,TimeFormats,vehicleModels} from "common/Constant"
import { Marker} from 'react-leaflet'
import startImg from "assert/images/start.png"
import endImg from "assert/images/end.png"
import L from 'leaflet'

export default function(props){

    const [records,setRecords] = React.useState([])
    const [routes,setRoutes] = React.useState()
    const [center,setCenter] = React.useState([])

    React.useEffect(() => {
        axios.get(`/api/logistics/record/${props.waybillId}`)
        .then(res => {
            setRecords(res.data)
        })
        if(props.orderId){
            axios.get(`/api/tracking/${props.orderId}`)
            .then(res => {
                setRoutes(res.data)
            })
            axios.get(`/api/driver/order-${props.orderId}/location`)
            .then(res => {
                setCenter([res.data.lat,res.data.lng])
            })
        }
        
    },[props.waybillId])

    return (
        <Row>
            <Col span={12} style={{border:'1px solid #1989FA'}}>
                <CommonMap
                center={center}
                routes={routes}
                style={{height:'500px',}}>
                    {
                        routes&&<Marker icon={
                            new L.Icon({
                                iconUrl:startImg,
                                iconSize: [25, 25],
                            })
                        } 
                        position={routes[0]}
                        />
                    }
                    {
                        routes&&<Marker icon={
                            new L.Icon({
                                iconUrl:endImg,
                                iconSize: [25, 25],
                            })
                        } 
                        position={routes[routes.length - 1]}/>
                    }
                </CommonMap>
            </Col>
            <Col span={12}>
                <Timeline mode='left'>
                    {
                        records.map((item,index) => {
                            return <Timeline.Item 
                                    color={index==0?'green':'gray'}
                                    label={formatTime(item.createTime,TimeFormats.YMDHMS)}>
                                        {item.content}
                                    </Timeline.Item>
                        })
                    }
                    
                </Timeline>
            </Col>
        </Row>
    )
}