import React from 'react'
import { Row, Col, Space, Layout, Table, Form, Modal, Button, Input, DatePicker, message, Image, Upload } from "antd"
import { PlusOutlined } from '@ant-design/icons';
import CBreadcrumb from "components/CBreadcrumb"
import CImage from 'components/CImage'
import { getValueFromEvent } from 'components/CImage'
import axios from 'axios';
export default function Offine(props) {
  console.log(props);
  const { match: { params: { id } } } = props
  const [userForm] = Form.useForm()
  const [payForm] = Form.useForm()
  const [collForm] = Form.useForm()
  const [payList, setPayList] = React.useState([])
  let [content,setContent]= React.useState({})
  let [url,setUrl]= React.useState('')
  let [title,setTitle]= React.useState('')
  let [previewVisible,setPreviewVisible]= React.useState(false)
  React.useEffect(()=>{
    axios.get(`/api/billRepair/payBefore/${id}`).then(
      res =>{
        if(res.data.code == 100000){
          setContent(res.data.data)
          userForm.setFieldsValue({
            Name:res.data.data.companyName,
            account:res.data.data.bankCardNo,
            bank:res.data.data.bankName
          })
          payForm.setFieldsValue({
            phone:res.data.data.receiverCardNo,
            id:res.data.data.receiverPhone
          })
        } else {
          message.error(res.data.msg)
          setTimeout(() =>{
            props.history.push('/billAudit?tabPaneKey=unpaid')
          },1000)
        }
      },
      err => {
        message.error('查询详情失败'+err.message)
      }
    )
  },[])
  function save() {
    userForm.validateFields().then(
      res => {
        message.success('保存成功')
      },
      err => {
        message.error('保存失败')
      }
    )
  }
  function back() {
    props.history.push(`/billAudit/${id}/detail?state=unpaid`)
  }
  function fileRemove(file) {
    let list = payList.filter(item => item.uid != file.uid)
    setPayList(list)
  }
   // 预览图片取消
   function handleCancel() {
    setPreviewVisible(false)
  }
  // 预览图片
  function handlePreview(file) {
    setUrl(file.url)
    setTitle(file.name)
    setPreviewVisible(true)
  }
  // 文件上传
  const fileChange = async ({ file }, type) => {
    file.status = 'done'
    file.url = await getBase64(file);
    console.log(file);
    let fd = new FormData()
    fd.append('file', file)
    fd.append('id', id)
    axios.post('/api/billRepair/uploadCertPay', fd).then(
      res => {
        if (res.data.code === 100000) {
          
          setPayList(setPayList => [file])
              // setIdFileList(idFileList => [...idFileList, file])
              message.success('上传成功')
            
        }
        else {
          message.success('上传失败'+res.data.msg)
        }
      },
      err => {
        console.log(err);
        message.error('上传失败' + err.message)
      }
    )
  }
   // 图片存在本地
   function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  async function sub () {
    await Promise.all([userForm.validateFields(),payForm.validateFields()])
    let data = {
      billAuditId : id,
      companyName : userForm.getFieldValue('Name'),
      bankName : userForm.getFieldValue('bank'),
      bankCardNo :userForm.getFieldValue('account'),
      paySerNum : '1111'
    }
    axios.post('/api/billRepair/offLinePay',data).then(
      res => {
        if(res.data.code == 100000) {
          message.success('线下支付成功')
          props.history.push('/billAudit?tabPaneKey=unpaid')
        } else {
          message.success('线下支付失败'+res.data.msg)
        }
      },
      err => {
        message.success('线下支付失败'+err.message)
      }
    )
  }
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }
  return (
    <Layout>
      <CBreadcrumb items={[
        {
          text: '待付款账单',
          href: '/billAudit?tabPaneKey=unpaid'
        },
        {
          text: '线下支付',
          href: 'offine'
        }
      ]} />
      <Row style={{ fontSize: 18, marginLeft: 10 }}>付款明细</Row>
      <Row style={{ fontSize: '12px', color: 'black', fontFamily: '微软雅黑' }}>
        <Col span={10} style={{ textAlign: 'right' }}>账单号：{content.billAuditId}</Col>
        <Col span={5} style={{ textAlign: 'center' }}>运单梳理：1笔</Col>
        <Col span={8} style={{ textAlign: 'right' }}>待支付金额(费率：{content.rate}%):
          <span style={{ color: 'red' }}>￥{(content.price*1).toFixed(2)} ( 实际运费：{(content.waybillAmount*1).toFixed(2)}, 服务费：{(content.serviceFee*1).toFixed(2)} )</span>
        </Col>
      </Row>

      <Row style={{ fontSize: 18, marginLeft: 10 }}>平台付款信息</Row>
      <Row style={{ fontSize: '12px', color: 'black', fontFamily: '微软雅黑', textAlign: 'center' }}>
        <Col span={24} style={{ marginTop: 30, marginBottom: 12, fontSize: 16 }}>对公账户信息</Col>
      </Row>
      <Form labelCol={{ span: 11 }}>
        <Form.Item label="户名" style={{ marginBottom: 12 }}>
          <span>贵州双龙火运大数据有限公司</span>
        </Form.Item>
        <Form.Item label="账号" style={{ marginBottom: 12 }}>
          <span>5210 0012 3013 0002 97818</span>
        </Form.Item>
        <Form.Item label="开户行" style={{ marginBottom: 12 }}>
          <span>贵州交通银行贵阳经济技术开发区支行</span>
        </Form.Item>
      </Form>

      <Row style={{ fontSize: 18, marginLeft: 10, marginBottom: 30 }}>货主付款信息</Row>
      <Row style={{ fontSize: 15, marginLeft: 20, marginBottom: 30 }}>账户信息</Row>
      <Form labelCol={{ span: 8 }} form={userForm} wrapperCol={{span:12}}>
        <Row>
          <Col span={7}>
            <Form.Item label="户名" name="Name" rules={[{ required: true, message: '请填写用户名' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="账号" name="account" rules={[{ required: true, message: '请填写账号' }]}>
              <Input  />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="银行" name="bank" rules={[{ required: true, message: '请填写银行' }]}>
              <Input  />
            </Form.Item>
          </Col>
          <Col span={3} style={{ textAlign: 'right', paddingRight: 15 }}>
            <Form.Item >
              <Button type='primary' onClick={save}>保存</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ fontSize: 15, marginLeft: 20, marginBottom: 30 }}>收款人信息</Row>
      <Form labelCol={{ span: 8 }} form={collForm}>
        <Row>
          <Col span={7}>
            <Form.Item label="手机号" name="phone">
              {content.receiverPhone}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="身份证号" name="id" >
            {content.receiverCardNo}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ fontSize: 15, marginLeft: 20, marginBottom: 30 }}>付款凭证</Row>
      <Form labelCol={{ span: 8 }} form={payForm} onValuesChange={(c,a)=> {
        console.log(c,a);
      }}>
        <Row>
          {/* <Col span={7}>
            <Form.Item label="流水号" name="serialNumber" rules={[{ required: true, message: '请填写流水号' }]}>
              <Input style={{ width: 250 }} />
            </Form.Item>
          </Col> */}
          <Col span={7} >
            <Form.Item label="付款凭证" name="coll" getValueFromEvent={normFile} rules={[{ type: 'array', required: true, message: '请上传付款证明' }]} >
              <Upload
                action='#'
                listType="picture-card"
                fileList={payList}
                // maxCount="1"
                onRemove={fileRemove}
                onPreview={handlePreview}
                customRequest={fileChange}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row >
        <Col span={24} style={{ textAlign: 'center' }}>
          <Space >
            <Button type='primary' onClick={sub} >确定</Button>
            <Button onClick={back} >返回</Button>
          </Space>
        </Col>
      </Row>
      <Modal
        key='1'
        visible={previewVisible}
        title={title}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={url} />
      </Modal>
    </Layout>
  )
}
