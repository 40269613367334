import React from 'react'
import WaybillStore from "stores/WaybillStore"
import BillAuditStore from "stores/BillAuditStore"
import AddressStore from "stores/AddressStore"
import ShipmentStore from "stores/ShipmentStore"
import QuestionStore from "stores/QuestionStore"
import BillStore from "stores/BillStore"
import ProfileStore from "stores/ProfileStore"
import NewsStore from "stores/NewsStore"
import CatalogueStore from "stores/CatalogueStore"
import InvoiceStore from "stores/InvoiceStore"
import { RiskStore } from './RiskStore'
import CarStore from './CarStore'
import WaterStore from './WaterStore'

const storesContext = React.createContext({
  waybillStore:new WaybillStore(),
  billAuditStore:new BillAuditStore(),
  addressStore:new AddressStore(),
  shipmentStore:new ShipmentStore(),
  questionStore:new QuestionStore(),
  billStore:new BillStore(),
  profileStore:new ProfileStore(),
  newsStore:new NewsStore(),
  catalogueStore: new CatalogueStore(),
  invoiceStore:new InvoiceStore(),
  riskStore:new RiskStore(),
  carStore:new CarStore(),
  WaterStore:new WaterStore()
})

export default function useStores() {
  return React.useContext(storesContext)
}