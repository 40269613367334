import React, { useState, useEffect } from "react";
import {Form,Input,Cascader,Layout,Tabs,Row,Rate,Modal,message,DatePicker,Space,Select,Button,Divider} from "antd"
import CommonSearch from "components/Search"
import {SearchOutlined} from "@ant-design/icons";
import {Actions} from "components/common"
import "../index.less"
import {vehicleModels,vehicleLengths,formatTime,noData} from "common/Constant"
import { observer } from 'mobx-react'
import useStores from "stores/StoreContext"
import MobxTable,{mergeParams} from "components/List/MobxTable"
import AREA_CODE from "common/AreaCode"
import _ from "lodash"
import axios from "axios";
import {TabCard} from "components/common"
import {useParams, useHistory, useLocation } from "react-router-dom";
import LogisticsTrack from "components/Logistics/Track"
import EvaluateModal from "components/Logistics/Evaluate"
import qs from "qs"

const { RangePicker } = DatePicker;
const {TabPane} = Tabs


export const commonCol = [
    {
        title: "运单编号",
        dataIndex: "waybillId",
        ellipsis:true
    },
    {
        title: "发布时间",
        dataIndex: "createTime",
        render:(text) => formatTime(text),
        ellipsis:true
    },
    {
        title: "始发地",
        dataIndex: "fromAddress",
        ellipsis:true
    },
    {
        title: "目的地",
        dataIndex: "toAddress",
        ellipsis:true
    },
    {
        title: "货物",
        dataIndex: "cargos",
        ellipsis:true,
    },
]

const searchProps = [
    'vehicleLength',
    'vehicleModel',
    'searchField',
    'publishStartDate',
    'publishEndDate',
    'pickupStartDate',
    'pickupEndDate',
    'deliveryStartDate',
    'deliveryEndDate',
    'closeStartDate',
    'closeEndDate',
    'signStartDate',
    'signEndDate',
    'fromAreaCode',
    'toAreaCode']

export default observer(function(){
    let location  = useLocation()
    let history = useHistory();
    const activeKey = qs.parse(location.search.slice(1)).tabPaneKey
    const {waybillStore} = useStores()
    const [tabPaneKey,setTabPaneKey] = React.useState(activeKey=='unConfirm'?'publishing':activeKey)
    const [trackVisible,setTrackVisible] = React.useState(false)
    const [evaluateVisible,setEvaluateVisible] = React.useState(false)
    const [item,setItem] = React.useState({})
    const [evaluateReadOnly,setEvaluateReadOnly] = React.useState(false)
    

    React.useEffect(() => {
        return () => {
            waybillStore.reset()
        }
    },[])

    return <Layout className='full waybill' style={{paddingTop:'0px'}}>
                <Tabs activeKey={tabPaneKey}
                onChange={(key) => {
                    history.push(`/waybill?tabPaneKey=${key}`)
                    waybillStore.search(key)
                    setTabPaneKey(key)
                }}>
                    <TabPane key='publishing' tab={<TabCard text='发布中'/>}>
                        <CommonSearch title='运单列表'>
                            <SearchForm type='publishing'
                            onValuesChange={(changedValues,allValues) => {
                                waybillStore.publisingParams.logisticsState = 'publishing'
                                waybillStore.publisingParams = mergeParams(waybillStore.publisingParams,allValues,searchProps,['logisticsState'])
                                waybillStore.searchPublising()
                            }}/>
                        </CommonSearch>
                        <MobxTable
                        rowKey="id"
                        store={waybillStore}
                        params='publisingParams'
                        result='publisingResult'
                        method='searchPublising'
                        columns={[
                            ...commonCol,
                            {
                                title:'车长（米）',
                                dataIndex:'vehicleLength'
                            },
                            {
                                title:'车型',
                                dataIndex:'vehicleModel',
                                render:(text) => vehicleModels[text]
                            },
                            {
                                title:'订单金额',
                                dataIndex:'waybillPrice',
                                width:'150px',
                                render:(text) => <span style={{color:'red'}}>￥ {text.toFixed(1)}</span>
                            },
                            {
                                title:'操作',
                                render:(text,record) => <Actions items = {[
                                    {
                                        text:'运单详情',
                                        onClick:() => history.push(`/waybill/${record.waybillId}`)
                                    }
                                ]}/>
                            }
                        ]}/>
                    </TabPane>
                    <TabPane key='unSend' tab={<TabCard text='待发车'/>}>
                        <CommonSearch title='运单列表'>
                            <SearchForm type='unSend'
                            onValuesChange={(changedValues,allValues) => {
                                waybillStore.unSendParams.logisticsState = 'unSend'
                                waybillStore.unSendParams = mergeParams(waybillStore.unSendParams,allValues,searchProps,['logisticsState'])
                                waybillStore.searchUnSend()
                            }}/>
                        </CommonSearch>
                        <MobxTable
                        rowKey="id"
                        store={waybillStore}
                        params='unSendParams'
                        result='unSendResult'
                        method='searchUnSend'
                        columns={[
                            ...commonCol,
                            {
                                title:'承运司机',
                                dataIndex:'driverName',
                                ellipsis:true
                            },
                            {
                                title:'车牌号',
                                dataIndex:'plateNumber',
                                ellipsis:true
                            },
                            {
                                title:'订单金额',
                                dataIndex:'waybillPrice',
                                width:'150px',
                                render:(text) => <span style={{color:'red'}}>￥ {text.toFixed(1)}</span>
                            },
                            {
                                title:'操作',
                                width:'180px',
                                render:(text,record) => <Actions items = {[
                                    {
                                        text:'运单详情',
                                        onClick:() => history.push(`/waybill/${record.waybillId}`)
                                    },
                                    {
                                        text:'确认发车',
                                        onClick:() => {
                                            Modal.confirm({
                                                title:'确认发车',
                                                onOk:() => {
                                                    axios.post(`/api/logistics/pickup/${record.transportOrderId}`)
                                                    .then(res => {
                                                        message.success('发车成功')
                                                        waybillStore.searchUnSend()
                                                    })
                                                }
                                            })
                                        }
                                    }
                                ]}/>
                            }
                        ]}/>
                    </TabPane>
                    <TabPane key='onTheWay' tab={<TabCard text='在途中'/>}>
                        <CommonSearch title='运单列表'>
                            <SearchForm type='onTheWay'
                            onValuesChange={(changedValues,allValues) => {
                                waybillStore.onTheWayParams.logisticsState = 'onTheWay'
                                waybillStore.onTheWayParams = mergeParams(waybillStore.onTheWayParams,allValues,searchProps,['logisticsState'])
                                waybillStore.searchOnTheWay()
                            }}/>
                        </CommonSearch>
                        <MobxTable
                            rowKey="id"
                            store={waybillStore}
                            params='onTheWayParams'
                            result='onTheWayResult'
                            method='searchOnTheWay'
                            columns={[
                                ...commonCol,
                                {
                                    title:'承运司机',
                                    dataIndex:'driverName',
                                    ellipsis:true
                                },
                                {
                                    title:'车牌号',
                                    dataIndex:'plateNumber',
                                    ellipsis:true
                                },
                                {
                                    title:'订单金额',
                                    dataIndex:'waybillPrice',
                                    width:'150px',
                                    render:(text) => <span style={{color:'red'}}>￥ {text.toFixed(1)}</span>
                                },
                                {
                                    title:'发车时间',
                                    dataIndex:'pickupTime',
                                    ellipsis:true,
                                    render:(text) => formatTime(text)
                                },
                                {
                                    title:'操作',
                                    width:'180px',
                                    render:(text,record) => <Actions items = {[
                                        {
                                            text:'运单详情',
                                            onClick:() => history.push(`/waybill/${record.waybillId}`)
                                        },
                                        {
                                            text:'运输轨迹',
                                            onClick:() => {
                                                setTrackVisible(true)
                                                setItem(record)
                                            }
                                        }
                                    ]}/>
                                }
                            ]}/>
                    </TabPane>
                    <TabPane key='unSign' tab={<TabCard text='待签收'/>}>
                        <CommonSearch title='运单列表'>
                            <SearchForm type='unSign'
                            onValuesChange={(changedValues,allValues) => {
                                waybillStore.unSignParams.logisticsState = 'unSign'
                                waybillStore.unSignParams = mergeParams(waybillStore.unSignParams,allValues,searchProps,['logisticsState'])
                                waybillStore.searchUnSign()
                            }}/>
                        </CommonSearch>
                        <MobxTable
                            rowKey="id"
                            store={waybillStore}
                            params='unSignParams'
                            result='unSignResult'
                            method='searchUnSign'
                            columns={[
                                ...commonCol,
                                {
                                    title:'承运司机',
                                    dataIndex:'driverName',
                                    ellipsis:true
                                },
                                {
                                    title:'车牌号',
                                    dataIndex:'plateNumber',
                                    ellipsis:true
                                },
                                {
                                    title:'订单金额',
                                    dataIndex:'waybillPrice',
                                    width:'150px',
                                    render:(text) => <span style={{color:'red'}}>￥ {text.toFixed(1)}</span>
                                },
                                {
                                    title:'到达时间',
                                    dataIndex:'deliveryTime',
                                    ellipsis:true,
                                    render:(text) => formatTime(text)
                                },
                                {
                                    title:'操作',
                                    width:'240px',
                                    render:(text,record) => <Actions items = {[
                                        {
                                            text:'运单详情',
                                            onClick:() => history.push(`/waybill/${record.waybillId}`)
                                        },
                                        {
                                            text:'确认签收',
                                            onClick:() => {
                                                Modal.confirm({
                                                    title:'确认签收',
                                                    onOk:() => {
                                                        axios.post(`/api/logistics/sign/${record.transportOrderId}`)
                                                        .then(res => {
                                                            message.success('签收成功')
                                                            waybillStore.searchUnSign()
                                                        })
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            text:'运输轨迹',
                                            onClick:() => {
                                                setTrackVisible(true)
                                                setItem(record)
                                            }
                                        }
                                    ]}/>
                                }
                            ]}/>
                    </TabPane>
                    <TabPane key='completed' tab={<TabCard text='已完成'/>}>
                        <CommonSearch title='运单列表'>
                            <SearchForm type='completed'
                            onValuesChange={(changedValues,allValues) => {
                                waybillStore.completedParams.logisticsState = 'completed'
                                waybillStore.completedParams = mergeParams(waybillStore.completedParams,allValues,searchProps,['logisticsState'])
                                waybillStore.searchCompleted()
                            }}/>
                        </CommonSearch>
                        <MobxTable
                            rowKey="id"
                            store={waybillStore}
                            params='completedParams'
                            result='completedResult'
                            method='searchCompleted'
                            columns={[
                                ...commonCol,
                                {
                                    title:'承运司机',
                                    dataIndex:'driverName',
                                    ellipsis:true
                                },
                                {
                                    title:'车牌号',
                                    dataIndex:'plateNumber',
                                    ellipsis:true
                                },
                                {
                                    title:'订单金额',
                                    dataIndex:'waybillPrice',
                                    width:'150px',
                                    render:(text) => <span style={{color:'red'}}>￥ {text.toFixed(1)}</span>
                                },
                                {
                                    title:'签收时间',
                                    dataIndex:'signTime',
                                    ellipsis:true,
                                    render:(text) => formatTime(text)
                                },
                                {
                                    title:'操作',
                                    width:'240px',
                                    render:(text,record) => <Actions items = {[
                                        {
                                            text:'运单详情',
                                            onClick:() => history.push(`/waybill/${record.waybillId}`)
                                        },
                                        {
                                            text:'运输轨迹',
                                            onClick:() => {
                                                setTrackVisible(true)
                                                setItem(record)
                                            }
                                        },
                                        () => {
                                            if(record.efficiencyScore==undefined||record.efficiencyScore==null){
                                                return <a style={{
                                                            color:'orange',
                                                            marginLeft:'10px',
                                                            textDecoration:'underline'
                                                        }}
                                                        onClick={() => {
                                                            setItem(record)
                                                            setEvaluateReadOnly(false)
                                                            setEvaluateVisible(true)
                                                        }}>评价</a>
                                            }
                                            return <a style={{
                                                        color:'gray',
                                                        marginLeft:'10px'
                                                    }}
                                                    onClick={() => {
                                                        setItem(record)
                                                        setEvaluateReadOnly(true)
                                                        setEvaluateVisible(true)
                                                    }}>查看评价</a>
                                        }
                                    ]}/>
                                }
                            ]}/>
                    </TabPane>
                    <TabPane key='closed' tab={<TabCard text='已关闭'/>}>
                        <CommonSearch title='运单列表'>
                            <SearchForm type='closed'
                            onValuesChange={(changedValues,allValues) => {
                                waybillStore.closedParams.logisticsState = 'closed'
                                waybillStore.closedParams = mergeParams(waybillStore.closedParams,allValues,searchProps,['logisticsState'])
                                waybillStore.searchClosed()
                            }}/>
                        </CommonSearch>
                        <MobxTable
                            rowKey="id"
                            store={waybillStore}
                            params='closedParams'
                            result='closedResult'
                            method='searchClosed'
                            columns={[
                                ...commonCol,
                                {
                                    title:'订单金额',
                                    dataIndex:'waybillPrice',
                                    ellipsis:true,
                                    render:(text) => <span style={{color:'red'}}>￥ {text.toFixed(1)}</span>
                                },
                                {
                                    title:'关闭时间',
                                    dataIndex:'closeTime',
                                    ellipsis:true,
                                    render:(text) => formatTime(text)
                                },
                                {
                                    title:'操作',
                                    render:(text,record) => <Actions items = {[
                                        {
                                            text:'运单详情',
                                            onClick:() => history.push(`/waybill/${record.waybillId}`)
                                        }
                                    ]}/>
                                }
                            ]}/>
                    </TabPane>
                </Tabs>
                <Modal title='运单跟踪'
                width='800px'
                footer={null}
                onCancel={()=>setTrackVisible(false)}
                visible={trackVisible}>
                    <LogisticsTrack 
                    waybillId={item.waybillId}
                    orderId={item.transportOrderId}/>
                </Modal>
                <EvaluateModal 
                item={item} 
                readOnly={evaluateReadOnly}
                visible={evaluateVisible}
                onCancel={() => setEvaluateVisible(false)}/>
            </Layout>
})


function SearchForm(props){
    const [form] = Form.useForm();
    const getFormItems = () => {
        let items = []
        if(props.type=='publishing'){
            items.push(
                <Form.Item
                label='发布日期'
                name="publishDate"
                getValueFromEvent={(e)=>{
                    if(e){
                        form.setFieldsValue({publishStartDate:e[0].valueOf()})
                        form.setFieldsValue({publishEndDate:e[1].valueOf()})
                    }else{
                        form.setFieldsValue({publishStartDate:null})
                        form.setFieldsValue({publishEndDate:null})
                    }
                }}>
                    <RangePicker allowClear/>
                </Form.Item>
            )
        }
        if(props.type == 'publishing'){
            items.push(
                <Form.Item
                label='车长（米）'
                name="vehicleLength">
                    <Select style={{width:'100px'}} allowClear
                    listHeight='400'>
                        {
                            vehicleLengths.map(item => {
                                return <Select.Option value={item}>
                                            {item}
                                        </Select.Option>
                            })
                        }
                        
                    </Select>
                </Form.Item>
            )
            items.push(
                <Form.Item
                label='车型'
                name="vehicleModel">
                    <Select style={{width:'100px'}} allowClear>
                        {
                            _.keys(vehicleModels).map(key => {
                                return <Select.Option value={key}>
                                            {vehicleModels[key]}
                                        </Select.Option>
                            })
                        }
                        
                    </Select>
                </Form.Item>
            )
        }
        if(!(window.innerWidth<1600&&props.type=='publishing')){
            items.push(
                <Form.Item
                label='始发地'
                name="fromAreaCodes"
                getValueFromEvent={e => {
                    form.setFieldsValue({'fromAreaCode':_.last(e)})
                    return e
                }}>
                    <Cascader 
                    options={AREA_CODE} 
                    placeholder={'始发地'} 
                    />
                </Form.Item>
            )
            items.push(
                <Form.Item
                label='目的地'
                name="toAreaCodes"
                getValueFromEvent={e => {
                    form.setFieldsValue({'toAreaCode':_.last(e)})
                    return e
                }}>
                    <Cascader 
                    options={AREA_CODE} 
                    placeholder={'目的地'} 
                    />
                </Form.Item>
            )
        }
        if(props.type == 'onTheWay'&&window.innerHeight>900){
            items.push(
                <Form.Item
                label='发车日期'
                name="pickupDate"
                getValueFromEvent={(e)=>{
                    if(e){
                        form.setFieldsValue({pickupStartDate:e[0].valueOf()})
                        form.setFieldsValue({pickupEndDate:e[1].valueOf()})
                    }else{
                        form.setFieldsValue({pickupStartDate:null})
                        form.setFieldsValue({pickupEndDate:null})
                    }
                }}>
                    <RangePicker allowClear/>
                </Form.Item>
            )
        }
        if(props.type == 'unSign'&&window.innerHeight>900){
            items.push(
                <Form.Item
                label='到达日期'
                name="deliveryDate"
                getValueFromEvent={(e)=>{
                    if(e){
                        form.setFieldsValue({deliveryStartDate:e[0].valueOf()})
                        form.setFieldsValue({deliveryEndDate:e[1].valueOf()})
                    }else{
                        form.setFieldsValue({deliveryStartDate:null})
                        form.setFieldsValue({deliveryEndDate:null})
                    }
                }}>
                    <RangePicker allowClear/>
                </Form.Item>
            )
        }
        if(props.type == 'closed'&&window.innerHeight>900){
            items.push(
                <Form.Item
                label='关闭日期'
                name="closeDate"
                getValueFromEvent={(e)=>{
                    if(e){
                        form.setFieldsValue({closeStartDate:e[0].valueOf()})
                        form.setFieldsValue({closeEndDate:e[1].valueOf()})
                    }else{
                        form.setFieldsValue({closeStartDate:null})
                        form.setFieldsValue({closeEndDate:null})
                    }
                }}>
                    <RangePicker allowClear/>
                </Form.Item>
            )
        }
        if(props.type == 'completed'&&window.innerHeight>900){
            items.push(
                <Form.Item
                label='签收日期'
                name="signDate"
                getValueFromEvent={(e)=>{
                    if(e){
                        form.setFieldsValue({signStartDate:e[0].valueOf()})
                        form.setFieldsValue({signEndDate:e[1].valueOf()})
                    }else{
                        form.setFieldsValue({signStartDate:null})
                        form.setFieldsValue({signEndDate:null})
                    }
                }}>
                    <RangePicker allowClear/>
                </Form.Item>
            )
        }
        items.push(
            <Form.Item
            name="searchField">
                <Input suffix={<SearchOutlined />} style={{width:'200px'}} allowClear></Input>
            </Form.Item>
        )
        return items
    }


    return (
        <Form colon={false} hideRequiredMark={true}
        form={form}
        style={{display:'flex',flexDirection:'row'}}
        {...props}>
            <Form.Item noStyle name='publishStartDate'/>
            <Form.Item noStyle name='publishEndDate'/>
            <Form.Item noStyle name='pickupStartDate'/>
            <Form.Item noStyle name='pickupEndDate'/>
            <Form.Item noStyle name='deliveryStartDate'/>
            <Form.Item noStyle name='deliveryEndDate'/>
            <Form.Item noStyle name='closeStartDate'/>
            <Form.Item noStyle name='closeEndDate'/>
            <Form.Item noStyle name='signStartDate'/>
            <Form.Item noStyle name='signEndDate'/>
            <Form.Item noStyle name='fromAreaCode'/>
            <Form.Item noStyle name='toAreaCode'/>
            <Space>
                {getFormItems()}
            </Space>
        </Form>
    )
}