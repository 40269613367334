import React, { Component } from "react";
import { Table, Divider, Tag } from "antd";
import { observer } from "mobx-react";
import _ from "lodash"

export const mergeParams = (searchParams,allValues,searchProps,keepProps) => {
  const keep = _.pick(searchParams,_.union(['pageSize','current'],keepProps))
  searchParams = _.pick(allValues,searchProps)
  return {...searchParams,...keep}
}

export default observer(function MobxTable(props){

  let initParams = {}

  const getDefaultPageSize = () => {
    if(window.innerHeight>900){
      return 10
    }
    return 4
  }

  React.useEffect(() => {
    fetch()
    return () => store[params || "searchParams"] = initParams; 
  },[])

  const fetch = () => {
    const { store, method, result, params } = props;
    let searchParams = store[params || "searchParams"];
    initParams = _.cloneDeep(searchParams)
    searchParams.pageSize = searchParams.pageSize || getDefaultPageSize();
    searchParams.current = searchParams.current || 1;
    store[params || "searchParams"] = searchParams;
    store[result || "searchResult"].loading = false;
    store[method || "search"]();
  };

  const handleTableChange = (pagination, filters, sorter,extra) => {
    const { store, params } = props;
    let searchParams = store[params || "searchParams"];
    Object.assign(
      searchParams || {},
      { current: pagination.current },
      { sortType: sorter.order },
      { field: sorter.field }
    );
    store[params || "searchParams"] = searchParams;
    fetch();
  };

  const { store, result, params} = props;
  const searchParams = store[params || "searchParams"];
  const resultTemp = store[result || "searchResult"];

  const getCurrent = () => {
    if(resultTemp.results?.length==0&&resultTemp.count>0){
      searchParams.current = 1
      fetch()
    }
    return searchParams.current
  }

  const pagination = {
    pageSize: searchParams.pageSize || getDefaultPageSize(),
    current: getCurrent(),
    total: resultTemp.count
  };

  return (
    <Table
        columns={props.columns}
        style={props.style}
        rowKey={"id"}
        locale={{ emptyText: '没有数据' }}
        dataSource={resultTemp.results}
        pagination={pagination}
        loading={resultTemp.loading}
        onChange={handleTableChange}
        {...props}
      />
  )
})