import React, { useState, useEffect } from "react";
import {Form,Input,Button,Space,Row,Col,message} from "antd"
import { useHistory } from "react-router-dom";
import LogoImg from "assert/images/logo1.png"
import PhoneImg from "assert/images/phone.png"
import SecretImg from "assert/images/secret.png"
import UserImg from "assert/images/user.png"
import CodeImg1 from "assert/images/code1.png"
import Vcode from "react-vcode";
import axios from "axios";
import CountButton from "components/CountButton"
import {regular} from "common/Constant"

const captchaKey = `captcha-${new Date().getTime()}`;
export default function(){
    let history = useHistory()
    const [form] = Form.useForm();

    const [imageCode,setImageCode] = React.useState()
    const [formData,setFormData] = React.useState({})

    const onFinish = (values) => {
        const captchaKey = localStorage.getItem("captchaKey");
        values.captchaKey=captchaKey
        axios.put(`/api/auth/forgetPw`,values)
        .then(res => {
            message.success('忘记密码修改成功')
            history.push('/login')
        })
    }

    const captchaOnClick = () => {
        const captchaKey = localStorage.getItem("captchaKey");
        axios.get(`/api/auth/captcha`,{params:{key:captchaKey}})
        .then(res => {
            setImageCode(res.data)
        })
    };

    React.useEffect(() => {
        localStorage.setItem("captchaKey", captchaKey);
        captchaOnClick();
    },[])

    return (
        <div className='auth-bg'>
            <div className='forget-container'>
                    <Space>
                        <img src={LogoImg} style={{width: '110px'}}/>
                        <span style={{
                        fontSize:'34px',
                        color:'#1C56FB',
                        fontWeight:'530',
                        }}>双龙火云智慧物流货运平台客户端</span>
                    </Space>
                    <Form.Provider onFormChange={(formName) => {
                            setFormData(form.getFieldsValue())
                        }}>
                        <Form
                            form={form}
                            className='form'
                            onFinish={onFinish}>
                            <Form.Item
                                name="accountName"
                                rules={[{ required: true, message: '请输入账号' }]}>
                                <Input prefix={<img src={UserImg}/>} style={{width:'100%'}} placeholder='请输入账号'/>
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                rules={[{ required: true, message: '请输入手机号' }]}>
                                <Input prefix={<img src={PhoneImg}/>} style={{width:'100%'}} placeholder='请输入手机号'/>
                            </Form.Item>
                            <Form.Item
                                name="captcha"
                                rules={[{ required: true, message: '请输入图形验证码' }]}>
                                <Row>
                                    <Col span={12}>
                                        <Input prefix={<img src={CodeImg1}/>} style={{width:'100%'}} placeholder='请输入图形验证码'/>
                                    </Col>
                                    <Col span={12} style={{display:'flex',justifyContent:'center'}}>
                                        <Vcode onClick={captchaOnClick} value={imageCode} />
                                    </Col>
                                </Row>
                                
                            </Form.Item>
                            <Form.Item
                                name="code"
                                rules={[{ required: true, message: '请输入手机验证码' }]}>
                                    <Row>
                                        <Col span={12}>
                                            <Input prefix={<img src={CodeImg1}/>} style={{width:'100%'}} placeholder='请输入手机验证码'/>
                                        </Col>
                                        <Col span={1}/>
                                        <Col span={11}>
                                            <CountButton
                                            style={{
                                                width: "100%",
                                                height:'90%'
                                            }}
                                            option="FORGET_PASSWORD"
                                            captchaKey={captchaKey}
                                            {...formData}
                                            />
                                        </Col>
                                    </Row>
                            </Form.Item>
                            <Form.Item
                                    name="password"
                                    hasFeedback
                                    validateFirst={true}
                                    rules={[
                                        { required: true, message: '请输入6-12位密码' },
                                        {pattern:regular.password,message:'请输入6-12位密码'}]}>
                                    <Input.Password prefix={<img src={SecretImg}/>} style={{width:'100%'}} placeholder='请输入6-12位密码'/>
                                </Form.Item>
                                <Form.Item
                                    name="confirm"
                                    dependencies={['password']}
                                    hasFeedback
                                    validateFirst={true}
                                    rules={[
                                            { required: true, message: '请输入6-12位密码' },
                                            {pattern:regular.password,message:'请输入6-12位密码'},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                    }
                                                    return Promise.reject('密码不一致!');
                                                },
                                                })
                                            ]}>
                                    <Input.Password prefix={<img src={SecretImg}/>} style={{width:'100%'}} placeholder='确认密码'/>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"
                                    className='auth-submit-button'>
                                        <span >提交</span>
                                    </Button>
                                </Form.Item>
                                <div style={{display:'flex',justifyContent:'center'}}
                                onClick={() => history.push('/login')}>
                                    <a>直接登录</a>
                                </div>
                        </Form>
                    </Form.Provider>
            </div>
        </div>
    )
}