import React, { useState, useEffect } from "react";
import {Row,Col,Button,Layout,Image,Table,Modal,message,Space, Input,Form} from "antd"
import {Title} from "components/common"
import "../index.less"
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import {formatTime,TimeFormats,vehicleModels,halfScreenHeight,noData} from "common/Constant"
import LogisticsTrack from "components/Logistics/Track"
import useStores from "stores/StoreContext"
import _ from "lodash"
import CBreadcrumb from "components/CBreadcrumb"
import {FallOutlined,PictureOutlined,ExclamationCircleOutlined} from "@ant-design/icons";
import EvaluateModal from "components/Logistics/Evaluate"
import styled from "styled-components";

const cols = [
    {title:'',dataIndex:'v1',width:'15%',className:'cell',align:'right'},
    {title:'',dataIndex:'v2',width:'35%'},
    {title:'',dataIndex:'v3',width:'15%',className:'cell',align:'right'},
    {title:'',dataIndex:'v4',width:'35%'},
]

let receipts = []
export default function({match}){
    const history = useHistory()
    const [closeForm] = Form.useForm()
    const id = match.params.id;
    const [detail,setDetail] = React.useState({waybill:{price:0},shipments:[]})
    const [trackVisible,setTrackVisible] = React.useState(false)
    const [receiptVisible,setReceiptsVisible] = React.useState(false)
    const [closeVisible,setCloseVisible] = React.useState(false)
    const [earlierVisible,setEarlierVisible] = React.useState(false)
    const [laterVisible,setLaterVisible] = React.useState(false)
    const [damageVisible,setDamageVisible] = React.useState(false)
    const [receipt,setReceipt] = React.useState(receipts[0])
    const [evaluateVisible,setEvaluateVisible] = React.useState(false)
    const [evaluateReadOnly,setEvaluateReadOnly] = React.useState(false)

    React.useEffect(() => {
        refresh()
    },[])

    const refresh = () => {
        axios.get(`/api/logistics/${id}`)
        .then(res => {
            setDetail(res.data)
            const {transportOrder} = res.data
            if(transportOrder&&transportOrder.deliveryReceipt){
                receipts = res.data.transportOrder?.deliveryReceipt.split(',')
                setReceipt(receipts&&receipts[0])
            }
           setEvaluateReadOnly(transportOrder?.efficiencyScore!=undefined&&transportOrder?.efficiencyScore!=null)
        })
    }

    const earlierSurcharge = () =>{
        const { surcharge } = detail
        return surcharge ? surcharge.warehouseOut + surcharge.devanning + surcharge.overtime + surcharge.detention + surcharge.loadingGate : 0
    }

    const laterSurcharge= () =>{
        const { surcharge } = detail
        return surcharge ? surcharge.unload + surcharge.laterOther : 0
    }

    const waybillPriceTotal=()=> {
        const { waybill, transportOrder } = detail
        return waybill.price + laterSurcharge() + earlierSurcharge() - (transportOrder?transportOrder.cargoDamagePrice:0)
    }


    const getBreadcrumbTitle = () => {
        let title = {}
        if(detail){
            switch(detail.logisticsState){
                case 'publishing':
                    title={
                        text:'发布中运单',
                        href:`/waybill?tabPaneKey=${detail.logisticsState}`
                    }
                    break
                case 'unConfirm':
                    title={
                        text:'发布中运单',
                        href:`/waybill?tabPaneKey=${detail.logisticsState}`
                    }
                    break
                case 'unSend':
                    title={
                        text:'待发车运单',
                        href:`/waybill?tabPaneKey=${detail.logisticsState}`
                    }
                    break
                case 'onTheWay':
                    title={
                        text:'在途中运单',
                        href:`/waybill?tabPaneKey=${detail.logisticsState}`
                    }
                    break
                case 'unSign':
                    title={
                        text:'待签收运单',
                        href:`/waybill?tabPaneKey=${detail.logisticsState}`
                    }
                    break
                case 'completed':
                    title={
                        text:'已完成运单',
                        href:`/waybill?tabPaneKey=${detail.logisticsState}`
                    }
                    break
                case 'closed':
                    title={
                        text:'已关闭运单',
                        href:`/waybill?tabPaneKey=${detail.logisticsState}`
                    }
                    break 
            }
        }
        return title
    }

    const shippingInfoTable = () => {
        const {carrier} = detail
        let items = []
        if(carrier){
            items.push(
                {
                    v1:'承运方',
                    v2:carrier?.companyName,
                    v3:'电话号码',
                    v4:carrier?.contractPhone
                }
            )
        }
        items.push(
            ...[
                {
                    v1:'承运司机',
                    v2:detail.driver?.realName,
                    v3:'电话号码',
                    v4:detail.driver?.accountName
                },
                {
                    v1:'车牌号码',
                    v2:detail.vehicle?.plateNumber,
                    v3:'车长（米）',
                    v4:detail.vehicle?.vehicleLength
                },
                {
                    v1:'车型',
                    v2:vehicleModels[detail.vehicle?.vehicleModel],
                    v3:'载重（吨）',
                    v4:detail.vehicle?.loads
                }
            ]
        )
        return items
    }


    return (
        <Layout>
            <CBreadcrumb items={[
                    getBreadcrumbTitle(),
                    {
                        text:'运单详情',
                        href:''
                    }
                ]}>
                <Space size='large'>
                    <span>
                        运单编号：{detail.waybill?.id}
                    </span>
                    
                </Space>
            </CBreadcrumb>
            <Layout className='full'  style={{height:`${halfScreenHeight*1.8}px`}}>
                <Container>
                    {
                        detail.logisticsState=='closed'
                        &&
                        <span style={{color:'red'}}>
                            关闭原因：{detail.transportOrder?.closeReason||detail.waybill?.closeReason}
                        </span>
                    }
                </Container>
                <Title text='装卸货信息'/>
                <Container>
                    <Table 
                        bordered
                        pagination={false}
                        showHeader={false}
                        columns={cols}
                        dataSource={[
                            {
                                v1:'装货联系人',
                                v2:<Space>
                                        <span>{detail.waybill.acceptName}</span>
                                        <span style={{whiteSpace:'nowrap'}}>{detail.waybill.acceptPhone}</span>
                                    </Space>,
                                v3:'装货地址',
                                v4:<TdTextWrap >
                                    {detail.waybill.fromAddress}
                                    </TdTextWrap>
                            },
                            {
                                v1:'卸货联系人',
                                v2:<Space>
                                        <span>{detail.waybill.unloadName}</span>
                                        <span style={{whiteSpace:'nowrap'}}>{detail.waybill.unloadPhone}</span>
                                    </Space>,
                                v3:'卸货地址',
                                v4:<TdTextWrap>
                                    {detail.waybill.toAddress}
                                    </TdTextWrap>
                            },
                            {
                                v1:'预计发车时间',
                                v2:formatTime(detail.waybill.expectPublishTime,TimeFormats.YMDHM),
                                v3:'预计到达时间',
                                v4:formatTime(detail.waybill.expectReachTime,TimeFormats.YMDHM)
                            },
                        ]}/>
                </Container>
                {
                    !detail.transportOrder&&
                    <div>
                        <Title text='需求信息'/>
                        <Container>
                            <Table 
                                bordered
                                pagination={false}
                                showHeader={false}
                                columns={cols}
                                dataSource={[
                                    {
                                        v1:'车长',
                                        v2:detail.waybill.vehicleLength,
                                        v3:'车型',
                                        v4:vehicleModels[detail.waybill.vehicleModel]
                                    },
                                ]}/>
                        </Container>
                    </div>
                }
                {
                    detail.transportOrder&&
                    <div>
                        <Title text='承运信息'/>
                        <Container>
                            <Table 
                                pagination={false}
                                showHeader={false}
                                columns={cols}
                                dataSource={shippingInfoTable()}/>
                        </Container>
                    </div>
                }
                <Title text='货物信息'/>
                <Container>
                    <Table 
                        footer={() => <div>总计：{detail.shipments.length}种货物；{_.sumBy(detail.shipments,item=>item.weight).toFixed(1)}吨</div>}
                        columns={[
                            {title:'货物名称',dataIndex:'cargoName',width:'30%'},
                            {title:'重量(吨)',dataIndex:'weight'},
                            {title:'体积(立方米)',dataIndex:'volume',render:(text)=>noData(text)},
                            {title:'包装',dataIndex:'packingManner',width:'30%'},
                        ]}
                        dataSource={detail.shipments}/>
                </Container>
                <Title text='其它信息'/>
                <Container>
                    <Table 
                        bordered
                        pagination={false}
                        showHeader={false}
                        columns={cols}
                        dataSource={[
                            {
                                v1:'纸质订单编号',
                                v2:detail.waybill.customerOrderNo,
                                v3:'发货备注',
                                v4:<TdTextWrap >
                                        {noData(detail.waybill.remark)}
                                    </TdTextWrap>
                            },
                            {
                                v1:'货物类型',
                                v2:<Space>
                                        粮食
                                    </Space>,
                                v3:'运输组织',
                                v4:<span>公空联运</span>
                            },
                            {
                                v1:'保险公司代号',
                                v2:<Space>
                                        --
                                    </Space>,
                                v3:'保险单号',
                                v4:<span>--</span>
                            },
                            {
                                v1:'业务类型',
                                v2:<Space>
                                        城市配送
                                    </Space>,
                            }
                            
                        ]}/>
                </Container>
                <Title text='费用信息'/>
                <Container>
                    <Table 
                        bordered
                        pagination={false}
                        showHeader={false}
                        columns={cols}
                        dataSource={[
                            {
                                v1:'提货附加费',
                                v2:<div style={{display:'flex',justifyContent:'space-between'}}>
                                        {earlierSurcharge().toFixed(1)}
                                        {
                                            detail.surcharge?.earlierSaveBy?
                                            <a onClick={() => {
                                                setEarlierVisible(true)
                                            }}>查看</a>:null
                                        }
                                        
                                    </div>,
                                v3:'卸货附加费',
                                v4:<div style={{display:'flex',justifyContent:'space-between'}}>
                                        {laterSurcharge().toFixed(1)}
                                        {
                                            detail.surcharge?.laterSaveBy?
                                            <a onClick={() => {
                                                setLaterVisible(true)
                                            }}>查看</a>:null
                                        }
                                    </div>
                            },
                            {
                                v1:'货损扣款',
                                v2:<div style={{display:'flex',justifyContent:'space-between'}}>
                                        {(detail.transportOrder?.cargoDamagePrice||0).toFixed(1)}
                                        {
                                            detail.transportOrder?.cargoDamagePrice?
                                            <a onClick={() => {
                                                setDamageVisible(true)
                                            }}>查看</a>:null
                                        }
                                    </div>,
                                v3:'扣款原因',
                                v4:<span>{noData(detail.transportOrder?.cargoDamageReason)}</span>
                            },
                            {
                                v1:'运费',
                                v2:detail.waybill.price.toFixed(1),
                                v3:'运单总额',
                                v4:<span style={{color:'red'}}>{waybillPriceTotal().toFixed(1)}</span>
                            },
                        ]}/>
                </Container>
                <Container>
                    <div style={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                        }}>
                        <Space>
                            {
                                (detail.logisticsState=='publishing'||detail.logisticsState=='unConfirm')&&
                                <Button type='primary' danger
                                onClick={() => setCloseVisible(true)}>关闭运单</Button>
                            }
                            {
                                detail.logisticsState=='unSend'&&
                                <Button type='primary' 
                                onClick={() => {
                                    Modal.confirm({
                                        title:'确认发车',
                                        onOk:() => {
                                            axios.post(`/api/logistics/pickup/${detail.transportOrder.id}`)
                                            .then(res => {
                                                message.success('发车成功')
                                                refresh()
                                            })
                                        }
                                    })
                                    
                                }}>确认发车</Button>
                            }
                            {
                                detail.logisticsState=='unSign'&&
                                <Button type='primary' 
                                onClick={() => {
                                    Modal.confirm({
                                        title:'确认签收',
                                        onOk:() => {
                                            axios.post(`/api/logistics/sign/${detail.transportOrder.id}`)
                                            .then(res => {
                                                message.success('签收成功')
                                                refresh()
                                            })
                                        }
                                    })
                                    
                                }}>确认签收</Button>
                            }
                            {
                                detail.logisticsState=='completed'&&
                                <Button type='primary'
                                onClick={() => {
                                    setEvaluateVisible(true)
                                }}>
                                    {evaluateReadOnly?'查看评价':'评价'}
                                </Button>
                            }
                            <Button onClick={() => history.push(`/waybill?tabPaneKey=${detail.logisticsState}`)}>返回列表</Button>
                        </Space>
                        <Space size='large'>
                            {
                                (detail.transportOrder?.pickupTime)&&
                                <Space style={{cursor:'pointer'}}
                                onClick={() => setTrackVisible(true)}>
                                    <FallOutlined style={{color:'#1989FA'}}/>
                                    <span style={{color:'#1989FA'}}>运单跟踪</span>
                                </Space>
                            }
                            {
                                detail.transportOrder&&detail.transportOrder.deliveryReceipt&&
                                <Space style={{cursor:'pointer'}}
                                onClick={() => setReceiptsVisible(true)}>
                                    <PictureOutlined style={{color:'#1989FA'}}/>
                                    <span style={{color:'#1989FA'}}>签单照片</span>
                                </Space>
                            }
                        </Space>
                    </div>
                </Container>
            </Layout>
            <Modal title='运单跟踪'
            width='800px'
            footer={null}
            onCancel={()=>setTrackVisible(false)}
            visible={trackVisible}>
                <LogisticsTrack 
                waybillId={detail.waybill.id}
                orderId={detail.transportOrder&&detail.transportOrder.id}/>
            </Modal>
            <Modal 
            title='签单照片'
            width='800px'
            footer={null}
            onCancel={() => setReceiptsVisible(false)}
            visible={receiptVisible}>
                <div style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}>
                    <div style={{
                            width:'100%',
                            height:'400px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center'
                        }}>
                        <img 
                        src={`/file/${receipt}`} 
                        style={{height:'auto',width:'auto',maxHeight:'100%',maxWidth:'100%'}}/>
                    </div>
                    <Row align='middle' gutter={10} style={{width:'100%',marginTop:'10px'}}>
                        <Col span={4}></Col>
                        {
                            receipts.map(item => {
                                return <Col span={4} 
                                        style={{height:'60px',padding:'10px'}}
                                        onClick={() => {
                                            setReceipt(item)
                                        }}>
                                            <img 
                                            src={`/file/${item}`} 
                                            style={{
                                                height:'100%',
                                                border:`${receipt==item?'1px':'0px'} solid #1989FA`}}/>
                                        </Col>
                            })
                        }
                    </Row>
                </div>
            </Modal>
            <Modal 
            title={'提货附加费'}
            footer={null}
            width='600px'
            visible={earlierVisible}
            onCancel={() => setEarlierVisible(false)}>
                <div>
                    <Row style={{lineHeight:3}}>
                        <Col span={12}>
                            <Space>
                                <span>出库费</span>
                                <span>{detail.surcharge?.warehouseOut||0}元</span>
                            </Space>
                            <SurchargeImgs imgs={detail.surcharge?.warehouseOutReceipt}/>
                        </Col>
                        <Col span={12}>
                            <Space>
                                <span>拆托费</span>
                                <span>{detail.surcharge?.devanning||0}元</span>
                            </Space>
                            <SurchargeImgs imgs={detail.surcharge?.devanningReceipt}/>
                        </Col>
                    </Row>
                    <Row style={{lineHeight:3}}>
                        <Col span={12}>
                            <Space>
                                <span>压车费</span>
                                <span>{detail.surcharge?.detention||0}元</span>
                            </Space>
                            <SurchargeImgs imgs={detail.surcharge?.detentionReceipt}/>
                        </Col>
                        <Col span={12}>
                            <Space>
                                <span>进门费</span>
                                <span>{detail.surcharge?.loadingGate||0}元</span>
                            </Space>
                            <SurchargeImgs imgs={detail.surcharge?.loadingGateReceipt}/>
                        </Col>
                    </Row>
                    <Row style={{lineHeight:3}}>
                        <Col span={12}>
                            <Space>
                                <span>加班费</span>
                                <span>{detail.surcharge?.overtime||0}元</span>
                            </Space>
                            <SurchargeImgs imgs={detail.surcharge?.overtimeReceipt}/>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div>
                                小计：<span style={{color:'red'}}>￥{earlierSurcharge().toFixed(1)}</span>
                            </div>
                            <div style={{marginTop:'10px'}}>
                                业务备注：<span>{noData(detail.surcharge?.earlierDesc)}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Modal 
            title={'卸货附加费'}
            footer={null}
            width='600px'
            visible={laterVisible}
            onCancel={() => setLaterVisible(false)}>
                <div>
                    <Row style={{lineHeight:3}}>
                        <Col span={12}>
                            <Space>
                                <span>卸车费</span>
                                <span>{detail.surcharge?.unload||0}元</span>
                            </Space>
                            <SurchargeImgs imgs={detail.surcharge?.unloadReceipt}/>
                        </Col>
                        <Col span={12}>
                            <Space>
                                <span>其他</span>
                                <span>{detail.surcharge?.laterOther||0}元</span>
                            </Space>
                            <SurchargeImgs imgs={detail.surcharge?.laterOtherReceipt}/>
                        </Col>
                    </Row>
                    <Row >
                        <Col span={12}>
                            <div>
                                小计：<span style={{color:'red'}}>￥{laterSurcharge().toFixed(1)}</span>
                            </div>
                            <div style={{marginTop:'10px'}}>
                                业务备注：<span>{noData(detail.surcharge?.laterDesc)}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Modal 
            title={'货损扣款'}
            footer={null}
            width='600px'
            visible={damageVisible}
            onCancel={() => setDamageVisible(false)}>
                <div>
                    <Row style={{lineHeight:3}}>
                        <Space>
                            <span>货损扣款</span>
                            <span>{detail.transportOrder?.cargoDamagePrice||0}元</span>
                        </Space>
                    </Row>
                    <Row style={{lineHeight:3}}>
                        <Space style={{display:'flex',alignItems:'flex-start'}}>
                            <span style={{whiteSpace:'nowrap'}}>扣款原因</span>
                            <span>{noData(detail.transportOrder?.cargoDamageReason)}</span>
                        </Space>
                    </Row>
                </div>
            </Modal>
            <Modal
            visible={closeVisible}
            onCancel={() => setCloseVisible(false)}
            onOk={() => {
                closeForm.validateFields()
                .then(values => {
                    axios.post(`/api/logistics/cancel/${id}?reason=${values.reason}`)
                    .then(res => {
                        message.success('关闭运单成功')
                        setCloseVisible(false)
                        refresh()
                    })
                })
                
            }}>
                <div>
                    <Space style={{display:'flex',alignItems:'center'}}>
                        <ExclamationCircleOutlined style={{color:'orange',fontSize:'18px'}} />
                        <span style={{fontSize:'16px'}}>
                            是否确认关闭订单？
                            <span style={{fontSize:'14px'}}>关闭后不可恢复</span>
                        </span>
                    </Space>
                    <Form style={{marginTop:'20px'}}
                    form={closeForm}>
                        <Form.Item
                        name='reason'
                        label='关闭原因'
                        rules={[{ required: true, message: '请输入关闭原因' }]}>
                            <Input.TextArea 
                            maxLength={200}
                            placeholder='请输入关闭原因'
                            autoSize={{minRows: 3, maxRows:6}}/>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <EvaluateModal 
                item={{...detail.transportOrder,evaluateList:detail.evaluates,transportOrderId:detail.transportOrder?.id}} 
                readOnly={evaluateReadOnly}
                visible={evaluateVisible}
                onCancel={() => {
                    setEvaluateVisible(false)
                    refresh()
                }}/>
        </Layout>
    )
}

function SurchargeImgs({imgs}){
    return (
        <div >
            <Space>
                {
                    imgs&&imgs.split(',').map(img => {
                        return <Image  
                                src={`/file/${img}`} 
                                width={'60px'}/>
                    })
                }
            </Space>
        </div>
    )
}

function Container(props){
    return <Row style={{padding:'15px'}}>
                <Col span={20}>
                    {props.children}
                </Col>
            </Row>
}


export const TdTextWrap = styled.div`
    word-break:break-all; 
`