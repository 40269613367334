import React, { useState, useEffect } from "react";
import {Divider,Input,Space,Layout,Row,Col,Form,Cascader,DatePicker,Modal} from "antd"
import {Title,Actions} from "components/common"
import ReactEcharts from 'echarts-for-react';
import axios from "axios"
import {formatTime,halfScreenHeight} from "common/Constant"
import CommonSearch from "components/Search"
import MobxTable,{mergeParams} from "components/List/MobxTable"
import {SearchOutlined} from "@ant-design/icons";
import _ from "lodash"
import AREA_CODE from "common/AreaCode"
import {commonCol} from "pages/Waybill"
import useStores from "stores/StoreContext"
import {useParams, useHistory, useLocation } from "react-router-dom";
import LogisticsTrack from "components/Logistics/Track"
import BillModal from "components/Logistics/BillModal"
import Report from "components/Report"
import moment from "moment"
const { RangePicker } = DatePicker;

const searchProps = [
    'searchField',
    'publishStartDate',
    'publishEndDate',
    'pickupStartDate',
    'pickupEndDate',
    'fromAreaCode',
    'toAreaCode']
export default function (){

    const [notifications,setNotifications] = useState([])
    const {waybillStore} = useStores()
    let history = useHistory();
    const [trackVisible,setTrackVisible] = React.useState(false)
    const [billVisible,setBillVisible] = React.useState(false)
    const [billId,setBillId] = React.useState()
    const [item,setItem] = React.useState({})
    const [report,setReport] = React.useState()


    useEffect(() => {
        axios.get(`/api/notification`)
        .then(res => {
            setNotifications(res.data)
        })
        const startDate = moment().subtract(6, 'days').valueOf()
        const endDate = moment().valueOf()
        axios.get(`/api/report?startDate=${startDate}&endDate=${endDate}`)
        .then(res => {
            setReport(res.data)
        })
    },[])

    return (
        <Layout style={{height:'100%',padding:'10px',height:`${halfScreenHeight*2 - 66}px`}} >
            <Row style={{height:`${halfScreenHeight*0.8}px`}}>
                <Col span={18}>
                    <div style={{
                        backgroundColor:'white',
                        marginRight:'18px',
                        height:'100%'}}>
                        <Report 
                        datas={report} 
                        style={{height:`${halfScreenHeight - halfScreenHeight/5}px`}} />
                    </div>
                </Col>
                <Col span={6} style={{height:'100%',backgroundColor:'white'}}>

                    <Title text='消息提醒'/>
                    <div style={{
                            padding:'0px 15px 15px 15px',
                            overflowY:'auto',
                            height:`${halfScreenHeight*0.8 - 60}px`,
                            width:'100%'}}>
                    {
                        notifications.map(item => {
                            return <div >
                                        <div 
                                        onClick={() => {
                                            if(item.waybillId){
                                                history.push(`/waybill/${item.waybillId}`)
                                            }
                                            
                                            if(item.billId){
                                                setBillVisible(true)
                                                setBillId(item.billId)
                                            }
                                        }}
                                        style={{
                                            display:'flex',
                                            alignItems:'center',
                                            cursor:'pointer'
                                        }}>
                                            <span className='ant-table-cell-ellipsis'
                                            title={`${formatTime(item.createTime)}`}>
                                                {formatTime(item.createTime)}
                                            </span>
                                            <span className='ant-table-cell-ellipsis'
                                            style={{marginLeft:'15px'}}
                                            title={item.content}>{item.content}</span>
                                        </div>
                                        <Divider style={{margin:'13px'}}/>
                                    </div>
                        })
                    }
                    </div>
                    
                </Col>
            </Row>
            <div style={{backgroundColor:'white',marginTop:'10px',paddingTop:'15px',height:`${halfScreenHeight}px`}}>
                <CommonSearch title='在途运单列表'>
                    <SearchForm type='onTheWay'
                    onValuesChange={(changedValues,allValues) => {
                        waybillStore.onTheWayParams.logisticsState = 'onTheWay'
                        waybillStore.onTheWayParams = mergeParams(waybillStore.onTheWayParams,allValues,searchProps,['logisticsState'])
                        waybillStore.searchOnTheWay()
                    }}/>
                </CommonSearch>
                <MobxTable
                    rowKey="id"
                    store={waybillStore}
                    pageSize={5}
                    params='onTheWayParams'
                    result='onTheWayResult'
                    method='searchOnTheWay'
                    columns={[
                        ...commonCol,
                        {
                            title:'承运司机',
                            dataIndex:'driverName',
                            ellipsis:true
                        },
                        {
                            title:'车牌号',
                            dataIndex:'plateNumber',
                            ellipsis:true
                        },
                        {
                            title:'订单金额',
                            dataIndex:'waybillPrice',
                            ellipsis:true,
                            render:(text) => <span style={{color:'red'}}>￥ {text.toFixed(1)}</span>
                        },
                        {
                            title:'发车时间',
                            dataIndex:'pickupTime',
                            render:(text) => formatTime(text),
                            ellipsis:true
                        },
                        {
                            title:'操作',
                            width:'180px',
                            render:(text,record) => <Actions items = {[
                                {
                                    text:'运单详情',
                                    onClick:() => history.push(`/waybill/${record.waybillId}`)
                                },
                                {
                                    text:'运输轨迹',
                                    onClick:() => {
                                        setTrackVisible(true)
                                        setItem(record)
                                    }
                                }
                            ]}/>
                        }
                    ]}/>
            </div>
            <Modal title='运单跟踪'
                width='800px'
                footer={null}
                onCancel={()=>setTrackVisible(false)}
                visible={trackVisible}>
                <LogisticsTrack 
                waybillId={item.waybillId}
                orderId={item.transportOrderId}/>
            </Modal>
            <BillModal 
            visible={billVisible} 
            onCancel={()=>setBillVisible(false)}
            id={billId}/>
        </Layout>
    )
}

function SearchForm(props){
    const [form] = Form.useForm();
    return (
        <Form colon={false} hideRequiredMark={true}
        form={form}
        style={{display:'flex',flexDirection:'row'}}
        {...props}>
            <Space>
                <Form.Item
                label='发布日期'
                name="publishDate"
                getValueFromEvent={(e)=>{
                    if(e){
                        form.setFieldsValue({publishStartDate:e[0].valueOf()})
                        form.setFieldsValue({publishEndDate:e[1].valueOf()})
                    }else{
                        form.setFieldsValue({publishStartDate:null})
                        form.setFieldsValue({publishEndDate:null})
                    }
                }}>
                    <RangePicker allowClear/>
                </Form.Item>
                {
                    window.innerHeight>900
                    &&
                    <Space>
                        <Form.Item
                        label='始发地'
                        name="fromAreaCodes"
                        getValueFromEvent={e => {
                            form.setFieldsValue({'fromAreaCode':_.last(e)})
                            return e
                        }}>
                            <Cascader 
                            options={AREA_CODE} 
                            placeholder={'始发地'} 
                            />
                        </Form.Item>
                        <Form.Item
                        label='目的地'
                        name="toAreaCodes"
                        getValueFromEvent={e => {
                            form.setFieldsValue({'toAreaCode':_.last(e)})
                            return e
                        }}>
                            <Cascader 
                            options={AREA_CODE} 
                            placeholder={'目的地'} 
                            />
                        </Form.Item>
                    </Space>
                }
                
                <Form.Item
                label='发车日期'
                name="pickupDate"
                getValueFromEvent={(e)=>{
                    if(e){
                        form.setFieldsValue({pickupStartDate:e[0].valueOf()})
                        form.setFieldsValue({pickupEndDate:e[1].valueOf()})
                    }else{
                        form.setFieldsValue({pickupStartDate:null})
                        form.setFieldsValue({pickupEndDate:null})
                    }
                }}>
                    <RangePicker allowClear/>
                </Form.Item>
                <Form.Item
                name="searchField">
                    <Input suffix={<SearchOutlined />} style={{width:'200px'}} allowClear></Input>
                </Form.Item>
            </Space>
            <Form.Item noStyle name='publishStartDate'/>
            <Form.Item noStyle name='publishEndDate'/>
            <Form.Item noStyle name='pickupStartDate'/>
            <Form.Item noStyle name='pickupEndDate'/>
            <Form.Item noStyle name='fromAreaCode'/>
            <Form.Item noStyle name='toAreaCode'/>
        </Form>
    )
}