import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import Header from "common/Layouts/Header"
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Home from "pages/Home"
import Publish from "pages/Publish"
import Address from "pages/Address"
import Shipment from "pages/Shipment"
import Catalogue from "pages/Catalogue"
import Waybill from "pages/Waybill"
import Bill from "pages/Bill"
import BillDetail from "pages/Bill/Detail"
import Offine from "pages/Bill/Offine";
import WaybillDetail from "pages/Waybill/Detail"
import Setting from "pages/Setting"
import SettingAudit from "pages/Setting/Audit"
import InvoDetail from "pages/Invoice/InvoDetail"
import Risk from "pages/Risk"
import Car from "pages/Car"
import UserInfo from "pages/PersonalCenter/UserInfo"
import RechargeList from "pages/PersonalCenter/RechargeList"
import WaterList from "pages/PersonalCenter/WaterList"
import './index.css'
import {
    HomeOutlined,
    FileUnknownOutlined,
    ReconciliationOutlined,
    CreditCardOutlined,
    EnvironmentOutlined,
    AppstoreOutlined,
    LineChartOutlined,
    ClockCircleOutlined,
    SettingOutlined,
    PlusSquareFilled,
    CarOutlined,
    UserAddOutlined
} from "@ant-design/icons";
import Pay from "pages/Pay"
import News from "pages/News"
import Report from "pages/Report"
import useStores from "stores/StoreContext"
import { observer } from 'mobx-react'
import Invoice from "pages/Invoice"

const menus = [
    {
        icon: <HomeOutlined />,
        key: '/home',
        text: '首  页',
        component: () => <Home />
    },
    {
        icon: <FileUnknownOutlined />,
        key: '/publish',
        text: '指派订单',
        component: () => <Publish />
    },

    {
        icon: <ReconciliationOutlined />,
        key: '/waybill',
        text: '运单管理',
        component: () => <Waybill />
    },
    {
        icon: <FileUnknownOutlined />,
        key: '/risk',
        text: '风险运单',
        component: () => <Risk />
    },
    {
        icon: <CreditCardOutlined />,
        key: '/billAudit',
        text: '运费审核',
        component: () => <Bill />
    },
    {
        icon: <EnvironmentOutlined />,
        key: '/address',
        text: '地址管理',
        component: () => <Address />
    },
    {
        icon: <AppstoreOutlined />,
        key: '/shipment',
        text: '货物管理',
        component: () => <Shipment />
    },
    {
        icon: <ClockCircleOutlined />,
        key: '/news',
        text: '新闻通知',
        component: () => <News />
    },
    {
        icon: <ClockCircleOutlined />,
        key: '/report',
        text: '统计报表',
        component: () => <Report />
    },
    {
        icon: <SettingOutlined />,
        key: '/setting',
        text: '系统设置',
        disabled: false,
        component: () => <Setting />
    },
    {
        icon: <PlusSquareFilled />,
        key: '/Catalogue',
        text: '运单上报',
        disabled: false,
        component: () => <Catalogue />
    },
    {
        icon: <CreditCardOutlined />,
        key: '/invoice',
        text: '开票管理',
        component: () => <Invoice />
    },
    {
        icon: <UserAddOutlined />,
        key: '/userInfo',
        text: '个人中心',
        children: [
            {
                key: '/info',
                text: '用户信息',
                component: () => <UserInfo />,
            },
            {
                key: '/running',
                text: '流水列表',
                component: () => <WaterList />,
            },
            {
                key: '/recharge',
                text: '充值记录',
                component: () => < RechargeList />,
            },
        ]
    },
    {
        icon: <CarOutlined />,
        key: '/car',
        text: '合作司机',
        component: () => <Car />
    },
]

export default observer(function () {
    const history = useHistory()
    const location = useLocation()
    const { profileStore } = useStores()
    const [type, setType] = useState(document.body.getBoundingClientRect().width - 1 < 992 ? 'app' : 'desk')
    const { SubMenu } = Menu
    React.useEffect(() => {
        window.addEventListener('resize', () => {
            if (document.body.getBoundingClientRect().width - 1 < 992) {
                setType('app')
            } else {
                setType('desk')
            }
        })

    }, [])

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Header type={type} setType={setType} />
            <Layout>
                <Layout.Sider className={type}>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={["/" + location.pathname.split('/')[1]]}>
                        {
                            menus.map(menu => {
                                return (
                                    menu.children ? <SubMenu title={menu.text} key={menu.key}
                                        icon={menu.icon}>
                                        {menu.children.map(ele => {
                                            return <Menu.Item
                                                key={ele.key}
                                                disabled={!profileStore.profile.approved}
                                                onClick={() => {
                                                    history.push(`${ele.key}`)
                                                }}>
                                                {ele.text}
                                            </Menu.Item>
                                        })}
                                    </SubMenu> : <Menu.Item
                                        key={menu.key}
                                        icon={menu.icon}
                                        disabled={!profileStore.profile.approved}
                                        onClick={() => {
                                            history.push(`${menu.key}`)
                                        }}>
                                        {menu.text}
                                    </Menu.Item>
                                )
                            })
                        }
                    </Menu>
                </Layout.Sider>
                <Layout.Content >
                    <Switch>
                        {
                            menus.map(item => {
                                return (item.children ? item.children.map(ele => {
                                    return <Route key={ele.key} exact path={`${ele.key}`} component={ele.component} />
                                }) : <Route key={item.key} exact path={`${item.key}`} component={item.component} />)
                            })
                        }
                        <Route key={'/home/index'} exact path={'/'} component={Home} />
                        <Route key={'/waybill/detail'} exact path={'/waybill/:id'} component={WaybillDetail} />
                        <Route key={'/billAudit/detail'} exact path={'/billAudit/:id/detail'} component={BillDetail} />
                        <Route key={'/setting/audit'} exact path={'/setting/audit'} component={SettingAudit} />
                        <Route key={'/billAudit/pay'} exact path={'/billAudit/pay'} component={Pay} />
                        <Route key={'/offine'} exact path={'/offine/:id'} component={Offine} />
                        <Route key={'/inDetail'} exact path={'/inDetail/:id'} component={InvoDetail} />
                    </Switch>
                </Layout.Content>
            </Layout>

        </Layout>
    )
})
