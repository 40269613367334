import React from "react"
import { message } from "antd";
import vehicleImg from "assert/images/vehicle.png"
import startImg from "assert/images/start.png"
import endImg from "assert/images/end.png"
import _, { values } from "lodash"
import { formatTime, TimeFormats } from "common/Constant";

export function getGaoDe(lnglat,form) {
  var AMap = window.AMap
  var map = new AMap.Map("container1", {
    // resizeEnable: true,

    center:lnglat.length != 0?[lnglat[0]*1,lnglat[1]*1] :[116.397428, 39.90923],//地图中心点
    zoom: 15, //地图显示的缩放级别
    scrollWheel: true
  });
  // var marker = new AMap.Marker({
  //   icon: vehicleImg,
  //   position: [[lnglat[0]*1,lnglat[1]*1]]
  // });
  // map.add(marker)
  var startMarker = new AMap.Marker({
    position: lnglat.length != 0 ? [lnglat[0], lnglat[1]] : [],
    icon: new AMap.Icon({
      size: new AMap.Size(40, 50),
      image: vehicleImg,
      imageSize: new AMap.Size(40, 50)
    }),
    map: map

  })
  map.on('click', async function (e) {
    let lng = e.lnglat.getLng()
    let lat = e.lnglat.getLat()
    getGaoDe([e.lnglat.getLng(), e.lnglat.getLat()],form)
    getLngLatAddress([e.lnglat.getLng(), e.lnglat.getLat()]).then(
      res =>{
        form.setFieldsValue({address: res,lat,lng})
      }
    )
  });
  
}


export function gaoDe(numbers=[], type) {
  
  var AMap = window.AMap

  var map = new AMap.Map("container", {
    // resizeEnable: true,
    // center: [116.397428, 39.90923],//地图中心点
    zoom: 15, //地图显示的缩放级别
    scrollWheel: true
  });

  //构造路线导航类
  if (numbers.length > 0) {
    switch (numbers.length) {
      case 4:
        AMap.plugin('AMap.Driving', function () {
          var driving = new AMap.Driving({
            // map: map,
            //  size: 1,
            // panel: "panel",
            // ferry: 1, // 是否可以使用轮渡
            // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
            policy: AMap.DrivingPolicy.LEAST_TIME,
            // policy:1
          })
          // startLngLat1, endLngLat1
          driving.search(numbers[0], numbers[1], function (status, result) {
            
            if (status === 'error') message.error('绘制出错')
            // 未出错时，result即是对应的路线规划方案
            drawRoute1(result.routes[0])
          })
          driving.search(numbers[2], numbers[3], function (status, result) {
            
            if (status === 'error') message.error('绘制出错')
            // 未出错时，result即是对应的路线规划方案
            drawRoute1(result.routes[0])
          })
        })
        break;
      default:
        AMap.plugin('AMap.Driving', function () {
          var driving = new AMap.Driving({
            // map: map,
            //  size: 1,
            // panel: "panel",
            // ferry: 1, // 是否可以使用轮渡
            // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
            policy: AMap.DrivingPolicy.LEAST_TIME,
            // policy:1
          })
          if (type == 'zg') {
            // startLngLat1, endLngLat1
            driving.search(numbers[0], numbers[1], function (status, result) {
              
              if (status === 'error') message.error('绘制出错')
              // 未出错时，result即是对应的路线规划方案
              drawRoute1(result.routes[0])
            })
          } else {
            // startLngLat1, endLngLat1
            driving.search(numbers[0], numbers[1], function (status, result) {
              
              if (status === 'error') message.error('绘制出错')
              // 未出错时，result即是对应的路线规划方案
              drawRoute1(result.routes[0])
            })
          }

        })
        break;
    }

  }


  function drawRoute1(route) {
    var path = parseRouteToPath(route)
    console.log(path);
    var startMarker = new AMap.Marker({
      position: path[0],
      icon: 'https://webapi.amap.com/theme/v1.3/markers/n/start.png',
      map: map
    })

    var endMarker = new AMap.Marker({
      position: path[path.length - 1],
      icon: 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
      map: map
    })
    var routeLine = new AMap.Polyline({
      path: path,
      isOutline: true,
      outlineColor: 'red',
      borderWeight: 2,
      strokeWeight: 3,
      strokeColor: 'red',
      lineJoin: 'round'
    })

    routeLine.setMap(map)

    // 调整视野达到最佳显示区域
    map.setFitView([startMarker, endMarker, routeLine])
  }

  function drawRoute(route) {
    var path = parseRouteToPath(route)
    var startMarker = new AMap.Marker({
      position: path[0],
      icon: 'https://webapi.amap.com/theme/v1.3/markers/n/start.png',
      map: map
    })

    var endMarker = new AMap.Marker({
      position: path[path.length - 1],
      icon: 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
      map: map
    })
    var routeLine = new AMap.Polyline({
      path: path,
      isOutline: true,
      outlineColor: '#0091ff',
      borderWeight: 2,
      strokeWeight: 3,
      strokeColor: '#0091ff',
      lineJoin: 'round'
    })

    routeLine.setMap(map)

    // 调整视野达到最佳显示区域
    map.setFitView([startMarker, endMarker, routeLine])
  }

}

// 解析DrivingRoute对象，构造成AMap.Polyline的path参数需要的格式
// DrivingResult对象结构参考文档 https://lbs.amap.com/api/javascript-api/reference/route-search#m_DriveRoute
function parseRouteToPath(route) {
  var path = []
  for (var i = 0, l = route.steps.length; i < l; i++) {
    var step = route.steps[i]
    for (var j = 0, n = step.path.length; j < n; j++) {
      path.push(step.path[j])
    }
  }
  return path
}

export async function getLngLatAddress(address) {
  return new Promise((resolve) => {
    var AMap = window.AMap
    //构造路线导航类
    AMap.plugin('AMap.Geocoder', function () {
      var geocoder = new AMap.Geocoder({
        // city: "010", //城市设为北京，默认：“全国”
      })
      geocoder.getAddress(address, function (status, result) {
        if (status === 'complete' && result.regeocode) {
          var address = result.regeocode.formattedAddress
          resolve(address)
        } else {
          console.error('根据地址查询位置失败');
        }
      });
    })

  })

}

export async function getLngLat(address,type ='') {
  return new Promise((resolve) => {
    var AMap = window.AMap
    //构造路线导航类
    AMap.plugin('AMap.Geocoder', function () {
      var geocoder = new AMap.Geocoder({
        // city: "010", //城市设为北京，默认：“全国”
      })
      geocoder.getLocation(address, function (status, result) {
        if (status === 'complete' && result.geocodes.length) {
          var lnglat = result.geocodes[0].location
          resolve(lnglat)
        } else {
          switch(type) {
            case 'from':
              message.error('起点位置经纬度不存在，请点击输入框重新选择起点位置');
              break;
            case 'to':
              message.error('终点位置经纬度不存在，请点击输入框重新选择终点位置');
              break;
            default:
              message.error('根据地址位置标点失败');
              break;
          }
        }
      })
    })

  })

}

export async function tra(path,content) {
  var AMap = window.AMap
  var map = new AMap.Map("container", {
    // resizeEnable: true,

    // center:lnglat.length != 0?[lnglat[0]*1,lnglat[1]*1] :[116.397428, 39.90923],//地图中心点
    zoom: 15, //地图显示的缩放级别
    scrollWheel: true
  });
  var polyline = new AMap.Polyline({
    path: path,
    isOutline: true,
    outlineColor: '#ffeeff',
    borderWeight: 3,
    strokeColor: "#3366FF",
    strokeOpacity: 1,
    strokeWeight: 6,
    // 折线样式还支持 'dashed'
    strokeStyle: "solid",
    // strokeStyle是dashed时有效
    strokeDasharray: [10, 5],
    lineJoin: 'round',
    lineCap: 'round',
    zIndex: 50,
  })
  if(content != undefined) {
    let start = content.startPoint?content.startPoint.split(',') :await getLngLat(content.origin)
    let end = content.endPoint?content.endPoint.split(',') :await getLngLat(content.terminus)
    if(!content.startPoint && !content.endPoint) {
      start = Object.values(_.pick(start, 'lng', 'lat'))
      end = Object.values(_.pick(end, 'lng', 'lat'))
    }
    var startMarker = new AMap.Marker({
      position:  start,
      icon: new AMap.Icon({
        image: 'https://webapi.amap.com/theme/v1.3/markers/n/start.png',
      }),
      map: map
  
    })
    var endMarker = new AMap.Marker({
      position: end,
      icon: new AMap.Icon({
        image: 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
      }),
      map: map
    })
  } 
  map.add([polyline]);
  map.setFitView();
}


export function price(text) {
  if(text){
    return <span style={{ color: 'red' }}>￥ {(text*1).toFixed(2)}</span>
  } else {
    return <span style={{ color: 'red' }}>￥ 0.00</span>
  }
}



export const col = [
  {
    title: '运单号',
    dataIndex: 'waybillId',
    fixed: 'left',
    width: 200
  },
  {
    title: '车牌号',
    width: 200,
    dataIndex: 'plateNumber',
  },
  {
    title: '总金额',
    dataIndex: 'amount',
    width: 200,
    render: (text, record) => <span style={{ color: 'red' }}>￥ {(text * 1).toFixed(2)}</span>
  },
  {
    title: '运费',
    dataIndex: 'price',
    width: 100,
  },
  {
    title: '平台服务费',
    dataIndex: 'serviceFee',
    width: 150,
  },
  {
    title: '司机',
    width: 100,
    dataIndex: 'driverName',
  },
  {
    title: '司机电话',
    width: 200,
    dataIndex: 'driverPhone',
  },
  {
    title: '提交时间',
    dataIndex: 'createTime',
    width: 200,
    render: (text) => formatTime(text, TimeFormats.YMDHM)
  },
  {
    title: '付款人',
    width: 300,
    dataIndex: 'companyName',
  },
  {
    title: '付款账号',
    width: 200,
    dataIndex: 'bankCardNo',
    // render: (text) => formatTime(text, TimeFormats.YMDHM)
  },
  {
    title: '起止地',
    width: 600,
    dataIndex: 'fromToAddress',
  },
  {
    title: '起送时间',
    width: 200,
    dataIndex: 'startTime',
  },
  {
    title: '送达时间',
    width: 200,
    dataIndex: 'endTime',
  },
  {
    title: '货物',
    width: 200,
    dataIndex: 'cargoName',
  },
  {
    title: '装车数据',
    width: 100,
    dataIndex: 'weight',
  },
  {
    title: '发货客户',
    dataIndex: 'consignor',
    width: 100,
  },
  {
    title: '收货客户',
    dataIndex: 'consignee',
    width: 100,
  },

  {
    title: '备注',
    dataIndex: 'remark',
    width: 100,
    render: (text) => {
      if (text == null) {
        return '无'
      } else {
        return text
      }

    }
  },
  {
    title: '笔数',
    width: 100,
    fixed: 'right',
    render: (text) => <span>1</span>
  },
  
]
const str1 = '****'
const str2 = '***********'
const str3 = '*****'

export function phoneXin(phone) {
  return phone && phone.slice(0,3) + str1 + phone.slice(-4)
}

export function idCardXin(phone) {
  return phone.slice(0,3) + str2 + phone.slice(-4)
}

export function carXin(phone) {
  return phone.slice(0,3) + str3 + phone.slice(-4)
}