import React, { useState, useEffect } from "react";
import {Form,Input,Button,Layout,Tabs,Space,Select,message,Row,Col,DatePicker,Modal,Radio,Image,Divider } from "antd"
import CommonSearch from "components/Search"
import styled from "styled-components";
import {SearchOutlined} from "@ant-design/icons";
import {Actions,CustomTooltip,TabCard,Title,KeyValue} from "components/common"
import {formatTime,TimeFormats,halfScreenHeight,noData,regular} from "common/Constant"
import axios from "axios"
import useStores from "stores/StoreContext"
import MobxTable,{mergeParams} from "components/List/MobxTable"
import { useLocation, useParams, useHistory } from "react-router-dom";
import "../index.less"
import _ from "lodash"

export function AuditText(props){

    const text = () => {
        if(!props.auditState){
            return <span style={{color:'red'}}>未认证</span>
        }
        if(props.auditState == 'passed'){
            return <span style={{color:'green'}}>已认证</span>
        }
        if(props.auditState == 'auditing'){
            return <span style={{color:'blue'}}>认证中</span>
        }
        if(props.auditState == 'not_pass'){
            return <span style={{color:'red'}}>驳回</span>
        }
    }

    return (
        <span>
            {text()}
        </span>
    )
}

const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };
const {TabPane} = Tabs
const { RangePicker } = DatePicker;
const searchProps = [
    'startDate',
    'endDate',
    'type',
    'handle',
    'searchField'
]
export default function(){
    const [form] = Form.useForm();
    let history = useHistory();
    const {profileStore} = useStores()
    const [shipper,setShipper] = React.useState({})
    const [audit,setAudit] = React.useState({})
    
    let auditState = ''
    React.useEffect(() => {
        axios.get(`/api/shipper/detail`)
        .then(res => {
            setShipper(res.data)
        })
        axios.get(`/api/audit`)
            .then(res => {
                setAudit(res.data)
            })
        setInterval(() => {
            axios.get(`/api/audit`)
            .then(res => {
                setAudit(res.data)
                if(auditState != res.data.auditState){
                    profileStore.get()
                }
                auditState = res.data.auditState
            })
        },2000)
        
    },[])
    
    return (
        <Layout className='full setting' style={{paddingTop:'0px'}}>
             <Tabs >
             <TabPane key='1' tab={<TabCard text='修改密码'/>}>
                    <Row>
                        <Col span={8}>
                            <Form 
                            {...layout}
                            style={{padding:'30px'}}
                            form={form}>
                                <Form.Item
                                name="oldPassword"
                                label='输入原密码'
                                validateFirst={true}
                                rules={[
                                        { required: true, message: "输入原密码" },
                                        {pattern:regular.password,message:'请输入6-12位密码'}]}>
                                    <Input.Password placeholder="输入原密码"/>
                                </Form.Item>   
                                <Form.Item
                                name="newPassword"
                                label='输入新密码'
                                hasFeedback
                                validateFirst={true}
                                rules={[
                                        { required: true, message: "输入新密码" },
                                        {pattern:regular.password,message:'请输入6-12位密码'}]}>
                                    <Input.Password placeholder="输入新密码"/>
                                </Form.Item>  
                                <Form.Item
                                name="againNewPassword"
                                label='重新输入新密码'
                                hasFeedback
                                validateFirst={true}
                                rules={[
                                    { required: true, message: "重新输入新密码" },
                                    {pattern:regular.password,message:'请输入6-12位密码'},
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                          if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject('密码不一致');
                                        },
                                    })
                                ]}
                                >
                                    <Input.Password placeholder="重新输入新密码"/>
                                </Form.Item>  
                            </Form>
                            <Row>
                                <Col span={10}></Col>
                                <Col>
                                    <Button type="primary" 
                                    style={{width:'100px'}}
                                    onClick={() =>{
                                        form.validateFields()
                                        .then(values => {
                                            axios.post(`/api/shipper/modifyPw`,values)
                                            .then(res => {
                                                message.success('修改密码成功')
                                                form.resetFields()
                                                axios.post(`/api/profile/logout`)
                                                .then(res => {
                                                    history.push('/login')
                                                })
                                            })
                                        })
                                    }}>保存</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane key='2' tab={<TabCard text='企业信息'/>}>
                    {
                        (audit.auditState=='passed'||audit.auditState=='auditing')?
                        <CompanyInfo shipper={shipper} audit={audit}/>
                        :
                        <div style={{padding:'30px'}} >
                            <div style={{color:'black',fontSize:'16px'}}>
                                基本信息
                            </div>
                            <Row style={{lineHeight:5,paddingLeft:'20px'}}>
                                <Col span={6}>
                                    <KeyValue value='账号' text={shipper.accountName}/>
                                </Col>
                                <Col span={6}>
                                    <KeyValue value='入驻时间' text={formatTime(shipper.createTime,TimeFormats.YMDHM)}/>
                                </Col>
                                <Col span={6}>
                                    <KeyValue value='认证状态' text={<AuditText auditState={audit.auditState}/>}/>
                                </Col>
                                <Col span={6}>
                                    <KeyValue value='归属业务' text={shipper.supportName}/>
                                </Col>
                            </Row>
                            <Row style={{paddingLeft:'20px'}} align='middle'>
                                <Button type='primary' disabled={audit.auditState=='auditing'}
                                onClick={() => history.push('/setting/audit')}>提交认证</Button>
                                <span style={{marginLeft:'20px',color:'#1989FA'}}>提交企业认证，获得更多平台权限</span>
                            </Row>
                        </div>
                    }
                    
                </TabPane>
                <TabPane key='3' tab={<TabCard text='意见反馈'/>}>
                    <FeedBack/>
                </TabPane>
             </Tabs>
             
        </Layout>
    )
}

function FeedBack(props){
    const {questionStore} = useStores()
    const [form] = Form.useForm();
    const [createForm] = Form.useForm()
    const [visible,setVisible] = React.useState(false)
    const [handleVisible,setHandleVisible] = React.useState(false)
    const [question,setQuestion] = React.useState({})

    const detailFeedBack = () => {
        if(question.handleTime){
            if(!question.satisfactionTime){
                return (
                    <Row style={{marginTop:'8px',lineHeight:3}}>
                        <div style={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            width:'100%'
                        }}>
                            <Form
                            form={createForm}>
                                <Form.Item
                                initialValue={'satisfied'}
                                name='satisfaction'
                                label='处理结果满意度调查'>
                                    <Radio.Group 
                                    defaultValue='satisfied'>
                                        <Radio value='satisfied'>满意</Radio>
                                        <Radio value='dissatisfied'>不满意</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Form>
                            <Button type='primary'
                            onClick={() => {
                                createForm.validateFields()
                                .then(values => {
                                    axios.put(`/api/question/${question.id}?satisfaction=${values.satisfaction}`)
                                    .then(res => {
                                        message.success('反馈成功')
                                        setHandleVisible(false)
                                        questionStore.search()
                                    })
                                })
                            }}>确定</Button>
                        </div>
                    </Row>
                )
            }else{
                return (
                    <Space style={{lineHeight:3}}>
                        处理结果满意度 
                        {
                            question.satisfaction==='satisfied'?
                            <span style={{color:'green'}}>满意</span>
                            :
                            <span style={{color:'red'}}>不满意</span>
                        }
                    </Space>
                )
            }
        }
        
    }
    return (
        <div>
            <CommonSearch title={
                <Button type='primary'
                onClick={() => {
                    setVisible(true)
                }}>新建反馈</Button>
            }>
                <SearchForm 
                onValuesChange={(changedValues,allValues) => {
                    questionStore.searchParams = mergeParams(questionStore.searchParams,allValues,searchProps)
                    questionStore.search()
                }}/>
            </CommonSearch>
            <MobxTable
                rowKey="id"
                store={questionStore}
                columns={[
                    {
                        title:'日期',
                        dataIndex:'createTime',
                        render:(text) => formatTime(text)
                    },
                    {
                        title:'问题描述',
                        dataIndex:'submitContent',
                        ellipsis:true
                    },
                    {
                        title:'问题类型',
                        dataIndex:'type',
                        render:(text) => text=='consulation'?'咨询':'投诉'
                    },
                    {
                        title:'处理进度',
                        dataIndex:'handleTime',
                        render:(text) => text?<span style={{color:'green'}}>已处理</span>:<span style={{color:'red'}}>未处理</span>
                    },
                    {
                        title:'处理日期',
                        dataIndex:'handleTime',
                        render:(text) => text?formatTime(text):'--',
                        ellipsis:true
                    },
                    {
                        title:'处理回复',
                        dataIndex:'handleContent',
                        ellipsis:true,
                        render:(text) => noData(text)
                    },
                    {
                        title:'操作',
                        render:(text,record) => <Actions items = {[
                            {
                                text:'反馈详情',
                                onClick:() => {
                                    setQuestion(record)
                                    setHandleVisible(true)
                                }
                            }
                        ]}/>
                    }
                ]}/>
            <Modal title='新增反馈'
             onCancel={() => setVisible(false)}
             onOk={() => {
                 form.validateFields()
                 .then(values => {
                     axios.post(`/api/question`,values)
                     .then(Res => {
                        setVisible(false)
                        form.resetFields()
                        message.success('添加问题反馈成功')
                        questionStore.search()
                     })
                 })
             }}
             visible={visible}>
                <Form
                form={form}>
                    <Form.Item
                    label='问题类型'
                    name="type"
                    rules={[{ required: true, message: "请选择问题类型" }]}>
                        <Radio.Group>
                            <Radio value="consulation">
                                咨询
                            </Radio>
                            <Radio value="complaint">
                                投诉
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                    label='内容描述'
                    name="submitContent"
                    rules={[{ required: true, message: "请填写问题描述" }]}>
                        <Input.TextArea placeholder='问题描述' 
                        maxLength={200}
                        autoSize={{ minRows: 5, maxRows: 10 }}/>
                    </Form.Item>
                </Form>
             </Modal>
             <Modal
                visible={handleVisible}
                footer={null}
                width='600px'
                onCancel={() => setHandleVisible(false)}
                title='反馈详情'>
                    <Row>
                        <Col span={12}>
                            <Item value='提交时间' text={formatTime(question.createTime)}/>
                        </Col>
                        <Col span={12}>
                            <Item value='问题类型' text={question.type==='consulation'?'咨询':'投诉'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Item value='内容描述' text={noData(question.submitContent)}/>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={12}>
                            <Item value='处理时间' text={question.handleTime?formatTime(question.handleTime):'--'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Item value='处理反馈' text={noData(question.handleContent)}/>
                    </Row>
                    {detailFeedBack()}
                </Modal>
        </div>
    )
}

function CompanyInfo(props){
    const {shipper,audit} = props
    const margin = {marginTop:'10px',marginBottom:'10px'}
    return (
        <Layout className='full' style={{height:`${halfScreenHeight*1.7}px`}}>
            <Title text='基本信息'/>
            <Row>
                <Col span={1}></Col>
                <Col span={19}>
                    <Row style={margin}>
                        <KeyValue value={'企业名称'} text={shipper.companyName}/>
                    </Row>
                    <Row style={margin}>
                        <Col span={8}>
                            <KeyValue value={'入驻时间'} text={formatTime(shipper.createTime)}/>
                        </Col>
                        <Col span={8}>
                            <KeyValue value={'归属业务'} text={shipper.supportName}/>
                        </Col>
                        <Col span={8}>
                            <KeyValue value={'认证状态'} text={<AuditText auditState={audit.auditState}/>}/>
                        </Col>
                    </Row>
                    <Row style={margin}>
                        <Col span={8}>
                            <KeyValue value={'统一社会信用代码'} text={shipper.unifiedSocialCreditCode}/>
                        </Col>
                        <Col span={8}>
                            <KeyValue value={'固定电话'} text={shipper.companyPhone}/>
                        </Col>
                        <Col span={8} >
                           <CustomTooltip title={shipper.companyAddress}>
                           <KeyValue value={'企业地址'} text={shipper.companyAddress}/>
                          </CustomTooltip>
                        </Col>
                    </Row>
                    <Row style={margin}>
                        <Col span={8}>
                            <KeyValue value={'传真号码'} text={shipper.companyFaxNo}/>
                        </Col>
                        <Col span={8}>
                            <KeyValue value={'银行卡号'} text={shipper.bankCardNo}/>
                        </Col>
                        <Col span={8}>
                            <KeyValue value={'开户银行'} text={shipper.bankName}/>
                        </Col>
                    </Row>
                    <Row style={margin}>
                        <Col span={9}>
                            <AuditImg value='营业执照照片' type='business_license' attachments={audit.certAttachments}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Title text='联系人信息'/>
            <Row>
                <Col span={1}></Col>
                <Col span={18}>
                    <Row>
                        <Col span={8}>
                            <KeyValue value={'联系人'} text={shipper.contractName}/>
                        </Col>
                        <Col span={8}>
                            <KeyValue value={'联系电话'} text={shipper.contractPhone}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Title text='企业法人信息'/>
            <Row>
                <Col span={1}></Col>
                <Col span={18}>
                    <Row style={margin}>
                        <Col span={8}>
                            <KeyValue value={'企业法人'} text={shipper.companyLegalPerson}/>
                        </Col>
                        <Col span={8}>
                            <KeyValue value={'法人身份证号'} text={shipper.idNo}/>
                        </Col>
                        
                    </Row>
                    <Row style={margin}>
                        <Col span={8}>
                            <AuditImg value='身份证正面照' type='identification_front' attachments={audit.certAttachments}/>
                        </Col>
                        <Col span={8}>
                            <AuditImg value='身份证反面照' type='identification_reverse' attachments={audit.certAttachments}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout>
    )
}

function AuditImg(props){
    const content =() => {
        if(props.attachments?.length>0){
            return <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={{color:'#8D8A8A',width:'110px'}}>{props.value}</span>
                        <div style={{marginLeft:'10px'}}>
                            <Image 
                            src={`/file/${_.find(props.attachments,item=>item.type==props.type).fileName}`} 
                            height={90}
                            />
                        </div>
                    </div>
        }
        return <div></div>
    }

    return content()
}

function SearchForm(props){
    const [form] = Form.useForm();
    const getFormItems = () => {
        let items = []
        items.push(
            <Form.Item
            label='处理进度'
            name="handle">
                <Select style={{width:'100px'}} allowClear>
                    <Select.Option value={false}>
                        未处理
                    </Select.Option>
                    <Select.Option value={true}>
                        已处理
                    </Select.Option>
                </Select>
            </Form.Item>
        )
        items.push(
            <Form.Item
            label='问题类型'
            name="type">
                <Select style={{width:'100px'}} allowClear>
                    <Select.Option value={'consulation'}>
                        咨询
                    </Select.Option>
                    <Select.Option value={'complaint'}>
                        投诉
                    </Select.Option>
                </Select>
            </Form.Item>
        )
        items.push(
            <Form.Item
            label='日期'
            name="date"
            getValueFromEvent={(e)=>{
                if(e){
                    form.setFieldsValue({startDate:e[0].valueOf()})
                    form.setFieldsValue({endDate:e[1].valueOf()})
                }else{
                    form.setFieldsValue({startDate:null})
                    form.setFieldsValue({endDate:null})
                }
            }}>
                <RangePicker allowClear/>
            </Form.Item>
        )
        items.push(
            <Form.Item
            name="searchField">
                <Input suffix={<SearchOutlined />} style={{width:'200px'}} allowClear></Input>
            </Form.Item>
        )
        return items
        
    }
    return (
        <Form colon={false} hideRequiredMark={true}
        form={form}
        style={{display:'flex',flexDirection:'row'}}
        {...props}>
            <Form.Item noStyle name='startDate'/>
            <Form.Item noStyle name='endDate'/>
            <Space>
                {getFormItems()}
            </Space>
        </Form>
    )
}

export function Item(props){
    return (
        <Space style={{alignItems:'flex-start',lineHeight:3}}
            {...props}>
            <div style={{
                whiteSpace:'nowrap'
            }}>
                {props.value}
            </div>
            <div style={{
                wordBreak:'break-all'
            }} 
            title={props.title||props.text}>
                {props.text}
            </div>
        </Space>
    )
}