import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {Divider,Col,Tooltip} from "antd"
import "../App.less"
import _ from "lodash"

export function Actions(props){
   return ( <div style={_.merge({width:'100%',display:'flex',alignItems:'center'},props.style)}>
                {
                    props.items.map(item => {
                        if(typeof item == 'function'){
                            return item()
                        }
                        return <a style={{
                                    textDecoration:'underline',
                                    color:item.color,
                                    marginLeft:'10px'}} 
                                    {...item} >{item.text}
                                </a>
                    })
                }
            </div>)
    
}

export function Title(props){
    return <div {...props} style={{marginTop:'10px',marginBottom:'5px',marginLeft:'10px'}}>
                <span style={{fontSize:'16px',marginLeft:'15px'}}>
                    {props.text}
                </span>
                <Divider style={{marginTop:'10px'}}/>
            </div>
}

export function TabCard(props){
    return <div style={{
                width:'100px',
                height:'30px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'}}>
                {props.text}
            </div>

}

export function CustomTooltip(props){
    return <div style={{width:'100%',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>
                <Tooltip {...props}>
                    {props.children}
                </Tooltip>
            </div>
}

export function KeyValue(props){
    return <div style={{
                display:'flex',
            }}>
                <div style={{color:'#8D8A8A',whiteSpace:'nowrap'}}>{props.value}</div>
                ：
                <span style={{color:'#393838'}}>{props.text}</span>
            </div>
}