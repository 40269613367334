import React, {Component} from 'react';
import {Button, Form, Input, Icon, Select, Row, Col, message} from 'antd'
import CommonMap from 'components/Map'
import axios from "axios"
import {GAODE_KEY} from "common/Constant"
import _ from "lodash"

export default function(props){

    const [addresses,setAddresses] = React.useState([])
    const [center,setCenter] = React.useState()
    React.useEffect(() => {
        return () => {
            props.form.resetFields()
            setCenter('')
        }
    },[])

    return (
        <div>
            <Form
            form={props.form}>
                <Form.Item
                name='address'
                label='选择地址'
                rules={[
                    {
                      required: true,
                      message: `请输入地址`,
                    },
                  ]}>
                    <Select
                    placeholder='选择地址'
                    mode="combobox"
                    autoFocus={true}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    showSearch
                    style={{ width: '100%' }}
                    onSelect={(value,option) => {
                        if (!(option.props.item.location && option.props.item.location.length > 0)) {
                            message.warning('此选项无效')
                            return;
                        }
                        const latLng = option.props.item.location.split(',')
                        props.form.setFieldsValue({
                            address: value,
                            lat: latLng[1],
                            lng: latLng[0],
                            areacode: option.props.item.adcode
                        })
                        setCenter([latLng[1],latLng[0]])
                    }}
                    onSearch={
                        (value) => {
                            axios.get(`//restapi.amap.com/v3/assistant/inputtips?output=json&keywords=${value}&key=${GAODE_KEY}&datatype=all`)
                            .then(res => {
                                if(res.data.infocode === "10000"){
                                   res.data.tips.forEach(ele=>{
                                        if(typeof ele.location=='object'){
                                            ele.disabled = true
                                        }
                                   })
                                    setAddresses(res.data?.tips)
                                }else{
                                    message.warning(`高德接口不支持此参数，请重新输入`)
                                }
                                // const v = res.data.count>0?res.data.tips[0]:{}
                                // const location = v.location && v.location.length > 0 && v.location.split(',')
                            })
                        }
                    }>
                        {
                            addresses.map((item) =>{
                                const value = item.district+item.name+item.address
                                return <Select.Option 
                                        value={value}
                                        disabled={item.disabled} 
                                        item={item}>
                                            {value}
                                        </Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name='lat' noStyle/>
                <Form.Item name='lng' noStyle/>
                <Form.Item name='areacode' noStyle/>
            </Form>
            <CommonMap center={center||[props.form.getFieldValue('lat'),props.form.getFieldValue('lng')]}/>
        </div>
    )
}
