import React, { useState, useEffect } from "react";
import { Layout, Tabs, Form, Space, Input, Select, DatePicker, Button, message, Modal, Table, Divider, Row, Col, InputNumber } from "antd"
import qs from "qs"
import { TabCard, KeyValue } from "components/common"
import useStores from "stores/StoreContext"
import { useParams, useHistory, useLocation } from "react-router-dom";
import CommonSearch from "components/Search"
import _, { filter } from "lodash"
import { SearchOutline, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { vehicleModels, vehicleLengths, formatTime, noData, regular } from "common/Constant"
import MobxTable, { mergeParams } from "components/List/MobxTable"
import { Actions } from "components/common"
import { session } from "common/session"
// import  vehicle_code  from "common/vehicle_code.json"
import axios from "axios"
import { price } from "utils";
const { TabPane } = Tabs
const { RangePicker } = DatePicker;
const cols = [
  { title: '', dataIndex: 'v1', width: '15%', className: 'cell', align: 'right' },
  { title: '', dataIndex: 'v2', width: '35%' },
  { title: '', dataIndex: 'v3', width: '15%', className: 'cell', align: 'right' },
  { title: '', dataIndex: 'v4' },
]
const commonCol = [

  {
    title: '发票抬头',
    ellipsis: true,
    dataIndex: 'sendName'
  },
  {
    title: '货物或应税劳务、服务名称',
    ellipsis: true,
    dataIndex: 'remark'
  },
  {
    title: '总金额',
    ellipsis: true,
    dataIndex: 'price',
    render: (text) =>price(text),
  },
  {
    title: '运费',
    ellipsis: true,
    dataIndex: 'waybillAmount',
    render: (text) => price(text)
  },
  {
    title: '服务费',
    ellipsis: true,
    dataIndex: 'actServiceFee',
    render: (text) => price(text),
  },
  
]

const searchProps = [
  'submitStartDate',
  'submitEndDate',
  'paid',
  'applyStartDate',
  'applyEndDate',
  'confirmStartDate',
  'confirmEndDate',
  'searchField'
]

function Title(props) {
  React.useEffect(() => {
    setItems(props.items)

  }, [props.items])
  const { invoiceStore } = useStores()
  const [items, setItems] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [remark, setRemark] = React.useState([])
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      justifyContent: 'space-between',
      width: '700px'
    }}>
      <Space size='large'>
        <div style={{ color: '#8D8D8E' }}>已选：{items.length}笔</div>
        <div style={{ color: '#101010' }}>合计：
          <span style={{ color: '#FF4A28' }}>
            ￥{(_.sumBy(items, item => item.billPrice) || 0.0).toFixed(2)}
          </span>
        </div>
      </Space>
      <Space size='large'>
        <Button type='primary'
          onClick={() => {
            if (items.length == 0) {
              message.warning('请选择最少一笔账单')
              return
            }
            setVisible(true)
            axios.post('/api/invoice/remark', [props.items[0].id]).then(
              res => {
                if (res.data.code == 100000) {
                  let list = res.data.data.map((item,index) => {
                      if (index<3) {
                        return item + '\n'
                      } else {
                        // let name =vehicle_code.find(items => items.code == item.substring((item.indexOf('：') +1)))?.name + '\n'
                        // return `车型：${name}`  
                      }
                  }
                  )
                  setRemark(list.join(''))

                }
              }
            )
          }}>申请开票</Button>
        <Space>
          <ExclamationCircleOutlined style={{ color: '#1890FF' }} />
          <span>选择多张对账单须选择相同付款账户申请开票</span>
        </Space>
      </Space>
      <Modal
        title='申请开票'
        visible={visible}
        width='900px'
        onCancel={() => setVisible(false)}
        footer={null}>
        <Table
          pagination={false}
          showHeader={false}
          columns={cols}
          dataSource={[
            {
              v1: '发票抬头',
              v2: items[0]?.sendName,
              v3: '纳税人识别号',
              v4: items[0]?.unifiedSocialCreditCode
            },
            {
              v1: '单位地址',
              v2: items[0]?.companyAddress,
              v3: '固定电话',
              v4: items[0]?.companyPhone,
            },
            {
              v1: '开户银行',
              v2: items[0]?.sendBankName,
              v3: '银行账户',
              v4: items[0]?.sendBankCardNo,
            },
            {
              v1: '笔数',
              v2: items.length,
              v3: '开票金额',
              v4: <span style={{ color: 'red' }}>{(_.sumBy(items, item => item.billPrice) || 0.0).toFixed(2)}</span>,
            },
          ]} />
        <Divider />
        {
          items.map(item => {
            return <Table
              style={{ marginBottom: '15px' }}
              pagination={false}
              showHeader={false}
              columns={cols}
              dataSource={[
                {
                  v1: '水单号',
                  v2: item?.id,
                  v3: '提交时间',
                  v4: formatTime(item?.createTime)
                },
                {
                  v1: '事项',
                  v2: item?.remark,
                  v3: '金额',
                  v4: item?.billPrice.toFixed(2),
                },
              ]} />
          })
        }
        <div style={{
          display: 'flex',
          alignItems:'center',
          justifyContent:'space-around'
        }}>
          <div style={{width:50}}>备注:</div>
          <Input.TextArea
            rows={5}
            onChange={(e) => {setRemark(e.target.value);console.log(e.target.value);}}
            value={remark} 
            style={{flex:1}}/>
        </div>
        <div style={{marginTop:10,textAlign:'center'}}>
        <Space>
          <Button
            style={{ width: '100px' }}
            onClick={() => setVisible(false)}>取消</Button>
          <Button
            style={{ width: '100px' }}
            type='primary'
            onClick={() => {
              let list = remark.split('\n')
              list.pop()
              axios.post(`/api/invoice?remark=${list}`, items.map(item => item.id))
                .then(res => {
                  props.reset()
                  setVisible(false)
                  invoiceStore.searchUninvoice()
                  setItems([])
                  setRemark('')
                  message.success('申请开票成功')
                })
            }} >确认提交</Button>
        </Space>
        </div>
        

      </Modal>
    </div>
  )
}

export default function () {
  const finance = false//是否为财务角色
  const [form] = Form.useForm()
  const [tabPaneKey, setTabPaneKey] = React.useState(finance ? 'pending' : 'uninvoice')
  const { invoiceStore } = useStores()
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([])
  const [selectedRow, setSelectedRows] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [invoice, setInvoice] = React.useState({})
  const [billAudits, setBillAudits] = React.useState([])
  let location = useLocation()
  let history = useHistory();

  const reset = () => {
    setSelectedRowKeys([])
    setSelectedRows([])
  }

  const getTableData = () => {
    let items = [
      {
        v1: '发票抬头',
        v2: invoice?.sendName,
        v3: '纳税人识别号',
        v4: invoice?.unifiedSocialCreditCode
      },
      {
        v1: '单位地址',
        v2: invoice?.companyAddress,
        v3: '固定电话',
        v4: invoice?.companyPhone,
      },
      {
        v1: '开户银行',
        v2: invoice?.sendBankName,
        v3: '银行账户',
        v4: invoice?.sendBankCardNo,
      },
      {
        v1: '笔数',
        v2: billAudits.length,
        v3: '开票金额',
        v4: <span style={{ color: 'red' }}>{invoice.price?.toFixed(2)}</span>,
      },
    ]
    if (invoice.invoiceNo) {
      items.unshift(
        {
          v1: '发票号',
          v2: invoice?.invoiceNo,
          v3: '开票时间',
          v4: formatTime(invoice?.confirmedTime)
        }
      )
    }
    return items
  }
  return (
    <Layout className='full' style={{ paddingTop: '0px' }}>
      <Tabs activeKey={qs.parse(location.search.slice(1)).tabPaneKey || tabPaneKey}
        onChange={(key) => {
          history.push(`/invoice?tabPaneKey=${key}`)
          setTabPaneKey(key)
          if (key == 'uninvoice') {
            invoiceStore.searchUninvoice()
          }
          if (key == 'pending') {
            invoiceStore.searchPending()
          }
          if (key == 'confirmed') {
            invoiceStore.searchConfirmed()
          }
        }}>
        {
          !finance &&
          <TabPane key='uninvoice' tab={<TabCard text='未开票' />}>
            <CommonSearch title={<Title items={selectedRow} reset={reset} />}>
              <SearchForm type='uninvoice'
                onValuesChange={(changedValues, allValues) => {
                  invoiceStore.unInvoiceParams.applyInvoice = false
                  invoiceStore.unInvoiceParams.billAuditState = 'approved'
                  invoiceStore.unInvoiceParams = mergeParams(invoiceStore.unInvoiceParams, allValues, searchProps, ['applyInvoice', 'billAuditState'])
                  invoiceStore.searchUninvoice()
                }} />
            </CommonSearch>
            <MobxTable
              rowKey="id"
              rowSelection={{
                preserveSelectedRowKeys: true,
                selectedRowKeys,
                onChange: (keys, selectedRows) => {
                  setSelectedRowKeys(keys)
                  setSelectedRows(selectedRows)
                }
              }}
              store={invoiceStore}
              params='unInvoiceParams'
              result='unInvoiceResult'
              method='searchUninvoice'
              scroll={{y: 500 }}
              columns={[
                {
                  title: '水单号',
                  ellipsis: true,
                  dataIndex: 'id'
                },
                {
                  title: '提交时间',
                  dataIndex: 'createTime',
                  render: (text) => formatTime(text),
                  ellipsis: true,
                },
                {
                  title: '付款人',
                  ellipsis: true,
                  dataIndex: 'sendName'
                },
                {
                  title: '付款账户',
                  ellipsis: true,
                  dataIndex: 'sendBankCardNo'
                },
                {
                  title: '事项',
                  ellipsis: true,
                  dataIndex: 'remark'
                },
                {
                  title: '笔数',
                  dataIndex: 'billCount'
                },
                {
                  title: '总金额',
                  ellipsis: true,
                  dataIndex: 'billPrice',
                  render: (text) => price(text),
                },
                {
                  title: '运费',
                  ellipsis: true,
                  dataIndex: 'waybillAmount',
                  render: (text) => price(text),
                },
                {
                  title: '服务费',
                  ellipsis: true,
                  dataIndex: 'actServiceFee',
                  render: (text) => price(text)
                },
                {
                  title: '支付状态',
                  ellipsis: true,
                  dataIndex: 'payTime',
                  render: (text) => text ? '支付成功' : '未支付'
                },
                {
                  title: '操作',
                  render: (text, record) => <Actions items={[
                    {
                      text: '账单列表',
                      onClick: () => history.push(`/inDetail/${record.id}?state=uninvoice`)
                    }
                  ]} />
                }
              ]} />
          </TabPane>
        }
        <TabPane key='pending' tab={<TabCard text={finance ? '待开发票' : '已申请'} />}>
          <CommonSearch title='开票列表'>
            <SearchForm type='pending'
              onValuesChange={(changedValues, allValues) => {
                invoiceStore.pendingParams.state = 'pending'
                invoiceStore.pendingParams = mergeParams(invoiceStore.pendingParams, allValues, searchProps, ['state'])
                invoiceStore.searchPending()
              }} />
          </CommonSearch>
          <MobxTable
            rowKey="id"
            params='pendingParams'
            result='pendingResult'
            method='searchPending'
            scroll={{y: 500 }}
            store={invoiceStore}
            columns={[
              {
                title: '申请时间',
                dataIndex: 'createTime',
                render: (text) => formatTime(text),
                ellipsis: true,
              },
              ...commonCol,
              {
                title: '操作',
                render: (text, record) => <Actions items={[
                  {
                    text: finance ? '确认开票' : '开票明细',
                    onClick: () => {
                      axios.get(`/api/billAudit/invoice/${record.id}`)
                        .then(res => {
                          setBillAudits(res.data)
                          setInvoice(record)
                          setVisible(true)
                          form.setFieldsValue({ remark: record.remark })
                        })
                    }
                  }
                ]} />
              }
            ]} />
        </TabPane>
        <TabPane key='confirmed' tab={<TabCard text={finance ? '已开发票' : '已开票'} />}>
          <CommonSearch title='开票列表'>
            <SearchForm type='confirmed'
              onValuesChange={(changedValues, allValues) => {
                invoiceStore.confirmedParams.state = 'confirmed'
                invoiceStore.confirmedParams = mergeParams(invoiceStore.confirmedParams, allValues, searchProps, ['state'])
                invoiceStore.searchConfirmed()
              }} />
          </CommonSearch>
          <MobxTable
            rowKey="id"
            params='confirmedParams'
            result='confirmedResult'
            method='searchConfirmed'
            scroll={{y: 500 }}
            store={invoiceStore}
            columns={[
              {
                title: '发票号',
                dataIndex: 'invoiceNo',
                ellipsis: true,
              },
              {
                title: '开票时间',
                dataIndex: 'confirmedTime',
                render: (text) => formatTime(text),
                ellipsis: true,
              },
              ...commonCol,
              {
                title: '操作',
                render: (text, record) => <Actions items={[
                  {
                    text: '开票明细',
                    onClick: () => {
                      axios.get(`/api/billAudit/invoice/${record.id}`)
                        .then(res => {
                          setBillAudits(res.data)
                          setInvoice(record)
                          setVisible(true)
                        })
                    }
                  }
                ]} />
              }
            ]} />
        </TabPane>
      </Tabs>
      <Modal
        title='发票明细'
        visible={visible}
        width='900px'
        onCancel={() => {
          form.resetFields()
          setVisible(false)
        }}
        footer={null}>
        <Table
          style={{ marginBottom: '15px' }}
          pagination={false}
          showHeader={false}
          columns={cols}
          dataSource={getTableData()} />
        <Divider />
        {
          billAudits.map(item => {
            return <Table
              style={{ marginBottom: '15px' }}
              pagination={false}
              showHeader={false}
              columns={cols}
              dataSource={[
                {
                  v1: '水单编号',
                  v2: item?.id,
                  v3: '提交时间',
                  v4: formatTime(item?.createTime)
                },
                {
                  v1: '事项',
                  v2: item?.remark,
                  v3: '金额',
                  v4: item?.billPrice.toFixed(2),
                },
              ]} />
          })
        }
        <Space size='large'>
          {
            !invoice.invoiceNo && <KeyValue value='申请时间' text={formatTime(invoice.createTime)} />
          }
          {
            !(finance && tabPaneKey == 'pending')
            &&
            <KeyValue value='备注' text={invoice.remark} />
          }

        </Space>
        {
          (finance && tabPaneKey == 'pending') &&
          <div style={{ marginTop: '10px' }}>
            <Form
              form={form}>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label='备注'
                    name='remark'
                    rules={[{
                      required: true, whitespace: true, message: '请填写备注',
                    }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='发票号'
                    name='invoiceNo'
                    rules={[{
                      required: true, message: '请填写发票号',
                      pattern: regular.invoiceNo, message: '请填写长度为8的数字'
                    }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Space style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <Button
                style={{ width: '100px' }}
                onClick={() => {
                  form.resetFields()
                  setVisible(false)
                }}>取消</Button>
              <Button
                style={{ width: '100px' }}
                type='primary'
                onClick={() => {
                  form.validateFields()
                    .then(values => {
                      axios.post(`/api/invoice/${invoice.id}/${values.invoiceNo}?remark=${values.remark}`)
                        .then(res => {
                          message.success('确认开票成功')
                          setVisible(false)
                          form.resetFields()
                          invoiceStore.searchPending()
                        })
                    })
                }}>确认</Button>
            </Space>
          </div>
        }
      </Modal>
    </Layout>
  )


}

function SearchForm(props) {
  const [form] = Form.useForm();

  const getFormItems = () => {
    let items = []
    if (props.type === 'uninvoice') {
      if (window.innerHeight > 900) {
        items.push(
          <Form.Item
            label='提交时间'
            name="createDate"
            getValueFromEvent={(e) => {
              if (e) {
                form.setFieldsValue({ submitStartDate: e[0].valueOf() })
                form.setFieldsValue({ submitEndDate: e[1].valueOf() })
              } else {
                form.setFieldsValue({ submitStartDate: null })
                form.setFieldsValue({ submitEndDate: null })
              }
            }}>
            <RangePicker allowClear />
          </Form.Item>
        )
      }
      items.push(
        <Form.Item
          label='支付状态'
          name='paid'>
          <Select allowClear style={{ width: '150px' }}>
            <Select.Option value={true}>
              已付款
            </Select.Option>
            <Select.Option value={false}>
              未付款
            </Select.Option>
          </Select>
        </Form.Item>
      )
    }
    if (props.type === 'pending') {
      items.push(
        <Form.Item
          label='申请时间'
          name="applyDate"
          getValueFromEvent={(e) => {
            if (e) {
              form.setFieldsValue({ applyStartDate: e[0].valueOf() })
              form.setFieldsValue({ applyEndDate: e[1].valueOf() })
            } else {
              form.setFieldsValue({ applyStartDate: null })
              form.setFieldsValue({ applyEndDate: null })
            }
          }}>
          <RangePicker allowClear />
        </Form.Item>
      )
    }
    if (props.type === 'confirmed') {
      items.push(
        <Form.Item
          label='开票时间'
          name="confirmDate"
          getValueFromEvent={(e) => {
            if (e) {
              form.setFieldsValue({ confirmStartDate: e[0].valueOf() })
              form.setFieldsValue({ confirmEndDate: e[1].valueOf() })
            } else {
              form.setFieldsValue({ confirmStartDate: null })
              form.setFieldsValue({ confirmEndDate: null })
            }
          }}>
          <RangePicker allowClear />
        </Form.Item>
      )
    }
    items.push(
      <Form.Item
        name="searchField">
        <Input suffix={<SearchOutlined />} style={{ width: '200px' }} allowClear></Input>
      </Form.Item>
    )
    return items
  }
  return (
    <Form colon={false} hideRequiredMark={true}
      form={form}
      style={{ display: 'flex', flexDirection: 'row' }}
      {...props}>
      <Space>
        {getFormItems()}
      </Space>
    </Form>
  )
}