import React, { useState, useEffect } from "react";
import {Upload,Modal,message} from "antd"
import { PlusOutlined } from '@ant-design/icons';


function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

export default function(props){

    const uploadButton = (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">上传</div>
        </div>
      );
    
    const [visible,setVisible] = React.useState(false)
    const [img,setImg] = React.useState('')
    const [fileList,setFileList] = React.useState()

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
          }
          setImg(file.url || file.preview)
          setVisible(true)
    }

    const beforeUpload = (file) => {
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isLt3M) {
        message.error('图片超过3M，无法上传');
      }
      return isLt3M;
    }

    React.useEffect(() => {
      if(props.fileList?.length>0&&props.fileList[props.fileList.length-1].status){
        setFileList(props.fileList)
      }else{
        props.fileList&&props.fileList.pop()
        setFileList(props.fileList)
      }

    },[props.fileList])

    const show = () => {
      if(fileList?.length>=(props.count||1)&&fileList[0].status){
        return null
      }
      return uploadButton
    }

    return (
        <div>
            <Upload 
                listType="picture-card"
                action="/api/file"
                onPreview={handlePreview}
                beforeUpload={beforeUpload}
                {...props}
                fileList={fileList}>
                {show()}
            </Upload>
            <Modal
                visible={visible}
                title={props.title}
                footer={null}
                onCancel={() => setVisible(false)}
                >
                <img  style={{ width: '100%' }} src={img} />
            </Modal>
        </div>
    )
}

export const getValueFromEvent = (e) => {
  if (Array.isArray(e)) {
      return e;
  }
  const {fileList} = e
  if(fileList[fileList.length - 1]?.status){
    return fileList
  }
}