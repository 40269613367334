import { action, observable ,extendObservable} from "mobx";
import axios from "axios";
import _ from "lodash"

export default class ProfileStore{

    profile = observable({})

    get(){
        axios.get(`/api/shipper/detail`)
        .then(res => {
            this.profile = _.merge(this.profile,res.data)
        })
    }

}