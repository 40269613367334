import React, { useState, useEffect } from "react";
import { DatePicker,Form,Table,Layout} from 'antd';
import Report from "components/Report"
import axios from "axios"
import CommonSearch from "components/Search"
import {formatTime,halfScreenHeight,TimeFormats} from "common/Constant"
import moment from "moment"
const { RangePicker } = DatePicker;
let initStartDate = moment().subtract(6, 'days').valueOf()
let initEndDate = moment().valueOf()
export default function(){

   
    const [report,setReport] = React.useState()

    const search = (startDate,endDate) => {
        axios.get(`/api/report?startDate=${startDate}&endDate=${endDate}`)
        .then(res => {
            setReport(res.data)
        })
    }

    React.useEffect(() => {
        search(initStartDate,initEndDate)
    },[])

    return (
            <Layout className='full' style={{paddingTop:'0px'}}>
                <div className={'top'}>
                    <Report datas={report} style={{
                        height: `${halfScreenHeight*0.8}px`
                    }} />
                </div>
                <div className={'bottom'}>
                    <div className={'spaceBetween'} style={{padding:15,paddingRight: 20,paddingBottom:0}}>
                        <CommonSearch title='交易报表'>
                            <SearchForm type='unSign'
                            onValuesChange={(changedValues,allValues) => {
                                search(allValues.startDate,allValues.endDate)
                            }}/>
                        </CommonSearch>
                    </div>
                    <div className={'tab-content'}>
                        <div className={'default-table'}>
                            <Table
                            pagination={{pageSize:window.innerHeight>900?4:2}}
                            dataSource={report}
                            columns={[
                                {
                                    title: '交易日期',
                                    dataIndex: 'date',
                                    width: '20%',
                                    render: text => formatTime(text, TimeFormats.YMD)
                                }, {
                                    title: '发单量',
                                    dataIndex: 'waybillCount',
                                    width: '20%',
                                    render: text => text||0
                                }, {
                                    title: '交易数',
                                    dataIndex: 'signCount',
                                    width: '20%',
                                    render: text => text
                                },
                                {
                                    title: '交易额',
                                    dataIndex: 'signPrice',
                                    width: '20%',
                                    render: text => <span style={{color:'red'}}>￥{text?.toFixed(1)}</span>
                                }
                            ]}/>
                        </div>
                    </div>
                </div>
            </Layout>
    )
}

function SearchForm(props){
    const [form] = Form.useForm();
    return (
        <Form colon={false} hideRequiredMark={true}
        form={form}
        style={{display:'flex',flexDirection:'row'}}
        {...props}>
            <Form.Item
                label='日期'
                name="date"
                getValueFromEvent={(e)=>{
                    if(e){
                        form.setFieldsValue({startDate:e[0].valueOf()})
                        form.setFieldsValue({endDate:e[1].valueOf()})
                    }else{
                        form.setFieldsValue({startDate:initStartDate})
                        form.setFieldsValue({endDate:initEndDate})
                    }
                }}>
                    <RangePicker/>
            </Form.Item>
            <Form.Item noStyle name='startDate'/>
            <Form.Item noStyle name='endDate'/>
        </Form>
    )
}