import { action, observable } from "mobx";
import axios from "axios";

export default class CatalogueStore{
    searchResult = observable({})
    searchParams = {
        'createStartDate':'',
        'createEndDate':''
    }
    search(){
        console.log(this.searchParams);
        axios.post(`/api/billRepair/record`, this.searchParams).then(res => {
            
            this.searchResult.results = res.data.data.results;
            this.searchResult.count = res.data.data.count
          });
    }
}