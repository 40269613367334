import React, { useState, useEffect } from "react";
import {Form,Input,Button,Layout,Divider,InputNumber,Modal,message} from "antd"
import CommonSearch from "components/Search"
import styled from "styled-components";
import {SearchOutlined} from "@ant-design/icons";
import {useQuery} from "common/hooks"
import {Actions} from "components/common"
import axios from "axios"
import useStores from "stores/StoreContext"
import MobxTable,{mergeParams} from "components/List/MobxTable"
import { noData } from "common/Constant";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
let id
export default function (){

    const [visible,setVisible] = React.useState(false)
    const [form] = Form.useForm();
    const {shipmentStore} = useStores()

    return (
        <Layout className='full'>
            <CommonSearch title='货物列表'/>
            <Divider style={{marginTop:'10px'}}/>
            <SearchDiv>
                <Button type='primary' onClick={() => {
                    id=null
                    form.resetFields()
                    setVisible(true)
                }}>新建货物</Button>
                <Input suffix={<SearchOutlined />} style={{width:'300px'}}
                onChange={(e) => {
                    shipmentStore.searchParams = mergeParams(shipmentStore.searchParams,{searchField:e.target.value},['searchField'])
                    shipmentStore.search()
                }}></Input>
            </SearchDiv>
            <MobxTable
            rowKey="id"
            store={shipmentStore}
            columns={[
                {
                  title: "货物名称",
                  dataIndex: "cargoName",
                  ellipsis:true
                },
                {
                  title: "包装方式",
                  dataIndex: "packingManner",
                  ellipsis:true
                },
                {
                  title: "体积（立方米）",
                  dataIndex: "volume",
                  render:(text) => noData(text)
                },
                {
                  title: "重量（吨）",
                  dataIndex: "weight",
                },
                {
                  title: "操作",
                  width: 200,
                  render: (test, record) => (
                      <Actions items={[
                          {
                              text:'删除',
                              color:'red',
                              onClick:() => {
                                  Modal.confirm({
                                      title:"是否删除货物",
                                      onOk:() => {
                                          axios.delete(`/api/shipmentField/${record.id}`)
                                          .then(res => {
                                            message.success('删除成功')
                                            shipmentStore.search()
                                          })
                                      }
                                  })
                              }
                          },
                          {
                            text:'编辑',
                            onClick:() => {
                                id = record.id
                                setVisible(true)
                                form.setFieldsValue({...record})
                            }
                        }
                      ]}/>
                  ),
                },
              ]}
            />
            <Modal 
            visible={visible} 
            title={`${id?'编辑货物':'新建货物'}`}
            onCancel={()=>setVisible(false)}
            onOk={() => {
                form.validateFields()
                .then(values => {
                    if(!id){
                        axios.post(`/api/shipmentField`,values)
                        .then(res => {
                            setVisible(false)
                            message.success('添加货物成功')
                            shipmentStore.search()
                        })
                    }else{
                        axios.post(`/api/shipmentField/${id}`,values)
                        .then(res => {
                            setVisible(false)
                            message.success('修改货物成功')
                            shipmentStore.search()
                        })
                    }
                    
                })
            }}>
                <Form 
                {...layout}
                form={form}>
                    <Form.Item
                    label='货物名称'
                    name="cargoName"
                    rules={[{ required: true, message: "请输入货物名称" }]}>
                        <Input placeholder='填写货物名称' maxLength={20}/>
                    </Form.Item>
                    <Form.Item
                    label='包装方式'
                    name="packingManner"
                    rules={[{ required: true, message: "请输入包装方式" }]}>
                        <Input placeholder='填写包装方式' maxLength={20}/>
                    </Form.Item>
                    <Form.Item
                    label='体积（立方米）'
                    name="volume">
                        <InputNumber 
                        style={{width:'100%'}}
                        precision={1}
                        placeholder='填写体积'
                        maxLength={10}/>
                    </Form.Item>
                    <Form.Item
                    label='重量（吨）'
                    name="weight"
                    rules={[{ required: true, message: "请输入重量" }]}>
                        <InputNumber 
                        style={{width:'100%'}}
                        placeholder='填写重量' 
                        precision={1}
                        maxLength={10}/>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
    )
}

const SearchDiv = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0px 15px 15px 15px
`