import React, { useState, useEffect } from "react";
import {Form,Input,Button,Layout,Tabs,Space,Divider,message,Row,Select,DatePicker,Modal,Radio } from "antd"
import CommonSearch from "components/Search"
import {SearchOutlined} from "@ant-design/icons";
import {Actions} from "components/common"
import useStores from "stores/StoreContext"
import MobxTable,{mergeParams} from "components/List/MobxTable"
import _ from "lodash"
import axios from "axios"
import { formatTime ,TimeFormats} from "common/Constant";

const { RangePicker } = DatePicker;
export default function(){
    const {newsStore} = useStores()
    const [visible,setVisible] = React.useState(false)
    const [news,setNews] = React.useState({})
    return (
        <Layout className='full'>
            <CommonSearch title='新闻通知'>
                <SearchForm type='pending'
                onValuesChange={(changedValues,allValues) => {
                    const searchProps = ['startDate','endDate','readd','searchField']
                    newsStore.searchParams = mergeParams(newsStore.searchParams,allValues,searchProps)
                    newsStore.search()
                }}/>
            </CommonSearch>
            <MobxTable
                rowKey="id"
                store={newsStore}
                columns={[
                    {
                        title:'标题',
                        dataIndex:'title',
                        width:'40%'
                    },
                    {
                        title:'类型',
                        dataIndex:'type',
                        render:(text) =>  text=='news'?'新闻':'通知'
                    },
                    {
                        title:'发布时间',
                        dataIndex:'createTime',
                        render:(text) => formatTime(text,TimeFormats.YMDHM)
                    },
                    {
                        title:'阅读状态',
                        dataIndex:'readd',
                        render:(text) =>  text?'已读':<span style={{color:'red'}}>未读</span>
                    },
                    {
                        title:'操作',
                        render:(text,record) => (
                            <Actions items={[
                                {
                                    text:'查看详情',
                                    onClick:() => {
                                        setNews(record)
                                        setVisible(true)
                                        axios.post(`/api/news/${record.id}`)
                                        .then(res => {
                                            newsStore.search()
                                        })
                                    }
                                }
                            ]}/>
                        )
                    }
                ]}/>
                <Modal
                    visible={visible}
                    footer={null}
                    width='800px'
                    onCancel={() => setVisible(false)}
                    title={
                        <div >
                            <div style={{
                                fontSize:'30px',
                                fontWeight:'bold',
                                lineHeight:1.1
                            }}>{news.title}</div>
                            <Space size='large' style={{marginTop:'20px',color:'#87848B'}}>
                                <span>类型：{news.type=='news'?'新闻':'通知'}</span>
                                <span>发布时间：{formatTime(news.createTime,TimeFormats.YMDHM)}</span>
                            </Space>
                        </div>
                    }>
                    <div 
                    className='news'
                    dangerouslySetInnerHTML={{__html: news.description}}
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                    }}/>
                </Modal>
        </Layout>
    )
}


function SearchForm(props){
    const [form] = Form.useForm();
    return (
        <Form colon={false} hideRequiredMark={true}
        form={form}
        style={{display:'flex',flexDirection:'row'}}
        {...props}>
            <Space>
                <Form.Item
                    label='阅读状态'
                    name="readd"
                    style={{width:'200px'}}>
                    <Select allowClear>
                        <Select.Option value={true}>
                            已读
                        </Select.Option>
                        <Select.Option value={false}>
                            未读
                        </Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label='发布日期'
                    name="date"
                    getValueFromEvent={(e)=>{
                        if(e){
                            form.setFieldsValue({startDate:e[0].valueOf()})
                            form.setFieldsValue({endDate:e[1].valueOf()})
                        }else{
                            form.setFieldsValue({startDate:null})
                            form.setFieldsValue({endDate:null})
                        }
                    }}>
                        <RangePicker allowClear/>
                </Form.Item>
                <Form.Item
                name="searchField">
                    <Input suffix={<SearchOutlined />} style={{width:'200px'}} allowClear></Input>
                </Form.Item>
            </Space>
        </Form>
    )
}