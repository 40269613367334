import React from 'react'
import { Form, Input, Modal, message, Space, Button, Col, Row } from "antd"
import "./index.css"
import axios from 'axios'
export default function ManyChange(props) {
  const [content, setContent] = React.useState('')
  const [tip, setTip] = React.useState(false)
  const [form] = Form.useForm()
  const [subShow,setShow] = React.useState(false)
  const myRef = React.useRef()
  let user = {bankCardNo:'',idCard:'',phone:'',realName:''}
  React.useEffect(() => {
    form.setFieldsValue({...user})
    setContent('')
  },[props.manyVisible])
  function phoneSearch() {
    console.log(myRef.current);
    axios('/api/bill/receiver/' + myRef.current.state.value).then(
      res => {
        if (res.data.code == '100000' && res.data.data !=null) {
          setContent(res.data.data)
          setTip(false)
          setShow(true)
          form.setFieldsValue({
            ...res.data.data
          })
        } else {
          setContent('')
          setTip(true)
          setShow(false)
        }
      }
    )
  }
  function handleOk() {
    let data = form.getFieldsValue(true)
    const { id } = data
    const { billIds,store} = props
    axios.post('api/bill/receiverChange', { id, billIds }).then(
      res => {
        if (res.data.code == 100000) {
          message.success('提交成功')
          myRef.current.state.value= '' 
          props.change(false)
          store.searchPending()
          setShow(false)
      } else {
        message.error('提交失败'+res.data.msg)
      }
    })
  }

  return (
    <Modal
      title='修改收款人'
      visible={props.manyVisible}
      width='700px'
      className="image"
      footer={subShow?[
        <Button type='primary' style={{ width: 80 }} onClick={handleOk}>提交</Button>,
        <Button style={{ width: 80 }} onClick={() => { 
          props.change(false);
          setTip(false)
          myRef.current.state.value= '' 
          setShow(false)
        }}>取消</Button>
      ]:null}
      onCancel={() => {
        props.change(false);
        myRef.current.state.value= ''
        setShow(false)
        setTip(false)}}
    >

      <Row gutter={31} style={{ marginBottom: 10 }}>
        <Col span={14}>
          <Input placeholder='请输入手机号' ref={myRef} />
        </Col>
        <Col span={9}>
          <Button type="primary" onClick={phoneSearch} >查询</Button>
        </Col>

      </Row>

      {content && <Form form={form} labelCol={{ span: 3 }} wrapperCol={{ span: 15 }}>
        <Row >
          <Col span={24}>
            <Form.Item name='bankCardHolder' label='收款人' >
              <Input disabled style={{ color: 'black' }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='phone' label='手机号' >
              <Input disabled style={{ color: 'black' }} />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item name='idCard' label='身份证' >
              <Input disabled style={{ color: 'black' }} />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item name='bankCardNo' label='银行卡'>
              <Input disabled style={{ color: 'black' }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='bankBranch' label='开户行支行'>
              <Input disabled style={{ color: 'black' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>}

      {tip && <div style={{ textAlign: 'center', fontSize: 15, fontWeight: 'bold' }}>
        <span>没有符合条件的数据,请重新输入</span>
      </div>}
    </Modal>
  )
}
