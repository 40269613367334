import React, { useState, useEffect } from "react";
import {Breadcrumb} from "antd"


export default function(props){
    return (
        <div style={{backgroundColor:'white',padding:'10px',display:'flex',width:'100%'}}>
            <div style={{display:'flex',marginRight:'2%',width:'250px'}}>
                <span style={{color:'rgba(0, 0, 0, 0.45)'}}>当前位置：</span>
                <Breadcrumb separator=">" >
                    
                    {
                        props.items.map((item,index) => {
                            return <Breadcrumb.Item href={item.href}>{item.text}</Breadcrumb.Item>
                        })
                    }
                </Breadcrumb>
            </div>
            {props.children}
        </div>
    )
}