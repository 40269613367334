import React, { useState, useEffect } from "react";
import {Form,Input,Button,Layout,Tabs,Space,Select,message,Row,Col,DatePicker,Modal,Tooltip } from "antd"
import CBreadcrumb from "components/CBreadcrumb"
import {KeyValue,Title} from "components/common"
import { formatTime ,halfScreenHeight,regular,noData} from "common/Constant";
import axios from "axios"
import CImage,{getValueFromEvent} from "components/CImage"
import _ from "lodash"
import { useLocation, useParams, useHistory } from "react-router-dom";
import {AuditText} from "./index"

const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };
export default function(){

    const [form] = Form.useForm();
    let history = useHistory();
    const [shipper,setShipper] = React.useState({})
    const [audit,setAudit] = React.useState({})

    const getFileNameByType = (certAttachments,type) => {
        return certAttachments&&_.filter(certAttachments,item => item.type==type)[0].fileName
    }

    React.useEffect(() => {
        axios.get(`/api/shipper/detail`)
        .then(res => {
            setShipper(res.data)
            form.setFieldsValue({...res.data})
        })
        axios.get(`/api/audit`)
        .then(res => {
            setAudit(res.data)
            const {certAttachments} = res.data
            if(getFileNameByType(certAttachments,'business_license')){
                form.setFieldsValue({
                    business_license:[
                        {
                            uid:1,
                            url:'/file/'+getFileNameByType(certAttachments,'business_license'),
                            status:'done',
                            response:getFileNameByType(certAttachments,'business_license')
                        }]
                })
            }
            if(getFileNameByType(certAttachments,'identification_front')){
                form.setFieldsValue({
                    identification_front:[
                        {
                            uid:2,
                            url:'/file/'+getFileNameByType(certAttachments,'identification_front'),
                            status:'done',
                            response:getFileNameByType(certAttachments,'identification_front')
                        }]
                })
            }
            if(getFileNameByType(certAttachments,'identification_reverse')){
                form.setFieldsValue({
                    identification_reverse:[{
                        uid:3,
                        url:'/file/'+getFileNameByType(certAttachments,'identification_reverse'),
                        status:'done',
                        response:getFileNameByType(certAttachments,'identification_reverse')
                    }]
                })
            }
        })
    },[])
    const colSpan = 8
    return <div>
                <CBreadcrumb items={[
                    {
                        text:'企业信息',
                        href:'/setting'
                    },{
                        text:'提交认证',
                        href:''
                    }
                ]}>
                    <div style={{width:'80%'}}>
                        <Row>
                            <Col span={6}>
                                <KeyValue value='入驻时间' text={formatTime(shipper.createTime)}/>
                            </Col>
                            <Col span={4}>
                                <KeyValue value='归属业务' text={shipper.supportName}/>
                            </Col>
                            <Col span={4}>
                                <KeyValue value='认证状态' text={<AuditText auditState={audit.auditState}/>}/>
                            </Col>
                        </Row>
                        <Row>
                            {
                                audit.auditState=='not_pass'&&
                                <Col span={24}>
                                    <KeyValue value='驳回原因' text={<span style={{color:'red'}}>{audit.reason}</span>}/>
                                </Col>
                            }
                        </Row>
                    </div>
                </CBreadcrumb>
                <Layout className='full' style={{height:`${halfScreenHeight*2 - 90}px`}}>
                    <Form {...layout}
                    form={form}>
                        <Title text='基本信息'/>
                        <Row>
                            <Col span={colSpan}>
                                <Form.Item
                                label='企业名称'
                                name="companyName"
                                rules={[
                                    { required: true, message: "请输入企业名称" },
                                    {pattern:regular.companyName,message:'请输入正确的企业名称'}]}>
                                    <Input placeholder='输入企业名称' maxLength={50}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={colSpan}>
                                <Form.Item
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                                label='营业执照照片'
                                name="business_license"
                                rules={[{ required: true, message: "请上传营业执照照片" }]}>
                                    <CImage count={1}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={colSpan}>
                                <Form.Item
                                label='统一社会信用代码'
                                name="unifiedSocialCreditCode"
                                rules={[
                                    { required: true, message: "请输入统一社会信用代码" },
                                    {pattern:regular.unifiedSocialCreditCode,message:'请输入正确的统一社会信用代码'}]}>
                                    <Input placeholder='输入统一社会信用代码'/>
                                </Form.Item>
                            </Col>
                            <Col span={colSpan}>
                                <Form.Item
                                label='固定电话'
                                name="companyPhone"
                                rules={[{pattern:regular.telephone,message:'请输入正确的固定电话'}]}>
                                    <Input placeholder='区号-号码，例如010-12345678'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={colSpan}>
                                <Form.Item
                                label='企业地址'
                                name="companyAddress"
                                rules={[{ required: true, message: "请输入企业地址" }]}>
                                    <Input placeholder='输入企业地址' maxLength={100}/>
                                </Form.Item>
                            </Col>
                            <Col span={colSpan}>
                                <Form.Item
                                label='传真号码'
                                name="companyFaxNo"
                                rules={[{pattern:regular.faxNo,message:'请输入正确的传真号码'}]}>
                                    <Input placeholder='区号-传真，例如010-12345678'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={colSpan}>
                                <Form.Item
                                label='银行卡号'
                                name="bankCardNo"
                                rules={[{ required: true, message: "请输入银行卡号" },{pattern:regular.bankCardNo,message:'请输入正确的银行卡号'}]}>
                                    <Input placeholder='输入银行卡号' />
                                </Form.Item>
                            </Col>
                            <Col span={colSpan}>
                                <Form.Item
                                label='开户银行'
                                name="bankName"
                                rules={[{ required: true, message: "请输入开户银行" }]}>
                                    <Input placeholder='输入开户银行' maxLength={50}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Title text='联系人信息'/>
                        <Row>
                            <Col span={colSpan}>
                                <Form.Item
                                label='联系人'
                                name="contractName"
                                rules={[{ required: true, message: "请输入企业联系人" }]}>
                                    <Input placeholder='输入企业联系人' maxLength={10}/>
                                </Form.Item>
                            </Col>
                            <Col span={colSpan}>
                                <Form.Item
                                label='联系人电话'
                                name="contractPhone"
                                rules={[{ required: true, message: "请输入企业联系人电话" },{pattern:regular.phone,message:'请输入正确的手机号码'}]}>
                                    <Input placeholder='输入企业联系人电话'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Title text='企业法人信息'/>
                        <Row>
                            <Col span={colSpan}>
                                <Form.Item
                                label='企业法人'
                                name="companyLegalPerson"
                                rules={[{ required: true, message: "请输入企业法人" }]}>
                                    <Input placeholder='输入企业法人' maxLength={10}/>
                                </Form.Item>
                            </Col>
                            <Col span={colSpan}>
                                <Form.Item
                                label='身份证号码 '
                                name="idNo"
                                rules={[{ required: true, message: "请输入身份证号码" },{pattern:regular.idNo,message:'请输入正确的身份证号'}]}>
                                    <Input placeholder='输入身份证号码'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={colSpan}>
                                <Form.Item
                                    valuePropName="fileList"
                                    getValueFromEvent={getValueFromEvent}
                                    label='身份证正面照'
                                    name="identification_front"
                                    rules={[{ required: true, message: "请上传身份证正面照" }]}>
                                        <CImage count={1}/>
                                </Form.Item>
                            </Col>
                            <Col span={colSpan}>
                                <Form.Item
                                    valuePropName="fileList"
                                    getValueFromEvent={getValueFromEvent}
                                    label='身份证反面照'
                                    name="identification_reverse"
                                    rules={[{ required: true, message: "请上传身份证反面照" }]}>
                                        <CImage count={1}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}/>
                            <Button type='primary'
                            onClick={() => {
                                form.validateFields()
                                .then(values => {
                                    let body = {}
                                    let certAttachments = []
                                    _.forIn(values,(value,key) => {
                                        if(['business_license','identification_front','identification_reverse'].indexOf(key)!=-1){
                                            certAttachments.push({
                                                type:key,
                                                fileName:values[key][0].response
                                            })
                                        }
                                        
                                    })
                                    values.supportId = shipper.supportId
                                    body.shipper = values
                                    body.certAttachments = certAttachments
                                    axios.post(`/api/audit`,body)
                                    .then(res => {
                                        message.success('提交审核成功')
                                        form.resetFields()
                                        history.push('/setting')
                                    })
                                })
                            }}>提交认证</Button>
                            <Button type="text" 
                            onClick={() => {
                                form.resetFields()
                                history.push('/setting')
                            }}>取消</Button>
                        </Row>
                    </Form>
                </Layout>
            </div>
}
