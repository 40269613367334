import React, { useState, useEffect } from "react";
import {Row,Col,Space,Layout,Table,Form,Modal,Button,Input, DatePicker, message,Image } from "antd"
import { formatTime,noData ,surcharge,vehicleModels} from "common/Constant";
import axios from "axios"
import _ from "lodash"


const tableCols = [
    {title:'',dataIndex:'v1',width:'15%',className:'cell',align:'right'},
    {title:'',dataIndex:'v2',width:'35%'},
    {title:'',dataIndex:'v3',width:'15%',className:'cell',align:'right'},
    {title:'',dataIndex:'v4',width:'35%'},
]

export default function(props){

    const [visible,setVisible] = React.useState(false)
    const [billDetail,setBillDetail] = React.useState({})

    React.useEffect(() => {
        if(props.id){
            axios.get(`/api/bill/${props.id}`)
            .then(res => {
                setBillDetail(res.data)
                setVisible(props.visible)
            })
        }
    },[props.visible])

    const priceDataSource = () => {
        const {bill,order,earlySurcharge,laterSurcharge,waybill} = billDetail
        if(bill?.type == 'transport'){
                return [
                            {
                                v1:'费用编号',
                                v2:bill.id,
                                v3:'生成时间',
                                v4:formatTime(bill.createTime)
                            },{
                                v1:'费用类型',
                                v2:surcharge[bill.type],
                                v3:'运费金额',
                                v4:waybill.price.toFixed(1)
                            },{
                                v1:'货损扣款',
                                v2:order.cargoDamagePrice.toFixed(1),
                                v3:'扣款原因',
                                v4:noData(order.cargoDamageReason)
                            },{
                                v1:'费用总计',
                                v2:<span style={{color:'red'}}>{_.subtract(waybill.price,order.cargoDamagePrice).toFixed(1)}</span>
                            }
                        ]
            }
            if(bill?.type == 'earlier'){
                return [
                            {
                                v1:'费用编号',
                                v2:bill.id,
                                v3:'生成时间',
                                v4:formatTime(bill.createTime)
                            },{
                                v1:'费用类型',
                                v2:surcharge[bill.type],
                                v3:'出库费',
                                v4:<SurchargeRow 
                                    title='出库费'
                                    price={earlySurcharge.warehouseOut.toFixed(1)}
                                    receipt={earlySurcharge.warehouseOutReceipt}/>
                            },{
                                v1:'拆托费',
                                v2:<SurchargeRow 
                                    title='拆托费'
                                    price={earlySurcharge.devanning.toFixed(1)}
                                    receipt={earlySurcharge.devanningReceipt}/>,
                                v3:'压车费',
                                v4:<SurchargeRow 
                                    title='压车费'
                                    price={earlySurcharge.detention.toFixed(1)}
                                    receipt={earlySurcharge.detentionReceipt}/>
                            },{
                                v1:'进门费',
                                v2:<SurchargeRow 
                                    title='进门费'
                                    price={earlySurcharge.loadingGate.toFixed(1)}
                                    receipt={earlySurcharge.loadingGateReceipt}/>,
                                v3:'加班费',
                                v4:<SurchargeRow 
                                    title='加班费'
                                    price={earlySurcharge.overtime.toFixed(1)}
                                    receipt={earlySurcharge.overtimeReceipt}/>
                            },{
                                v1:'备注',
                                v2:earlySurcharge.earlierDesc,
                                v3:'费用总计',
                                v4:<span style={{color:'red'}}>{earlySurcharge.total.toFixed(1)}</span>
                            }
                        ]
            }
            if(bill?.type == 'later'){
                return [
                        {
                            v1:'费用编号',
                            v2:bill.id,
                            v3:'生成时间',
                            v4:formatTime(bill.createTime)
                        },{
                            v1:'费用类型',
                            v2:surcharge[bill.type],
                            v3:'卸车费',
                            v4:<SurchargeRow 
                                title='卸车费'
                                price={laterSurcharge.unload.toFixed(1)}
                                receipt={laterSurcharge.unloadReceipt}/>
                        },{
                            v1:'其他',
                            v2:<SurchargeRow 
                                title='其他'
                                price={laterSurcharge.laterOther.toFixed(1)}
                                receipt={laterSurcharge.laterOtherReceipt}/>,
                            v3:'备注',
                            v4:laterSurcharge.laterDesc
                        },{
                            v1:'费用总计',
                            v2:<span style={{color:'red'}}>{laterSurcharge.total.toFixed(1)}</span>
                        }
                ]
            }
        }

    return (
        <Modal
            title='费用明细'
            visible={visible}
            width='800px'
            footer={null}
            onCancel={() => {
                setVisible(false)
                props.onCancel&&props.onCancel()
            }}>
            <div>
                <div style={{lineHeight:2}}>费用信息</div>
                <Table 
                    bordered
                    pagination={false}
                    showHeader={false}
                    columns={tableCols}
                    dataSource={priceDataSource()}
                />
                <div style={{lineHeight:3}}>运单信息</div>
                <Table 
                    bordered
                    pagination={false}
                    showHeader={false}
                    columns={tableCols}
                    dataSource={[
                        {
                            v1:'运单编号',
                            v2:billDetail.waybill?.id,
                            v3:'发布时间',
                            v4:formatTime(billDetail.waybill?.createTime)
                        },
                        {
                            v1:'始发地',
                            v2:billDetail.waybill?.fromAddress,
                            v3:'目的地',
                            v4:billDetail.waybill?.toAddress
                        },
                        {
                            v1:'车长（米）',
                            v2:billDetail.waybill?.vehicleLength,
                            v3:'车型',
                            v4:vehicleModels[billDetail.waybill?.vehicleModel]
                        },
                        {
                            v1:'货物',
                            v2:<span>
                                    {
                                        billDetail.shipments?.map((item,index) => {
                                            return <div style={{ marginRight: 10 }}>
                                                        {item.cargoName} {item.weight ? `${item.weight}吨` : null} {item.volume ? `${item.volume}方` : null} {item.packingManner}{index < billDetail.shipments.length - 1 ? ";" : ""}
                                                    </div>
                                        })
                                    }
                                </span>
                        }
                    ]}
                />
            </div>
        </Modal>
    )
}

function SurchargeRow(props){
    return <div style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:"center"
            }}>
                {props.price}
                <Space>
                {
                    props.receipt&&
                    props.receipt.split(',').map(item => {
                        return <Image src={`/file/${item}`} width={20}/>
                    })
                }
                </Space>
               
            </div>
}