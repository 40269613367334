import React, { useState, useEffect } from "react";
import CBreadcrumb from "components/CBreadcrumb"
import {Row,Col,Space,Layout,Table,Form,Modal,Button,Input, DatePicker, message,Image } from "antd"
import axios from "axios"
import { useParams, Switch, useHistory, useLocation } from "react-router-dom";
import useStores from "stores/StoreContext"
import CommonSearch from "components/Search"
import MobxTable,{mergeParams} from "components/List/MobxTable"
import { formatTime,TimeFormats ,surcharge,noData,halfScreenHeight} from "common/Constant";
import {SearchOutlined,PictureOutlined} from "@ant-design/icons";
import {Actions} from "components/common"
import BillModal from "components/Logistics/BillModal"
import _ from "lodash"
import qs from "qs"

const searchProps = [
    "createStartDate",
    "createEndDate",
    "searchField"
]

export default function({match}){
    const history = useHistory()
    const location = useLocation()
    const {state} = qs.parse(location.search.slice(1))
    const id = match.params.id;
    const {billStore} = useStores()
    const [visible,setVisible] = React.useState(false)
    const [billId,setBillId] = React.useState('')
    
    const [billAuditDetail,setBillAuditDetail] = React.useState({})
    React.useEffect(() => {
        console.log('id====>',id)
        billStore.searchParams.billAuditId=id
        axios.get(`/api/billAudit?billAuditId=${id}`)
        .then(res => {
            setBillAuditDetail(res.data.results[0])
        })
    },[])

    const getBreadcrumbTitle = () => {
        let title = {}
        switch(state){
            case "pending":
                title={
                    text:'待处理账单',
                    href:`/billAudit?tabPaneKey=${state}`
                }
                break
            case "unpaid":
                title = {
                    text:'待付款账单',
                    href:`/billAudit?tabPaneKey=${state}`
                }
                break
            case "paid":
                title = {
                    text:'已付款账单',
                    href:`/billAudit?tabPaneKey=${state}`
                }
                break
        }
        return title
    }


    return (
        <Layout>
            <CBreadcrumb items={[
                    getBreadcrumbTitle(),
                    {
                        text:'账单明细',
                        href:''
                    }
                ]}>
            </CBreadcrumb>
            <Layout className='full' style={{height:`${halfScreenHeight*1.8}px`}}>
                <div style={{
                    margin:'20px',
                    backgroundColor:'#F3F7FB',
                    padding:'15px',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between'}}>
                    <Space size='large'>
                        <div style={{width:'200px'}}>账单编号：{billAuditDetail.id}</div>
                        <div style={{width:'200px'}}>提交时间：{formatTime(billAuditDetail.createTime,TimeFormats.YMDHM)}</div>
                        <div style={{display:'flex'}}>
                            <span style={{whiteSpace:'nowrap'}}>事项：</span>
                            <span>
                                {noData(billAuditDetail.remark)}
                            </span>
                        </div>
                    </Space>
                    <Space size='large' 
                    style={{display:'flex',alignItems:'center'}}>
                        <span style={{whiteSpace:'nowrap'}}>{billAuditDetail.billCount}笔</span>
                        <span style={{color:'red'}}>
                            ￥
                            <span style={{fontSize:'20px'}}>{billAuditDetail.billPrice?.toFixed(2)}</span>
                        </span>
                        {
                            state=="unpaid"&&null
                            // <Button type='primary'
                            // onClick={() => {
                            //     history.push(`/billAudit/pay?billAuditId=${billAuditDetail.id}&amount=${billAuditDetail.billPrice}`)
                            // }}>立即支付</Button>
                        }
                        {
                            state=='pending'&&
                            <Button type='primary'
                            onClick={() => {
                                Modal.confirm({
                                    title:'是否对账',
                                    onOk:() => {
                                        axios.post(`/api/billAudit/${id}`)
                                        .then(res => {
                                            message.success('对账成功')
                                            history.push(`/billAudit?tabPaneKey=${state}`)
                                        })
                                    }
                                })
                                
                            }}>立即对账</Button>
                        }
                        {
              state == 'unpaid' &&
              <Button type='primary'
                onClick={() => { history.push(`/offine/${billAuditDetail.id}`,) }}>线下支付</Button>
            }
                    </Space>
                </div>
                <CommonSearch title='费用列表'>
                    <SearchForm type='pending'
                    onValuesChange={(changedValues,allValues) => {
                        billStore.searchParams.billAuditId=id
                        billStore.searchParams = mergeParams(billStore.searchParams,allValues,searchProps,['billAuditId'])
                        billStore.search()
                    }}/>
                </CommonSearch>
                <MobxTable
                    rowKey="id"
                    store={billStore}
                    columns={[
                        {
                            title:'费用编号',
                            dataIndex:'id',
                        },
                        {
                            title:'生成日期',
                            dataIndex:'createTime',
                            render:(text) => formatTime(text),
                            ellipsis:true
                        },
                        {
                            title:'始发地',
                            dataIndex:'fromAddress',
                            ellipsis:true
                        },
                        {
                            title:'目的地',
                            dataIndex:'toAddress',
                            ellipsis:true
                        },
                        {
                            title:'运单编号',
                            dataIndex:'waybillId',
                            ellipsis:true
                        },
                        {
                            title:'费用类型',
                            dataIndex:'billType',
                            render:(text) => surcharge[text],
                            ellipsis:true
                        },
                        {
                            title:'金额',
                            dataIndex:'price',
                            render:(text) => <span style={{color:'red'}}>￥ {text.toFixed(2)}</span>
                        },
                        {
                            title:'操作',
                            render:(text,record) => <Actions items = {[
                                {
                                    text:'费用明细',
                                    onClick:() => {
                                        setBillId(record.id)
                                        setVisible(true)
                                        
                                    }
                                }
                            ]}/>
                        }
                    ]}/>
            </Layout>
            <BillModal 
            visible={visible} 
            onCancel={()=>setVisible(false)}
            id={billId}/>
            
        </Layout>
    )
}


const { RangePicker } = DatePicker;
function SearchForm(props){
    const [form] = Form.useForm();
    return (
        <Form colon={false} hideRequiredMark={true}
        form={form}
        style={{display:'flex',flexDirection:'row'}}
        {...props}>
            <Space>
                <Form.Item
                label='生成日期'
                name="date"
                getValueFromEvent={(e)=>{
                    if(e){
                        form.setFieldsValue({createStartDate:e[0].valueOf()})
                        form.setFieldsValue({createEndDate:e[1].valueOf()})
                    }else{
                        form.setFieldsValue({createStartDate:null})
                        form.setFieldsValue({createEndDate:null})
                    }
                }}>
                    <RangePicker allowClear/>
                </Form.Item>
                <Form.Item
                name="searchField">
                    <Input suffix={<SearchOutlined />} style={{width:'200px'}} allowClear></Input>
                </Form.Item>
                <Form.Item noStyle name='createStartDate'/>
                <Form.Item noStyle name='createEndDate'/>
            </Space>
        </Form>
    )
}