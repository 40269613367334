import { action, observable } from "mobx";
import axios from "axios";


export default class ShipmentStore{
    searchResult = observable({})
    searchParams = {}

    search(){
        axios.get(`/api/shipmentField`, { params: this.searchParams }).then(res => {
            this.searchResult.results = res.data.results;
            this.searchResult.count = res.data.count
          });
    }
}