import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup ,Polyline} from 'react-leaflet'
import vehicleImg from "assert/images/vehicle.png"
import L from 'leaflet'

export default function(props){
    const [lat,setLat] = React.useState(31.57003745)
    const [lng,setLng] = React.useState(120.3054559)
    const [zoom,setZoom] = React.useState(13)
    const {routes} = props

    const getPosition = () => {
        if(props.center&&props.center[0]&&props.center[1]){
            return props.center
        }
        return [lat,lng]
    }

    const getBounds=()=>{
        if(routes?.length>0){
            return [[props.center[0],props.center[1]],routes[0],routes[routes.length-1]]
        }
    }

    return (
       <Map zoom={zoom} style={{height:'300px'}}
        bounds={getBounds()}
        {...props} center={getPosition()}>
            <TileLayer
                subdomains={ "1234"}
                url='http://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}'
            />
            {
                getPosition()?<Marker position={getPosition()} icon={
                    new L.Icon({
                        iconUrl:vehicleImg,
                        iconSize: [25, 25],
                    })
                }/>:null
            }
            {
                routes?<Polyline  positions={routes}/>:null
            }
            {props.children}
        </Map>
    )
}