import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Divider, Layout, Tabs, Row, Col, Space, DatePicker, Table, Modal, Select, message, InputNumber } from "antd"
import { insuranceList, vechleType, weightUnit, vehicleLengths, noData, vehicleModels, vehicleLengthModels, halfScreenHeight, regular, } from "common/Constant"
import highLowImg from "assert/images/vehicle/high_low.png"
import highLowActiveImg from "assert/images/vehicle/high_low_active.png"
import highSidedImg from "assert/images/vehicle/high_sided.png"
import highSidedActiveImg from 'assert/images/vehicle/high_sided_active.png'
import flatbedImg from "assert/images/vehicle/flatbed.png"
import flatbedActiveImg from "assert/images/vehicle/flatbed_active.png"
import vanImg from "assert/images/vehicle/van.png"
import vanActiveImg from "assert/images/vehicle/van_active.png"
import "./index.less"
import { AuditOutlined, PlusOutlined, MenuOutlined } from "@ant-design/icons";
import axios from "axios"
import { Actions, Title } from "components/common"
import CommonMap from "components/Map"
import _ from "lodash"
import { useHistory, useLocation } from "react-router-dom"
import moment from "moment"
import AddressForm from "components/Logistics/AddressForm"
import { Item } from "pages/Setting";
import qs from 'qs'

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};
const addressLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 10 },
}
let addressType, res, timeOut

function search(callback) {
  if (timeOut) {
    clearTimeout(timeOut)
    timeOut = null
  }
  timeOut = setTimeout(callback, 800)
}

const { TabPane } = Tabs;
let inputValue, defaultValues, driverChange
export default function () {
  let location = useLocation()
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm()
  const [defaultValues, setDefaultValue] = React.useState(null)
  const [type, setType] = React.useState(document.body.getBoundingClientRect().width - 1 < 992 ? 'app' : 'desk')
  const [price, setPrice] = React.useState(0)

  const [driverInfo, setDriverInfo] = React.useState(undefined)
  const [driverList, setDriverList] = React.useState([])

  const [addressForm] = Form.useForm();
  const selectRef = React.useRef()
  let history = useHistory()
  const [vehicleModel, setVehicleModel] = React.useState()
  const [vehicleLength, setVehicleLength] = React.useState()

  const [shipments, setShipments] = React.useState([{ weight: '', cargoTypeClassificationCode: '', cargoName: '', packingManner: '' }])
  const [shipmentVisible, setShipmentVisible] = React.useState(false)
  const [commonShipments, setCommonShipments] = React.useState([])
  const [selectShipment, setSelectShipment] = React.useState({})

  const [mapVisible, setMapVisible] = React.useState(false)

  const [addressVisible, setAddressVisible] = React.useState(false)
  const [addresses, setAddresses] = React.useState([])
  const [selectAddress, setSelectAddress] = React.useState({})

  const [tableActionDelTag, setTableActionDelTag] = React.useState(false)


  const [editingKey, setEditingKey] = useState('');
  const isEditing = record => record.id === editingKey;
  const toAddAddress = (type) => {
    addressType = type
    axios.get(`/api/address`)
      .then(res => {
        setAddressVisible(true)
        setAddresses(res.data.results)
      })
  }

  const toAddShipments = () => {
    axios.get(`/api/shipmentField`)
      .then(res => {
        setShipmentVisible(true)
        setCommonShipments(res.data.results)
      })
  }


  function selectBlur(v) {
    form.setFieldsValue({ driverPhone: v })
    console.log(v);
    // console.log(document.getElementById('driverPhone').value);
    console.log(form.getFieldValue('driverPhone'));
    // document.getElementById('driverPhone').value = form.getFieldValue('driverPhone')
    // console.log(selectRef);
    // console.log(form.getFieldValue('driverPhone'));
  }

  function selectChange(v) {
    driverChange = false
  }
  function selectFouse(e) {

    form.setFieldsValue({ 'driverPhone': form.getFieldValue('driverPhone') })
    // document.getElementById('driverPhone').value = form.getFieldValue('driverPhone')
    // console.log(document.getElementById('driverPhone').value);
  }


  const modelImg = (model) => {
    if (model == 'high_low') {
      return model == vehicleModel ? highLowActiveImg : highLowImg
    }
    if (model == 'high_sided') {
      return model == vehicleModel ? highSidedActiveImg : highSidedImg
    }
    if (model == 'flatbed') {
      return model == vehicleModel ? flatbedActiveImg : flatbedImg
    }
    if (model == 'van') {
      return model == vehicleModel ? vanActiveImg : vanImg
    }
  }

  const EditableCell = ({
    editing,
    inputType,
    record,
    title,
    children,
    dataIndex,
    required,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber precision={1} min={0} maxLength={10} style={{ width: type == 'desk' ? '100%' : '60px' }} />
      : inputType === 'select' ?
        <Select >
          {weightUnit.map((item, index) => <Select.Option key={index} value={item}>{item}</Select.Option>)}
        </Select> : <Input />;
    return (
      <td {...restProps}>
        <Form.Item noStyle name='id' />
        {editing ? (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: required,
                message: inputType === 'number' ? `请输入${title}` : `请选择${title}`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  React.useEffect(() => {

    window.addEventListener('resize', () => {
      if (document.body.getBoundingClientRect().width - 1 < 992) {
        setType('app')
      } else {
        setType('desk')
      }
    })
    form.setFieldsValue({
      vehicleLength: '不限',
      vehicleModel: '不限',
      insuranceCompanyCode: '无',
    })
    tableForm.setFieldsValue({
      cargoTypeClassificationCode: '吨'
    })
    if (location.search) {
      let waybillItem = JSON.parse(qs.parse(location.search.slice(1)).item)
      //  form.setFieldsValue({...waybillItem})
      form.setFieldsValue({ ...waybillItem })
      tableForm.setFieldsValue({ ...waybillItem })
      axios.get(`/api/driver/DriverSearch/${waybillItem.driverPhones}`).then(

        res => {
          driverChange = true
          form.setFieldsValue({
            'driverPhone': <span>
              司机姓名:{res.data[0].realName} 电话号码:{res.data[0].accountName} 车牌:{res.data[0].plateNumber}<span style={{ color: res.data[0].approved == 1 ? 'green' : 'red' }}> {res.data[0].approved == 1 ? '已认证' : '未认证'}
              </span>
            </span>
          })
        })

      // console.log(waybillItem);
    }
    return () => {
      window.removeEventListener('resize', () => {
        setType(null)
      })
    }
  }, [])
  return (
    <Layout className='full' style={{ height: `${halfScreenHeight * 1.95}px`, overflow: type == 'desk' ? null : 'auto' }}>
      {/* <Title text='需求信息'/> */}
      {/* <Row >
                <Col span={3}>
                     <span style={{float:'right',margin:'12px',marginRight:'20px'}}>车长车型</span>
                </Col>
                <Col span={15}>
                    <Tabs onChange={(length) => {
                        setVehicleLength(length)
                        const currentModels = _.find(vehicleLengthModels,item => item.length==length).model
                        if(currentModels.indexOf(vehicleModel) == -1){
                            setVehicleModel(currentModels[0])
                        }
                    }} activeKey={vehicleLength}>
                        {
                            vehicleLengthModels.map((item) => {
                                return <TabPane 
                                        tab={`${item.length}米`} 
                                        key={item.length}
                                        style={{
                                            height:'100px',
                                            paddingTop:'25px',
                                            border:'1px solid #C8CACD'
                                        }}>
                                            <Row style={{width:'100%'}}>
                                                {
                                                    item.model.map(item1 => {
                                                        return <Col span={2}>
                                                                    <VehicleCard 
                                                                        img={modelImg(item1)}
                                                                        text={item1}
                                                                        onClick={() => setVehicleModel(item1)}/>
                                                                </Col>
                                                    })
                                                }
                                            </Row>
                                       </TabPane>
                            })
                        }
                    </Tabs>
                </Col>
                
            </Row> */}
      <Title text='装卸货信息' style={{ marginTop: '20px' }} />
      <Form style={{ paddingLeft: '10px' }}
        form={form}
        {...layout}>
        <Row >
          <Col span={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '5px'
            }}>
            <Circle text={'装'} color='#5CD4A6' />
          </Col>
          <Col span={type == 'desk' ? 6 : 23}>
            <Form.Item
              label='装货联系人'
              name="acceptName"
              rules={[{ required: true, message: "请输入联系人姓名" }]}>
              <Input placeholder='填写联系人姓名' maxLength={50} />
            </Form.Item>
          </Col>
          {type == 'desk' ? null : <Col span={1} />}
          <Col span={type == 'desk' ? 5 : 23} style={{ marginLeft: type == 'desk' ? 15 : null }}>
            <Form.Item
              name="acceptPhone"
              rules={[
                { required: true, message: "请输入联系人电话" },
                { pattern: regular.phone, message: '请输入正确的手机号' }]}>
              <Input placeholder='填写联系人电话' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}>
          </Col>
          <Col span={12}>
            <Form.Item
              {...addressLayout}
              label='装货地址'
              name="fromAddress"
              rules={[{ required: true, message: "请输入装货地址" }]}>
              <Input placeholder='填写装货地址'
                onClick={() => {
                  addressType = 'from'
                  setMapVisible(true)
                }} />
            </Form.Item>
            <Form.Item noStyle name='fromAreaCode' />
            <Form.Item noStyle name='fromLat' />
            <Form.Item noStyle name='fromLng' />
          </Col>
          <Col span={7} style={{ padding: '5px', marginLeft: '10px' }}>
            <Space style={{ cursor: 'pointer' }}
              onClick={() => toAddAddress('from')}>
              <AuditOutlined style={{ color: '#1989FA', fontSize: '15px' }} />
              <span style={{ color: '#1989FA' }}>使用常用地址</span>
            </Space>
          </Col>
        </Row>
        <Row>
            <Col span={1} />
            <Col span={6}>
              <Form.Item
                label='预计发车时间'
                name="expectPublishTime"
                rules={[{ required: true, message: "请选择时间" }]}>
                <DatePicker
                  format='YYYY-MM-DD HH:mm'
                  showTime={{ format: 'HH:mm' }}
                  placeholder='请选择时间'
                  disabledDate={
                    (current) => {
                      const expectReachTime = _.cloneDeep(form.getFieldValue('expectReachTime'))
                      return (expectReachTime && current > moment(expectReachTime)?.endOf('day')) || current < moment().startOf('day')
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>

        <Row >
          <Col span={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '5px'
            }}>
            <Circle text={'卸'} color='red' />
          </Col>
          <Col span={type == 'desk' ? 6 : 23} >
            <Form.Item
              label='卸货联系人'
              name="unloadName"
              rules={[{ required: true, message: "请输入联系人姓名" }]}>
              <Input placeholder='填写联系人姓名' maxLength={50} />
            </Form.Item>
          </Col>
          {type == 'desk' ? null : <Col span={1} />}
          <Col span={type == 'desk' ? 5 : 23} style={{ marginLeft: type == 'desk' ? 15 : null }}>
            <Form.Item
              name="unloadPhone"
              rules={[
                { required: true, message: "请输入联系人电话" },
                { pattern: regular.phone, message: '请输入正确的手机号' }]}>
              <Input placeholder='填写联系人电话' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1} />
          <Col span={12}>
            <Form.Item
              {...addressLayout}
              label='卸货地址'
              name="toAddress"
              rules={[{ required: true, message: "请输入卸货地址" }]}>
              <Input placeholder='填写卸货地址'
                onClick={() => {
                  addressType = 'to'
                  setMapVisible(true)
                }} />
            </Form.Item>
            <Form.Item noStyle name='toAreaCode' />
            <Form.Item noStyle name='toLat' />
            <Form.Item noStyle name='toLng' />
          </Col>
          <Col span={7} style={{ padding: '5px', marginLeft: '10px' }}>
            <Space style={{ cursor: 'pointer' }}
              onClick={() => toAddAddress('to')}>
              <AuditOutlined style={{ color: '#1989FA', fontSize: '15px' }} />
              <span style={{ color: '#1989FA' }}>使用常用地址</span>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={1} />
          <Col span={6}>
                        <Form.Item
                        label='预计到达时间'
                        name="expectReachTime"
                        rules={[{ required: true, message: "请选择时间" }]}>
                            <DatePicker 
                            format='YYYY-MM-DD HH:mm'
                            showTime={{format:'HH:mm'}}
                            placeholder='请选择时间'
                            disabledDate={(current) => {
                                const expectPublishTime = _.cloneDeep(form.getFieldValue('expectPublishTime'))
                                return current <= moment().startOf('day')||current < expectPublishTime?.startOf('day')
                            }}
                            />
                        </Form.Item>
                    </Col>
        </Row>
        <Title text='货物信息' style={{ marginTop: '20px' }} />
        {/* <Row >
          <Col span={18}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              lineHeight: 3,
              paddingLeft: '25px'
            }}>
             <span>
              总计：{shipments?.length}种货物；{_.sumBy(shipments, item => item.weight || 0)?.toFixed(1)}kg
            </span> *
            <Space style={{ cursor: 'pointer' }}
              onClick={toAddShipments}>
              <MenuOutlined style={{ color: '#1989FA' }} />
              <span style={{ color: '#1989FA' }}>
                选择常用货物
              </span>
            </Space>
          </Col>
        </Row>  */}
        <Row style={{ paddingLeft: '10px' }}>
          <Col span={18}>
            <Form
              form={tableForm}
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              name='shipments'
              rules={[{ required: true, message: "请选择货物信息" }]}>
              <Table
                rowKey='id'
                dataSource={shipments}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                columns={[
                  {
                    title: "货物名称",
                    dataIndex: "cargoName",
                    editable: true,
                    width: type == 'desk' ? '30%' : '90px',
                    onCell: record => ({
                      record,
                      title: "货物名称",
                      inputType: 'text',
                      dataIndex: "cargoName",
                      editing: true,
                      required: true
                    })
                  },
                  {
                    title: "重量",
                    dataIndex: "weight",
                    width: type == 'desk' ? '15%' : '20px',
                    editable: true,
                    onCell: record => ({
                      record,
                      title: "重量",
                      inputType: 'number',
                      dataIndex: "weight",
                      editing: true,
                      required: true,

                    })
                  },
                  {
                    title: "单位",
                    dataIndex: "cargoTypeClassificationCode",
                    width: type == 'desk' ? '10%' : '10px',
                    editable: true,
                    render: (text) => noData(text),
                    onCell: record => ({
                      record,
                      title: '单位',
                      inputType: 'select',
                      dataIndex: "cargoTypeClassificationCode",
                      editing: true,
                      required: true
                    })
                  },
                  // {
                  //     title: "包装",
                  //     dataIndex: "packingManner",
                  //     width:'20%',
                  //     editable: true,
                  //     onCell:record => ({
                  //         record,
                  //         title:'包装',
                  //         inputType: 'text',
                  //         dataIndex:"packingManner",
                  //         editing: isEditing(record),
                  //         required:true
                  //     })
                  // },
                  // {
                  //   title: "操作",
                  //   width: '20%',
                  //   render: (text, record) => {
                  //     const editable = isEditing(record);
                  //     return editable ? (
                  //       <Actions
                  //         style={{
                  //           marginBottom: '17px'
                  //         }}
                  //         items={[
                  //           {
                  //             text: '保存',
                  //             onClick: async () => {
                  //               tableForm.validateFields()
                  //                 .then(values => {
                  //                   const temp = _.cloneDeep(shipments)
                  //                   temp.forEach((item, index) => {
                  //                     if (item.id == values.id) {
                  //                       temp[index] = values
                  //                     }
                  //                   })
                  //                   setShipments(temp)
                  //                   tableForm.resetFields()
                  //                   setEditingKey('')
                  //                 })
                  //             }
                  //           }, {
                  //             text: tableActionDelTag ? '删除' : '取消',
                  //             color: 'red',
                  //             onClick: () => {
                  //               if (tableActionDelTag) {
                  //                 const tempShipments = _.cloneDeep(shipments)
                  //                 _.remove(tempShipments, item => item.id == record.id)
                  //                 setShipments(tempShipments)
                  //                 if (record.id == editingKey) {
                  //                   setEditingKey('')
                  //                 }
                  //               } else {
                  //                 setEditingKey('')
                  //               }

                  //             }
                  //           }
                  //         ]} />
                  //     ) : (
                  //       <Actions items={[
                  //         {
                  //           text: '编辑',
                  //           onClick: () => {
                  //             tableForm.setFieldsValue({
                  //               ...record
                  //             })
                  //             setEditingKey(record.id)
                  //             setTableActionDelTag(false)
                  //           }
                  //         }, {
                  //           text: '删除',
                  //           color: 'red',
                  //           onClick: () => {
                  //             const tempShipments = _.cloneDeep(shipments)
                  //             _.remove(tempShipments, item => item.id == record.id)
                  //             setShipments(tempShipments)
                  //             if (record.id == editingKey) {
                  //               setEditingKey('')
                  //             }
                  //           }
                  //         }
                  //       ]} />
                  //     )
                  //   }
                  // }
                ]} />
            </Form>
            <Form.Item name='shipments' rules={[{ required: true, message: "请添加货物" }]} />
          </Col>
        </Row>
        {/* <Row>
          <Col span={18}>
            <Button
              type="dashed"
              size='large'
              style={{ width: '100%' }}
              icon={<PlusOutlined style={{ color: '#1989FA' }} />}
              onClick={() => {
                const id = new Date().getTime()
                let shipments1 = _.cloneDeep(shipments)
                shipments1.push({ id: id, weight: '', volume: '', cargoName: '', packingManner: '' })
                setShipments(shipments1)
                setEditingKey(id)
                tableForm.validateFields()
                  .then(values => {
                    if (editingKey && shipments.length > 0) {
                      message.warning('请先保存货物信息')
                      return
                    }
                    setTableActionDelTag(true)
                    let shipments1 = _.cloneDeep(shipments)
                    const id = new Date().getTime()
                    shipments1.push({ id: id, weight: '', volume: '', cargoName: '', packingManner: '' })
                    tableForm.resetFields()
                    tableForm.setFieldsValue({ id: id })
                    setShipments(shipments1)
                    setEditingKey(id)
                  })
              }}>
              <span style={{ color: '#1989FA' }}>添加货物</span>
            </Button>
          </Col>
        </Row> */}
        <Title text='所需车辆信息' style={{ marginTop: '20px' }} />
        <Row>
          <Col span={1} />
          <Col span={type == 'desk' ? 6 : 11}>
            <Form.Item
              name='vehicleLength'
              // rules={[{ required: true, message: "请选择所需车长" }]}
              label='所需车长'>
              <Select >
                {vehicleLengths.map((item, index) => <Select.Option key={index} value={item} >{['其他', '不限', '无'].includes(item) ? item : item + '米'}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={1} />
          <Col span={type == 'desk' ? 6 : 11}>
            <Form.Item
              name='vehicleModel'
              // rules={[{ required: true, message: "请选择所需车型" }]}
              label='所需车型'>
              <Select >
                {vechleType.map((item, index) => <Select.Option key={index} value={item}>{item}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Title text='指派司机' style={{ marginTop: '20px' }} />
        <Row>
          <Col span={1} />
          <Col span={6}>
            <Form.Item
              name='driverPhone'
              // rules={[{ required: true, message: "请输入手机号" }, { pattern: regular.phone, message: '请输入正确的手机号' }]}
              label='司机电话'

            // {
            //   ...(location.search && {initialValue:<span style={{color:'green'}}>已认证</span>})

            // }

            >

              <Select
                style={{ width: type == "desk" ? 450 : 280 }}
                showSearch
                showArrow={false}
                ref={selectRef}
                // value={form.getFieldValue('driverPhone')}
                defaultActiveFirstOption={false}
                filterOption={false}
                virtual={false}
                allowClear
                notFoundContent={'暂无数据'}
                // onFocus={selectFouse}
                onClear={() => setDriverList([])}
                // onBlur={selectBlur}
                mode="combobox"
                onSearch={
                  (value) => {
                    if (value.length > 0) {
                      // selectRef.current.props.onBlur(value)
                      // form.setFieldsValue({'driverPhone':form.getFieldValue('driverPhone')})
                      form.setFieldsValue({ 'driverPhone': value })
                      search(() => axios.get(`/api/driver/DriverSearch/${value}`)
                        .then(res => {
                          console.log(res)
                          setDriverList(res.data)
                        }))
                    } else {
                      return
                    }
                  }
                }

                onChange={selectChange}
              >
                {
                  driverList.map(item => {
                    return <Select.Option value={item.accountName} key={item.id} >
                      <Space>
                        <span>司机姓名:{item.realName}</span>
                        <span>电话号码:{item.accountName}</span>
                        <span>车牌号:{item.plateNumber}</span>
                        <span style={{ color: item.approved == 1 ? 'green' : 'red' }}>{item.approved == 1 ? ' 已认证' : ' 未认证'}</span>
                      </Space>
                    </Select.Option>
                  })
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1} />
          <Col span={6}>
            <Form.Item

              name='price'
              rules={[{ required: true, message: "请输入运费金额" }, { pattern: regular.price, message: '请输入数字,保留小数点后一位' }]}
              label='运费金额'>
              <InputNumber
                placeholder='运费金额'
                min={0} style={{ width: type == "desk" ? 450 : 280 }}
                maxLength={10} onChange={
                  async (price) => {
                    let res = await axios(`/api/logistics/service-fee/${price}`)
                    setPrice(res.data.data.serviceFee)
                  }
                } />
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col span={1} />
          <Col span={6}>
            <Form.Item
              label='所需服务费'>
              <span style={{ color: 'red' }}>{price}元</span>
            </Form.Item>

          </Col>
        </Row>
        <Title text='保险信息' style={{ marginTop: '20px' }} />
        <Row>
          <Col span={1} />
          <Col span={type == 'desk' ? 6 : 11}>
            <Form.Item
              name='insuranceCompanyCode'
              // rules={[{ required: true, message: "请选择保险公司" }]}
              label='保险公司'>
              <Select >
                {insuranceList.map((item, index) => <Select.Option key={index} value={item.code}>{item.value}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={1} />
          <Col span={type == 'desk' ? 6 : 11}>
            <Form.Item
              name='insurancePolicyNo'
              // rules={[{ required: true, message: "请输入保险单号" }]}
              label='保险单号'>
              <Input placeholder='保险单号' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Title text='其它信息' style={{ marginTop: '20px' }} />
        <Row>
          <Col span={1} />
          {/* <Col span={6}>
            <Form.Item
              label='纸质订单编号'
              name='customerOrderNo'
              rules={[{ required: true, message: "请输入纸质订单编号" }]}>
              <Input placeholder='纸质订单编号' maxLength={30} />
            </Form.Item>
          </Col> */}
        </Row>
        <Row>
          <Col span={1} />
          <Col span={type == 'desk' ? 12 : 23}>
            <Form.Item
              {...addressLayout}
              name='remark'
              label='发货备注'>
              <Input.TextArea placeholder='特殊情况填写备注' maxLength={300} />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
          <Col span={1} />
          <Col span={6}>
            <Form.Item
              name='price'
              rules={[{ required: true, message: "请输入运费金额" }, { pattern: regular.price, message: '请输入数字,保留小数点后一位' }]}
              label='运费金额（元）'>
              <InputNumber placeholder='运费金额' min={0} style={{ width: '100%' }} maxLength={10} />
            </Form.Item>
          </Col>
        </Row> */}
        <Row style={{ paddingBottom: 10 }}>
          <Col span={3} />
          <Button
            type='primary'
            size='large'
            style={{
              width: '200px'
            }}
            onClick={async () => {
              await tableForm.validateFields()
                .then(values => {
                  debugger
                  // if (editingKey) {
                  //   message.warning('请先保存货物信息')
                  // }
                  res = [values]

                })

              if (shipments.length == 0) {
                form.setFields([{ name: 'shipments', errors: ['请添加货物'], value: null }])
              } else {
                form.setFields([{ name: 'shipments', errors: null, value: 1 }])
              }
              form.validateFields()
                .then(values => {
                  // values.vehicleLength = vehicleLength
                  // values.vehicleModel = vehicleModel
                  if (values.vehicleLength == '不限') {
                    values.vehicleLength = 0
                  }
                  if (values.insuranceCompanyCode == '无') {
                    values.insuranceCompanyCode = null
                  }
                  if (location.search && driverChange) {
                    values.driverPhone = JSON.parse(qs.parse(location.search.slice(1)).item).driverPhones
                  }
                  values.expectPublishTime = moment(values.expectPublishTime).format('YYYY-MM-DD')
                  values.expectReachTime = moment(values.expectReachTime).format('YYYY-MM-DD')
                  shipments.forEach(item => item.id = null)
                  axios.post(`/api/logistics/publish`, { waybill: values, shipments: res, driver: { driverPhone: values.driverPhone } })
                    .then(res => {
                      if (res.data.code == 100000) {
                        driverChange = false
                        message.success('发布成功')
                        history.push('/waybill')
                      } else {
                        message.error('发布失败 ' + res.data.msg)
                      }
                    })
                })
            }}>
            发布
          </Button>
          <Button
            onClick={() => {
              form.resetFields()
              setShipments([])
              setVehicleModel('high_sided')
              setVehicleLength('4.2')
            }}
            style={{ marginLeft: '20px' }}
            type="text"
            size='large'>
            清空
          </Button>
        </Row>
      </Form>
      <Modal
        width={'800px'}
        onCancel={() => setAddressVisible(false)}
        onOk={() => {
          if (!selectAddress.address) {
            message.warning('请选择一条数据')
            return
          }
          if (addressType == 'from') {

            form.setFieldsValue({
              fromAddress: selectAddress.address,
              fromAreaCode: selectAddress.areacode,
              fromLat: selectAddress.lat,
              fromLng: selectAddress.lng,
              acceptName: selectAddress.contractName,
              acceptPhone: selectAddress.contractPhone
            })
          }
          if (addressType == 'to') {
            form.setFieldsValue({
              toAddress: selectAddress.address,
              toAreaCode: selectAddress.areacode,
              toLat: selectAddress.lat,
              toLng: selectAddress.lng,
              unloadName: selectAddress.contractName,
              unloadPhone: selectAddress.contractPhone
            })
          }
          setAddressVisible(false)
        }}
        title='常用地址'
        visible={addressVisible}>
        <div className={'address-list'}>
          {
            addresses.map((item, i) => {
              return (
                <Row
                  className={selectAddress.id == item.id ? 'item selected cur-po' : 'item cur-po'}
                  onClick={() => setSelectAddress(item)}>
                  <Col
                    title={item.contractName}
                    span={4}
                    className={'ant-table-cell-ellipsis'}>
                    {item.contractName}
                  </Col>
                  <Col span={4}>
                    {item.contractPhone}
                  </Col>
                  <Col
                    title={item.address}
                    span={16}
                    className={'ant-table-cell-ellipsis'}>
                    {item.address}
                  </Col>
                </Row>
              )
            })
          }
        </div>
      </Modal>
      <Modal
        title='选择地址'
        onCancel={() => setMapVisible(false)}
        visible={mapVisible}
        width='800px'
        onOk={() => {
          addressForm.validateFields()
            .then(values => {
              if (addressType == 'from') {
                form.setFieldsValue({
                  fromAreaCode: values.areacode,
                  fromAddress: values.address,
                  fromLat: values.lat,
                  fromLng: values.lng,
                })
              }
              if (addressType == 'to') {
                form.setFieldsValue({
                  toAreaCode: values.areacode,
                  toAddress: values.address,
                  toLat: values.lat,
                  toLng: values.lng,
                })
              }
              addressForm.resetFields()
              setMapVisible(false)
            })

        }}>
        <AddressForm form={addressForm} />
      </Modal>
      <Modal
        width={'800px'}
        onCancel={() => setShipmentVisible(false)}
        onOk={() => {
          if (!selectShipment.cargoName) {
            message.warning('请选择货物')
            return
          }
          if (_.find(shipments, item => item.id == selectShipment.id)) {
            message.warning('不能重复添加货物')
            return
          }
          shipments.push(selectShipment)
          setShipments(_.cloneDeep(shipments))
          setSelectShipment({})
          setShipmentVisible(false)
        }}
        title='常用货物'
        visible={shipmentVisible}>
        <div className={'address-list'}>
          <Row>
            <Col span={6} className={'title'}>
              货物名称
            </Col>
            <Col span={6} className={'title'}>
              包装方式
            </Col>
            <Col span={6} className={'title'}>
              体积（立方米）
            </Col>
            <Col span={6} className={'title'}>
              重量（kg）
            </Col>
          </Row>
          {
            commonShipments.map(item => {
              return (
                <Row className={selectShipment.id == item.id ? 'item selected cur-po' : 'item cur-po'}
                  onClick={() => setSelectShipment(_.pick(item, ['cargoName', 'packingManner', 'weight', 'volume', 'id']))}
                  style={{
                    display: 'flex',
                    paddingLeft: '20px',
                    alignItems: 'center',
                  }}>
                  <Col
                    title={item.cargoName}
                    span={6}
                    className={'content ant-table-cell-ellipsis'} >
                    {item.cargoName}
                  </Col>
                  <Col
                    title={item.packingManner}
                    span={6}
                    className={'content ant-table-cell-ellipsis'}>
                    {item.packingManner}
                  </Col>
                  <Col span={6} className={'content'}>
                    {noData(item.volume)}
                  </Col>
                  <Col span={6} className={'content'}>
                    {item.weight}
                  </Col>
                </Row>
              )
            })
          }
        </div>
      </Modal>
    </Layout>
  )
}


function VehicleCard(props) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  }}
    {...props}>
    <img src={props.img} style={{ height: '20px' }} />
    <span style={{ marginTop: '5px' }}>{vehicleModels[props.text]}</span>
  </div>
}

function Circle(props) {
  return <div style={{
    height: '20px',
    width: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: `1px solid ${props.color}`
  }}>
    <span style={{ color: props.color, fontSize: '10px' }}>{props.text}</span>
  </div>
}
