import { action, observable } from "mobx";
import axios from "axios";


export default class BillStore {
    pendingResult = observable({})
    pendingParams = {submit:false}

    searchPending() {
        axios.get(`/api/bill/service`, { params: this.pendingParams }).then(res => {
          for (let item of res.data.results) {
            item.amount = item.price + item.serviceFee
          }
          this.pendingResult.results = res.data.results;
          this.pendingResult.count = res.data.count
        });
    }

    pendingResults = observable({})
    pendingParamss = {billAuditState:'pending'}

    searchPendings() {
        axios.get(`/api/billAudit`, { params: this.pendingParamss }).then(res => {
          this.pendingResults.results = res.data.results;
          this.pendingResults.count = res.data.count
        });
    }
    unpaidResult = observable({})
    unpaidParams = {billAuditState:'unpaid'}

    searchUnpaid() {
        axios.get(`/api/billAudit`, { params: this.unpaidParams }).then(res => {
          this.unpaidResult.results = res.data.results;
          this.unpaidResult.count = res.data.count
        });
    }


    paidResult = observable({})
    paidParams = {billAuditState:'paid'}

    searchPaid() {
        axios.get(`/api/billAudit`, { params: this.paidParams }).then(res => {
          this.paidResult.results = res.data.results;
          this.paidResult.count = res.data.count
        });
    }
}