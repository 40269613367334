import axios from "axios";
import { message } from "antd";


axios.interceptors.response.use(
    response => response,
  (error) => {
    switch (error.response.status) {
      case 400:
        if (error.response.data.error == "invalid_grant") {
          message.error("用户名或密码错误");
          break;
        }
      case 403:
        message.warning(error.response.data.message)
        break;
      case 412:
        message.warning(error.response.data)
        break;
      case 500:
        message.error(error.response.data.detail);
        break;
    }
    return Promise.reject(error);
  }
);

export function formdata(data) {
  const form_data = new FormData();
  for (const key in data) {
    form_data.append(key, data[key]);
  }
  return form_data;
}
