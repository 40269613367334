import React from 'react';
import './App.less';
import { ConfigProvider } from 'antd';
import zhCN from "antd/es/locale/zh_CN";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Authenticated } from "common/session";
import Layouts from "common/Layouts";
import Login from "pages/Auth/Login"
import Register from "pages/Auth/Register"
import ForgetPassword from "pages/Auth/ForgetPassword"
import  "common/network"
import moment from "moment";
import "moment/locale/zh-cn";

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Router >
        <Switch>
          <Route key="login" exact path="/login" component={Login} />
          <Route key="register" exact path="/register" component={Register} />
          <Route key="forgetPassword" exact path="/forgetPassword" component={ForgetPassword} />
          <Authenticated>
            <Layouts.Main></Layouts.Main>
          </Authenticated>
        </Switch>
      </Router>
    </ConfigProvider>
  );
}

export default App;
